/* eslint-disable */

/* eslint-disable-next-line no-unused-vars */

/**
 * This is a simplified version just dealing with simple numbers of params.
 * @param {*} P0 - result to be calculated
 * @param {*} equation -
 * @param  {...any} params - all input variables for an equation
 */
/* export function calculateOnInputsOfNs(P0, equation, ...params) {
  let bvParams = [];

  console.log(`equation: ${equation}`);

  if (params.length >= 1) {
    params.forEach(p => {
      console.log(`p@calculateOnInputs: ${JSON.stringify(p)}`);

      let bv;
      if (p.unit) {
        let m = new Measurement(p);
        bv = m.baseValue();
      } else {
        bv = p.value;
      }
      bvParams.push(bv);
    })
  }

  let P0Unit = P0.unit ? P0.unit : null,
      P0Desc = P0.desc,
      P0IsFx = P0.isFx,
      P0Precision = P0.precision;

  let bvP0 = equation(...bvParams);

  let fvP0 = bvP0;
  if (P0Unit) {
    let mP0 = new Measurement({value: 99999, unit: P0Unit});
    fvP0 = mP0.faceValue(bvP0, P0Unit);
  }

  return {value: fvP0, unit: P0Unit, desc: P0Desc, isFx: P0IsFx, precision: P0Precision }
} */

function equationPitch(Ds, cf1) { return Ds * cf1} // copied from screwConveyor calculator as example

// allowed file types
const imageTypes = [ 'image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp' ]

module.exports = {
  equationPitch, // this is just for reference
  imageTypes,
}
