<template>
  <form class="grid grid-cols-4 gap-1 mx-auto p-1 w-full md:w-4/5 justify-center" @submit.prevent="handleSubmit">
    <div class="col-start-2 col-span-3 text-2xl font-bold text-center p-4">Login</div>
    <label for="email" class="col-start-1 col-span-1 text-right m-2">Email:</label>
    <input id="email" class="col-start-2 col-span-3 input-pq1 m-2" type="email" v-model="email">

    <label for="password" class="col-start-1 col-span-1 text-right m-2">Password:</label>
    <input id="password" class="col-start-2 col-span-3 input-pq1 m-2" type="password" v-model="password">

    <div v-if="error" class="error col-start-2 col-span-3 text-center">{{ error }}</div>
    <button class="roundBtn localBtnLogin" v-if="!isPending">Log in</button>
    <button class="roundBtn localBtnLogin" v-if="isPending" disabled>Loading</button><br>
    <div class="col-start-2 col-span-3 text-right px-6">Forgot password, would like to reset? </div>
    <button class="roundBtn localBtnLogin" title="Reset Password"
      @click="$router.push({name: 'ResetPassword'})">Reset Password
    </button>
  </form>
</template>

<script>
// using @ means start at the project src root
import useLogin from '@/composables/useLogin'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'E3dLogin',
  setup () {
    const { error, login, isPending } = useLogin()
    const router = useRouter()

    const email = ref('')
    const password = ref('')

    const handleSubmit = async () => {
      // const res = await login(email.value, password.value)
      await login(email.value, password.value)

      if (!error.value) {
        router.push({ name: 'NewCalculator' })
      }
    }

    return { email, password, handleSubmit, error, isPending }
  }
}
</script>

<style src="../../styles/components/index.css" scoped></style>
