<template>
  <div class="container">
    <div class="flex flex-col justify-center mx-auto">
      <div class="flex flex-row justify-center">
        <div class="text-center m-3 underline text-2xl"
          style='font-family: "Comic Sans MS", cursive, sans-serif; color:#1aa3ff;'
          v-if="projectData.length && showLess">
          {{ projectData[0].value.value }}
        </div>
        <div v-else class="text-center m-3 underline" style='font-family: "Comic Sans MS", cursive, sans-serif; color:#1aa3ff;'>
          Project Information
        </div>
        <button @click="showLess = !showLess">
          <label v-if="showLess">
            <img style="display: inline;"
              src="@/assets/icons/arrow_circle_down_black_24dp.svg"
              title="Show All Project Info"
              height="20" width="20" />
          </label>
          <label v-else><img style="display: inline;"
            src="@/assets/icons/arrow_circle_up_black_24dp.svg"
            title="Show Less Project Info"
            height="20" width="20" />
          </label>
        </button>
      </div>

      <div v-if="!showLess">
        <unified-input v-for="(item, index) in projectData" :key="index"
          :type = 'item.value.type'
          :label = "item.value.label"
          :modelValue = 'item.value.value'
          :datalist = 'item.value.datalist'
          :readonly = 'item.value.readonly'
          @update:modelValue = "item.value.value = $event" />
      </div>

    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import UnifiedInput from '@/components/UnifiedInput'

export default {
  name: 'E3dProjectInfo',
  components: {
    UnifiedInput
  },
  props: {
    projectData: {}
  },
  setup (props) {
    const showLess = ref(true)

    /**
     * This allows user to add more fields of data.
     */
    const addField = () => {}

    function updateProp (p, e) {
      console.log(`p@updateProp=${p}`)
      console.log(`e@updateProp=${e}`)
      p.value = e
    }

    const dataToShow = computed(() => {
      let length = showLess.value ? 1 : props.projectData.length
      return props.projectData.slice(0, length)
    })

    return {
      addField, updateProp, showLess, dataToShow
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../styles/components/index.css" scoped></style>

<style scoped>
  * {
    box-sizing: border-box;
  }

  /* html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  } */

  .label {
    text-align: right;
  }

  .project-info-area {
    display: inline-block;
    width: 400px;
    justify-content: center;
    align-items: center;
  }

  table { /* https://adamlynch.com/flexible-data-tables-with-css-grid/ */
    display: grid;
    border-collapse: collapse;
    /* min-width: 100%; */
    /* These are just initial values which are overriden using JavaScript when a column is resized */
    grid-template-columns:
      minmax(100px, 100%);
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    position: sticky;
    top: 0;
    background: #6c7ae0;
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    color: white;
    position: relative;
  }

  th:last-child {
    border: 0;
  }

  .resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0;
    width: 3px;
    cursor: col-resize;
  }

  .resize-handle:hover,
  /* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
  .header--being-resized .resize-handle {
    opacity: 0.5;
  }

  th:hover .resize-handle {
    opacity: 0.3;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #808080;
  }

  tr:nth-child(even) td {
    background: #f8f6ff;
  }
</style>
