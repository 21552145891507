<template>
  <SearchBox v-if="!($route.fullPath.includes('embed'))">
    <Toolbar :calculation='theCalculation' :settings='settings'
             @saveCalcData='saveCalcData()'
             @saveAsCalcData='saveAsCalcData()'
             @deleteCalc='deleteCalculator()'
             @shareCalc='shareCalculator()'
             @addChart='addChart()'/><br>
  </SearchBox>

  <div v-if="charts">
    <div v-for="chart in charts" :key="chart.chartID">
      <VueChart :chart='chart' />
    </div>
  </div>

  <div v-if="projectDataArray">
    <!-- <E3dProjectInfo v-if="settings.showProjectInfo" :projectData='projectDataArray'></E3dProjectInfo> -->
    <E3dProjectInfo :projectData='projectDataArray'></E3dProjectInfo>
  </div>

  <div v-if="theCalculation.PQs.length !== 0"> <!-- calc form/table header  -->
    <E3dPhysicalQuantity :physicalQuantity="pq0"
      @autoUnitConversion='autoUnitConversion()'
      @addPQ_PE="theCalculation.addPqPe"/>

    <span v-if="!showPQSettings" style="height: 2px;"></span>
    <span v-else style="height: 2px;"></span>
  </div>

  <draggable v-model="theCalculation.P_Qs.value" handle=".drag-handle">
  <!-- <draggable :list="PQs" :sort="true" @end='end' @drop='drop' @start="drag=true" :move="checkMove"> -->
    <div v-for="(pq, i) in theCalculation.P_Qs.value" :key="pq.id">
      <E3dPhysicalQuantity :physicalQuantity="pq" :rowId='i'
        v-if="pq.showPQRow || settings.showHiddenRows"
        :showExpression="isNaN(pq.expression) && settings.showHiddenRows"
        @deletePQ_PE="theCalculation.deleteCurrentPQ(pq, i)"
        @showPQ_PE="theCalculation.hideCurrentPQ(pq, i)"
        @rerunCalc="theCalculation.rerun()" />
    </div>
  </draggable> <br>

  <ImageController ref='imageController' v-show="settings.showImageInput" />

</template>

<script>
/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */
import { reactive, ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import * as utilsPub from "../API/utils-public"
import E3dPhysicalQuantity from "./PhysicalQuantity.vue"
import E3dProjectInfo from '@/components/ProjectInfo.vue'
import ImageController from '@/components/ImageController.vue'
import Toolbar from '@/components/Toolbar.vue'
import SearchBox from '@/components/SearchBox.vue'
import useCalculation from '@/composables/useCalculation'
// import getDocument from '@/composables/getDocument'
import getCalc from '@/composables/getCalc'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import useKeydown from '@/composables/useKeydown'

import { VueDraggableNext } from 'vue-draggable-next'
import VueChart from '@/components/VueChart.vue'

import {
  PhysicalQuantity,
  /* normalizeExpression,
  mathMethodListFull, mathPropListFull */
} from "../API/pq.js"

/* const projectData0 = {
  info: [
    { type: "text", label: "Title", value: '' },
    { type: "textarea", label: "Description", value: '' },

    { type: "text", label: "Project Name", value: '' },
    { type: "text", label: "Category", value: '' }, // math, physics, mechanical, structural, daily-life, etc.
    { type: "text", label: "Keywords", value: '' },
    // "protected": access by group member.
    { type: "datalist", label: "Visibility", value: 'public', datalist: [ 'public', 'private', 'protected' ] },
    { type: "text", label: "Status", value: '' },
    { type: "text", label: "Owner", value: 'anonymous', readonly: true },
    { type: "date", label: "Date", value: new Date().toISOString().substring(0, 10) },
    { type: "text", label: "Rev", value: '' },
    { type: "textarea", label: "Note", value: '' },
    { type: "textarea", label: "References", value: '' }
  ]
} */

let projectData0 = new Map()
projectData0
  .set('title', { type: "text", label: "Title", value: 'Demo' })
  .set('description', { type: "textarea", label: "Description", value: '' })
  .set('projectName', { type: "text", label: "Project Name", value: '' })
  //category: math, physics, mechanical, structural, daily-life, etc.
  .set('category', { type: "text", label: "Category", value: 'Misc.' })
  .set('keywords', { type: "text", label: "Keywords", value: '' })
  // "protected": access by group member.
  .set('visibility', { type: "datalist", label: "Visibility", value: 'public', datalist: [ 'public', 'private', 'protected' ] })
  .set('status', { type: "text", label: "Status", value: '' })
  .set('ownerDN', { type: "text", label: "Owner", value: "anonymous", readOnly: 'true' })
  .set('createdAt', { type: "date", label: "createdAt", value: new Date().toISOString().substring(0, 10) })
  .set('updatedAt', { type: "date", label: "updatedAt", value: new Date().toISOString().substring(0, 10) })
  .set('rev', { type: "text", label: "Rev", value: '' })
  .set('note', { type: "textarea", label: "Note", value: '' })
  .set('references', { type: "textarea", label: "References", value: '' })

export default {
  name: "Calculator0",
  components: {
    E3dPhysicalQuantity,
    ImageController,
    E3dProjectInfo,
    Toolbar,
    SearchBox,
    draggable: VueDraggableNext,
    VueChart
  },
  props: ['id'],
  setup(props) {
    debugger
    const { user } = getUser()

    console.log(`id: ${props.id}`)

    const isTesting = ref(false)

    const settings = reactive({
      showProjectInfo: false,
      showPQSettings: false,
      showImageInput: false,
      showSaveAsNewCalc: true, // this is indicator of being existing calc.
      showDeleteCalc: true,

      showTooltip: false,
      autoSymbol: false,
      alertIndicatorON: false, // turned ON by app whenever error msg is found.
      angleInDegrees: true,
      unitConversionAuto: true,

      showHiddenRows: true,
    })

    const libID = ref('mathCalc') // default to 'mathCalc'
    const router = useRouter()
    const route = useRoute()

    const showTooltip = ref(false)

    const projectID = ref('')
    const calcID = ref(props.id)

    const projectData = reactive(new Map())

    const visibility = ref('public')

    const userID = ref('')
    const ownerID = ref('')
    const ownerDN = ref('')
    const creatorID = ref('')
    const creatorDN = ref('')

    const fileError = ref(null)

    const imageController = ref(null)

    const imageFilePath = ref('')
    // const imageUrls = reactive([]) // obsolete
    const imageFileInput = ref(null)

    const images = reactive([]) // for all images, including from local harddrive & clipboard
    const imageFiles = reactive([]) // images loaded into memory from local harddrive
    const imageBlobs = reactive([]) // images pasted into memory from clipboard
    const imageUploadError = ref(null)

    const pq0 = reactive(new PhysicalQuantity({
      name: "Description",
      symbol: "Symbol",
      expression: 'Quantity/Expression',
      unit: "Unit[Auto]",
      unitConversionAuto: settings.unitConversionAuto
    }))

    const theCalculation = useCalculation(settings) // set calculation container for calc data from FB

    const unitConversionMode = computed(() => settings.unitConversionAuto ? 'Auto' : 'Manual')

    const angleUnit = ref('deg')
    const autoSymbol = ref(false)
    const isStarting = ref(true) // when true, show starting screen status, allow user to add PE/PQ/Demo
    const showPQSettings = ref(false)

    const alertIndicatorON = ref(false) // turned ON by app whenever error msg is found.

    const searchTerm = ref('')

    const { filePath, url, upload: uploadImage, delete: deleteImage, deleteAll: deleteAllImages } = useStorage()

    const calcLocationPath = computed(() => isTesting.value ? 'testCalcs' : 'calcs')

    const imageLocationPath = computed(() => user.value ?
      `${calcLocationPath.value}/images/tier01/${user.value.uid}/${projectID.value}/${calcID.value}/` :
      `${calcLocationPath.value}/images/tier00/anonymous/${projectID.value}/${calcID.value}/`
    )

    const { error0, addDoc } = useCollection(libID.value) // libID is not actually used in useCollection.
    const { error, calc: calculator, load: loadCalc, deleteCalc, loadImages, urls } =
      getCalc(calcLocationPath.value, calcID.value)

    const imageLocationPathExisting = computed(() =>
      `${calcLocationPath.value}/images/tier01/${calculator.value.ownerID}/${projectID.value}/${calcID.value}/`
    )

    /**
     * calculator accessibility / openness:
     * - no_access: owner's private
     * - readonly/protected: users that are not owner can use the calc if it is public, ie: change inputs
     * - read_write: full access to the calc's all expressions to customize and save as own copy
     * - read_write_save: owner has full access
     * - read_write_saveas:
     *
     * What about registered and unregistered status?
     */
    const calcAccessibility = computed(() => {
      if (user.value.uid === calculator.value.userID) { return 'READ_WRITE_SAVE'} // owner only
      else if ( calculator.value.visibility === 'public') { return 'READ_WRITE_SAVEAS'} // public calcs can be cloned by other
      else if ( calculator.value.visibility === 'protected') { return 'READ_ONLY'} // can be used by others, but not save result into FB. How about printability?
      else { return 'NO_ACCESS' }
    })

    const projectDataArray = computed(() => {
      return Array.from(projectData, ([key, value]) => ({key, value}))
    })

    const charts = reactive([])

    onMounted(() => {
      pq0.unit = `Unit[${unitConversionMode.value}]`
      initCalcData()
    })

    /**
     * - If there is input id, and there is calcs data searched from FB available already, then grab data from there. => This is what happens when user choose a calc from Calculators component.
     * - If calcsData is not available, then search FB by the id. => This is what happens when user enter a calc specific url in address bar.
     * - If found, poppulate data for the calc per the id.
     * - If no id, then start with blank page allowing user to start from scratch, or search ...
     *
     */
    const initCalcData = async () => {
      projectID.value     = ''
      theCalculation.clearCalc()

      await loadCalc() // load from FB the existing calc data to calculator

      if (calculator.value) {
        projectID.value     = calculator.value.projectID
        settings.angleInDegrees = calculator.value.angleInDegrees
        settings.showHiddenRows = calculator.value.showHiddenRows ? calculator.value.showHiddenRows : true

        calculator.value.projectData.forEach((item) => { projectData.set(item.key, item.value) })

        // projectData = calculator.value.projectData ? calculator.value.projectData.info : projectData0.info

        ownerID.value       = calculator.value.ownerID
        ownerDN.value       = calculator.value.ownerDN
        creatorID.value     = calculator.value.creatorID
        creatorDN.value     = calculator.value.creatorDN

        /* ownerID.value       = 'OLNFxYMdlPUdgXNi0gz9jPYURrq1'
        ownerDN.value       = 'dw'
        creatorID.value     = 'OLNFxYMdlPUdgXNi0gz9jPYURrq1'
        creatorDN.value     = 'dw' */

        imageFilePath.value = calculator.value.imageFilePath //eg: "test/Mixer 4 Layout.JPG"
        // eg: https://firebasestorage.googleapis.com/v0/b/auto-calc-80237.appspot.com/o/test%2FMixer%204%20Layout.JPG?alt=media&token=035cd2e0-0121-4f30-b267-b0b5efc73232

        /* if (calculator.value.imageUrls) {
          imageController.value.imageUrls.push(...calculator.value.imageUrls) // obsolete
        } */

        /* if (imageController.value.images.length) {
          settings.showImageInput = true
        } */

        if (calculator.value.images !== undefined) { // make sure images[] exist, then proceed further.
          if (calculator.value.images.length) {
            settings.showImageInput = true
            imageController.value.images.push(...calculator.value.images)
          }
        }

        route.meta.title = projectData.get('title').value
        route.params.calcTitle = projectData.get('title').value
        console.log(`$$$$$$$$$route.param: ${route.params.calcTitle}`)
        console.log(`$$$$$$$$$route.meta: ${route.meta.title}`)

        theCalculation.clearCalc()
        theCalculation.PQs.push(...calculator.value.quantities.map(qty => new PhysicalQuantity(qty)))

        // TODO: to prove the brute force is enough to get all PEs recalulated.
        // for ( let i = 0; i < theCalculation.PEs.value.length + 5; i++) { theCalculation.rerun() }
        // theCalculation.rerun()
        theCalculation.rerun()
      }

      // await loadImages(projectID.value) // this does not seem to be needed. imageUrls can be read directly as data properties and used in <img :src='url'>

      /* if(urls.value) {
        console.log(`init: ${urls.value}`)
      } */
    }

    function clearWorkspace() {
      searchTerm.value = ''
      theCalculation.clearCalc()
    }

    const _calcDesc = computed(() => {
      // let descInfo = projectData.info.find((info) => info.label === 'Description')
      // return descInfo.value
      return projectData.get('description')
    })

    const calcTitle = computed(() => {
      return projectData.get('title')
    })

    /**
     * https://jsfiddle.net/chrisvfritz/1oqjojjx/?utm_source=website&utm_medium=embed&utm_campaign=1oqjojjx
     * @param {*} ev
     */
    function selectAll(ev) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function () { ev.target.select() }, 200)
    }

    function switchTooltipOn() {
      setTimeout( ()=> { showTooltip.value = true; }, 300 );
    }

    function switchTooltipOff() {
      setTimeout( ()=> { showTooltip.value = false; }, 2000 );
    }

    function autoUnitConversion() {
      settings.unitConversionAuto = !settings.unitConversionAuto

      pq0.unitConversionAuto = settings.unitConversionAuto
      console.log(`@autoUnitConversion: ${JSON.stringify(pq0)}`)
      pq0.unit = `Unit[${unitConversionMode.value}]`
    }

    const toggleImageOptions = (img) => {
      img.showImageOptions = !img.showImageOptions
    }

    const saveCalcData = async () => {
      /**
       * A list for images to be deleted is created in ImageController, as imagesToDelete array.
       * Here is to commit deletion.
       */
      // await deleteAllImages(imageLocationPath.value)  // TODO: prompt user to confirm!!!

      // to recover so that anonymous can not delete.
      // if (!user.value /* for anonymous */|| user.value.uid !== ownerID.value) {
      //   alert("Only the owner can save this calc. You can clone it to your own copy to customize it.")
      //   return
      // }

      if (!user.value /* for anonymous */|| user.value.uid !== ownerID.value) {
        alert("Only the owner can save this calc. You can clone it to your own copy to customize it.")
        return
      }

      let res = confirm("Confirming you want to overwrite!")
      if (res === false) return

      if (imageController.value.imagesToDeletefromFB.length) {
        imageController.value.deleteImagesFromFB(imageLocationPathExisting.value)
      }

      // upload newly added images, which should all be in imageFiles[]
      if (imageController.value.imageFiles) {
        await imageController.value.appendImages(imageLocationPathExisting.value)
      }

      const updatedAt = new Date().toISOString().substring(0, 10)
      projectData.set('updatedAt', Object.assign({}, projectData.get('updatedAt'), { value: updatedAt }))

      console.log(`=================projectData: ${projectData}`)

      await addDoc(calcLocationPath.value, calcID.value, {
        calcID: calcID.value,
        projectID: projectID.value,

        calcTitle: projectData.get('title').value,
        calcDesc: projectData.get('description').value, // to be used for FB filter

        angleInDegrees: settings.angleInDegrees,
        showHiddenRows: settings.showHiddenRows,

        ownerID: ownerID.value ? ownerID.value : 'anonymous', // when overwriting the calc, owner stay unchanged.
        ownerDN: ownerDN.value ? ownerDN.value : 'anonymous',
        creatorID: creatorID.value ? creatorID.value : 'anonymous', // stay unchanged
        creatorDN: creatorDN.value ? creatorDN.value : 'anonymous',

        projectData: Array.from(projectData, ([key, value]) => ({key, value})),

        // ownerEmail: ownerEmail.value,

        quantities: theCalculation.PQs.map(pq => pq.dataObj),

        imageFilePath: filePath.value, // for single image, obsolete.
        imageUrl: url.value, // for single image, obsolete.
        // imageUrls: imageController.value.imageUrls, obsolete
        images: imageController.value.images,
        timeStamp: new Date()
      })

      debugger

      router.push({ name: 'E3dCalculator', params: { id: calcID.value, calcTitle: projectData.get('title').value } }) // force page refresh. however not seem to work. need to rework

      // TODO: when uploading is done, imageFiles[] array should be reset so it becomes fresh state for further changes.
    }

    const saveAsCalcData = async () => {
      projectID.value = utilsPub.UUID('p')
      calcID.value = utilsPub.UUID('c')
      // setup for access permission here

      // always update ownerID, ownerDN to current user
      ownerID.value = user.value ? user.value.uid : 'anonymous'
      ownerDN.value = user.value ? user.value.displayName : 'anonymous'

      let newOwner = Object.assign({}, projectData.get('ownerDN'), { value: ownerDN.value })
      projectData.set('ownerDN', newOwner)

      // TODO: auth - when the calc is not public, prompt the current user and return.

      /* if (imageController.value.imagesToDeletefromFB) {
        imageController.value.deleteImagesFromFB(imageLocationPath.value)
      } */

      // upload newly added images, which should all be in imageFiles[]
      if (imageController.value.images.length) {
        await imageController.value.uploadImages(imageLocationPath.value)
      }

      const updatedAt = new Date().toISOString().substring(0, 10)
      projectData.set('createdAt', Object.assign({}, projectData.get('createdAt'), { value: updatedAt }))
      projectData.set('updatedAt', Object.assign({}, projectData.get('updatedAt'), { value: updatedAt }))

      /* do I want to allow visibility to be inherited when cloning?
      const visibility = projectData.get('visibility').value.value
      if (visibility !== 'public' ) {
        projectData.set('visibility', Object.assign({}, projectData.get('visibility'), { value: 'public' }))
      } */

      await addDoc(calcLocationPath.value, calcID.value, {
        calcID: calcID.value,
        projectID: projectID.value,

        calcTitle: projectData.get('title').value,
        calcDesc: projectData.get('description').value, // to be used for FB filter?

        angleInDegrees: settings.angleInDegrees,
        showHiddenRows: settings.showHiddenRows,

        // when saveAs the calc, owner changed to the current user.
        ownerID: ownerID.value,
        ownerDN: ownerDN.value,
        creatorID: creatorID.value, // stay unchanged
        creatorDN: creatorDN.value, // TODO: createrID can change if orignial calc was set to allow using as template.

        // ownerEmail: ownerEmail.value,

        projectData: Array.from(projectData, ([key, value]) => ({key, value})),

        quantities: theCalculation.PQs.map(pq => pq.dataObj),

        imageFilePath: filePath.value, // for single image, obsolete.
        imageUrl: url.value, // for single image, obsolete.
        // imageUrls: imageController.value.imageUrls,
        images: imageController.value.images,
        timeStamp: new Date()
      })

      router.push({ name: 'E3dCalculator', params: { id: calcID.value, calcTitle: projectData.get('title').value } })
    }

    const deleteCalculator = () => {
      try {
        if (user.value.uid === ownerID.value || user.value.displayName == 'dw' ) {
          alert(`Confirm you want to delete the calculation!`)
          // get the doc again
          deleteCalc()
          // get the image directory
          deleteAllImages(imageLocationPathExisting.value)
        } else {
          // alert(`Only the owner can delete the calc.`)
          throw Error('Only the owner can delete the calc.')
        }
      } catch (err) {
        console.log(`@deleteCalculator: err = ${err.message}`)
        alert(`Only the owner can delete the calc. \nCalcs created by anonymous users will be deleted by Admin in ONE month.`)
        return
      }

      // theCalculation.clearCalc()

      // route to newCalc
      router.push({ name: 'NewCalculator' })
    }

    const shareCalculator = () => {
      alert(`Share the calculation!`)
    }

    function addChart() {
      let chart = {
        chartID: 'chart' + (charts.length + 1),
        calculation: theCalculation
      }

      charts.push(chart)
    }

    useKeydown([
      { key: 'q', altKey: 'true', fn: theCalculation.addPqPe },
      // { key: 's', ctrlKey: 'true', fn: saveCalcData },
    ])

    watch(settings, () => {
      theCalculation.settings = settings
      theCalculation.rerun()
    })

    return {
      router, route,
      theCalculation, settings, projectData, projectDataArray,
      user, ownerID, ownerDN, creatorDN,
      calcID,
      pq0, libID, projectID,
      calculator, deleteCalc, deleteCalculator, loadImages, urls,
      error,
      showPQSettings, alertIndicatorON, autoUnitConversion, unitConversionMode,
      searchTerm,
      angleUnit, // set angle to deg or rad for this calc.
      initCalcData,
      clearWorkspace, selectAll, calcTitle,
      showTooltip, switchTooltipOn, switchTooltipOff,
      saveCalcData, saveAsCalcData,
      imageController,
      charts, addChart,
      fileError,
      imageFilePath,
      imageFileInput,
      toggleImageOptions
    }
  },

}
</script>

<style src="../styles/components/index.css" scoped></style>
