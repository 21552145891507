<template>
  <div class="box items-center p-3" :class="[withBubbleClass ? bubbleClass : '']">
    <!-- <div :class="[isActive ? activeClass : '', errorClass]"></div> -->
    <slot />
  </div>

  <!--
    <div class="box sb1">I'm speech bubble</div>
    <div class="box sb2">I'm speech bubble</div>
    <div class="box sb3">I'm speech bubble</div>
    <div class="box sb4">I'm speech bubble</div>
  -->
</template>

<script>
/***
 * // refs:
 * https://codingislove.com/css-speech-bubbles/
 * https://codepen.io/RajRajeshDn/pen/oZdRJw
 */
import { ref } from 'vue'
export default {
  props: {
    type: {
      type: String,
      default: 'sb0'
    }
  },
  setup (props) {
    const withBubbleClass = true
    const bubbleClass = ref(props.type)
    return { bubbleClass, withBubbleClass }
  }
}
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}

.box {
  display: flex;
  width: auto;
  margin: 2px auto;
  background: lightgray;
  align-items: center;
  color: blue;
  font-family: arial;
  position: relative;
  border-radius: 6px;
  left: -80px;
}

.sb0:before {  /* copy from sb4 */
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid lightgray;
  border-top: 10px solid lightgray;
  border-bottom: 10px solid transparent;
  right: 10px;
  bottom: -12px;
}

.sb00:before { /* copy from sb3 */
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 6px solid lightgray;
  border-right: 6px solid transparent;
  border-top: 10px solid lightgray;
  border-bottom: 10px solid transparent;
  left: 9px;
  bottom: -15px;
}

.sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid lightgray;
  border-right: 10px solid transparent;
  border-top: 10px solid lightgray;
  border-bottom: 10px solid transparent;
  right: -19px;
  top: -6px;
}

/* .sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #00bfb6;
  border-right: 10px solid transparent;
  border-top: 10px solid #00bfb6;
  border-bottom: 10px solid transparent;
  right: -19px;
  top: 6px;
}
 */

.sb2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid lightgray;
  border-top: 10px solid lightgray;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 6px;
}

.sb3:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid lightgray;
  border-right: 10px solid transparent;
  border-top: 10px solid lightgray;
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}

.sb4:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #00bfb6;
  border-top: 10px solid #00bfb6;
  border-bottom: 10px solid transparent;
  right: 19px;
  bottom: -19px;
}

</style>
