<template>
  <div class="flex flex-row justify-center items-center">

    <button class="roundBtn" title="Add a Quantity / Expression [Alt+Q]"
        @click="calculation.addPqPe()">
      <img
          src="../assets/icons/add_circle_outline_black_24dp.svg"
          height="20" width="20" />
    </button>

    <button class="roundBtn" title="Create New Calculation"
            v-if="theSettings.showSaveAsNewCalc"
            @click="$router.push({name: 'NewCalculator'})">
            <img
              src="../assets/icons/note_add_black_24dp.svg"
              alt="Create New Calculation."
              height="20" width="20" />
    </button>

    <button class="roundBtn" title="Save Calculation"
            @click="$emit('saveCalcData')">
            <img
              src="../assets/icons/file-save-24px.svg"
              alt="Save the Calculation to Cloud."
              height="20" width="20" />
    </button>

    <button class="roundBtn" title="Save As New Calculation"
            v-if="theSettings.showSaveAsNewCalc"
            @click="$emit('saveAsCalcData')">
            <img
              src="../assets/icons/save-as.64.png"
              alt="Save Calculation to Cloud As..."
              height="20" width="20" />
    </button>

    <!-- <button class="roundBtn" title="Share..."
            v-if="theSettings.showSaveAsNewCalc"
            @click="$emit('shareCalc')">
            <img
              src="../assets/icons/share_black_24dp.svg"
              alt="Share this Calculation"
              height="20" width="20" />
    </button> -->

    <button class="roundBtn" title="Clear Workspace"
            @click="calculation.clearCalc(); calculation.addPqPe()">
            <img
              src="../assets/icons/cancel_black_24dp.svg"
              alt="Clear the Workspace"
              height="20" width="20" />
    </button>

    <button class="roundBtn" title="Delete the Calc"
            v-if="theSettings.showDeleteCalc"
            @click="$emit('deleteCalc')">
            <img
              src="../assets/icons/delete_forever_black_24dp.svg"
              alt="Delete the Calculation"
              height="20" width="20" />
    </button>

    <button class="roundBtn text-sm font-bold" title="Degrees/Radians"
            @click="toggleAngleInDegrees"> {{ angleUnit }}
    </button>

    <!-- Only show below for registered user -->
    <!-- <button class="roundBtn text-sm font-bold" title="To Embed in Other Websites"
            v-if="$route.fullPath.includes('/calculators/')"
            @click="todo()"> <b>&lt;/&gt;</b>
    </button> -->

    <button class="roundBtn" title="Show Settings" v-if="false"
            @click="toggleShowPQSettings">
        <img
          src="../assets/icons/settings-24px.svg"
          alt="Show PQ Settings"
          height="20" width="20" />
    </button>

    <!-- <BaseCheckbox v-model="showPQSettings" label='showPQSettings' @change="$emit('showPQSettings')" /> -->

    <button class="roundBtn" title="Auto Symbol"
            v-if="false"
            @click="toggleAutoSymbol"
            v-bind:class="[{enabled: theSettings.autoSymbol}, {disabled: !theSettings.autoSymbol}]">sym</button>

    <button class="roundBtn" title="Show Project Info"
            @click="toggleShowProjectInfo">
        <img
          src="../assets/icons/info_black_24dp.svg"
          alt="Show Project Info"
          height="20" width="20" />
    </button>

    <button class="roundBtn" title="Insert Images"
            @click="toggleShowImageInput">
            <img
              src="../assets/icons/add_photo_alternate-24px.svg"
              alt="Insert Image for Calculation"
              height="20" width="20" />
    </button>

    <button class="roundBtn" title="Insert X-Y Charts"
            @click="$emit('addChart')">
            <img
              src="../assets/icons/chart-multiple-black-24px.png"
              alt="Add Chart"
              height="20" width="20" />
    </button>

    <button v-if="theSettings.alertIndicatorON" class="roundBtn" title="Show Alerts"
            @click="toggleShowPQAlert">⚠️
    </button>

    <button class="roundBtn" :title="theSettings.showHiddenRows ? 'Hide Hidden Rows' : 'Show Hidden Rows'"
            @click="toggleShowHiddenRows">
            <img v-if="theSettings.showHiddenRows"
              src="../assets/icons/visibility_FILL0_wght400_GRAD0_opsz48.svg"
              alt="Show Hidden Rows"
              height="20" width="20" />
            <img v-if="!theSettings.showHiddenRows"
              src="../assets/icons/visibility_off_FILL0_wght400_GRAD0_opsz48.svg"
              alt="Show Hidden Rows"
              height="20" width="20" />
    </button>

    <!-- <button class="roundBtn toolbarItem" title="Share...(to come)"
            @click="shareCalc">
            <img style="display: inline;"
              src="../assets/icons/share-circle-black.png"
              alt="Share ..."
              height="20" width="20" />
    </button> -->

    <!-- <button class="roundBtn toolbarItem" title="Fork...(to come)"
            @click="forkCalc">
            <img style="display: inline;"
              src="../assets/icons/source-fork-black.png"
              alt="Fork Calc"
              height="20" width="20" />
    </button> -->

    <!-- <button class="roundBtn toolbarItem" title="Likes..."
            @click="updateLikes">
            <img style="display: inline;"
              src="../assets/icons/stars_black_24dp.svg"
              alt="Star"
              height="20" width="20" />
    </button> -->

    <!-- <button class="roundBtn toolbarItem" title="Show Help"
      @click="$router.push({name: 'Help'})">
      <img style="display: inline;"
        src="../assets/icons/help_outline-24px.svg"
        alt="Show Help"
        height="12" width="12" />
    </button> -->

    <a href="https://donwen.com/v1/unit-converter" class="toolbarItem" target="_blank">
      <button class="roundBtn toolbarItem" title="Unit Converter">
        <img
          src="../assets/icons/unit_converter_black_24dp2x.png"
          alt="Show Help"
          height="20" width="20" />
      </button>
    </a>

    <button class="roundBtn" title="Show Help"
      @click="openLinkInNewTab({name: 'Help'})">
      <img
        src="../assets/icons/help_outline-24px.svg"
        alt="Show Help"
        height="20" width="20" />
    </button>

  </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
// import BaseCheckbox from '@/components/BaseCheckbox'

export default {
  name: 'E3dToolbar',
  emits: ['saveCalcData', 'saveAsCalcData', 'shareCalc', 'deleteCalc', 'addChart'],
  components: {
    // BaseCheckbox
  },
  props: {
    calculation: Object,
    settings: Object
  },
  setup (props) {
    const router = useRouter()

    const theSettings = reactive(props.settings)

    const angleUnit = computed(() =>
      theSettings.angleInDegrees ? 'DEG' : 'RAD'
    )

    function toggleShowPQSettings () {
      theSettings.showPQSettings = !theSettings.showPQSettings
    }

    function toggleShowProjectInfo () {
      theSettings.showProjectInfo = !theSettings.showProjectInfo
    }

    function toggleShowImageInput () {
      theSettings.showImageInput = !theSettings.showImageInput
    }

    function toggleShowTooltip () {
      theSettings.showTooltip = !theSettings.showTooltip
    }

    function toggleShowHiddenRows () {
      theSettings.showHiddenRows = !theSettings.showHiddenRows
    }

    function toggleAutoSymbol () {
      theSettings.autoSymbol = !theSettings.autoSymbol
    }

    function toggleAngleInDegrees () {
      theSettings.angleInDegrees = !theSettings.angleInDegrees
    }

    function shareCalc () { // to update
      // theSettings.share++
      console.log('shareCalc')
    }

    function forkCalc () {
      // theSettings.fork++
      console.log('fork')
    }

    function updateLikes () {
      // theSettings.likes++
      console.log('likes')
    }

    function openLinkInNewTab (route) {
      const routeData = router.resolve(route)
      window.open(routeData.href)
    }

    function todo () {
      console.log('todo')
    }

    return {
      router,
      theSettings,
      toggleShowPQSettings,
      toggleShowProjectInfo,
      toggleShowImageInput,
      toggleShowTooltip,
      toggleShowHiddenRows,
      toggleAutoSymbol,
      toggleAngleInDegrees,
      angleUnit,
      shareCalc,
      forkCalc,
      updateLikes,
      openLinkInNewTab,
      todo
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../styles/components/index.css" scoped></style>
<style lang="scss" scoped>
  a:hover {
    // background-color: rgb(207, 240, 207);
    background-color: white;
    // color: white;
    // padding: 2px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
</style>
