/** 20211207: borrowed from '18000 general-calcs' */
// const definition for unit conversion
// export module Uom {
export const POUNDMASS_KG = 0.45359237 // factor from pound to KG
export const GRAVITY_SI = 9.80665
export const MILE_FOOT = 5280.0
export const INCH_METER = 25.4 / 1000
export const FOOT_METER = 12 * INCH_METER
export const NM_METER = 1852         // https://en.wikipedia.org/wiki/Nautical_mile
export const ACRE_FOOT2 = 66.0 * 660 // 1 acre = 66* 660 ft²
export const GALLON_UK_IMPERIAL_LITRE = 4.54609 // https://en.wikipedia.org/wiki/Gallon
export const GALLON_US_LIQUID_IN3 = 231.0 // 1 Gal US liquid = 231 in³
export const GALLON_US_DRY_IN3 = 268.8025
// https://en.wikipedia.org/wiki/Pint Each of these pints is one-eighth of its respective gallon, but the gallons differ.
export const PINT_GALLON = 1.0 / 8
export const OUNCE_FLUID_PINT_UK_IMPERIAL = 1.0 / 20
export const OUNCE_FLUID_PINT_US_LIQUID = 1.0 / 16
export const BUSHEL_GALLON_UK_IMPERIAL = 8.0 // https://en.wikipedia.org/wiki/Bushel#Volume
export const BUSHEL_GALLON_US_DRY = 8.0 // https://en.wikipedia.org/wiki/Bushel#Volume
export const SLUG_KG = 14.59390294 // 1 slug = 14.59390294 kg
export const BTU_J = 1055.05585262 // 1 BTU = 1055.05585262 J per wiki IT
// http://www.conversion-website.com/energy/from-calorie-IT.html
// 1 Cal = 4.1868 J, International Steam table calorie (1929)
export const CALORIE_IT_J = 4.1868 // International Steam table
export const CALORIE_TH_J = 4.184  // Thermochemical
export const HP_W = 550 * POUNDMASS_KG * GRAVITY_SI * FOOT_METER // 1 HP=550 lbf-ft/s, HP_W=745.5
export const ATM_PA = 101325.0 // 1 atm = 101325 pa
export const TORR_ATM = 1.0 / 760
export const MMHG_PA = 133.322387415 // 1 mmHG = 133.322387415 Pa
export const F_K_C0 = 5.0 / 9 // deltaF = 5/9 deltaK
export const MOLE = 6.02214076e23 // Avogadro's number
export const COULOMB_ELECTRON = 6.241e18 // 1 coulomb = 6.24e18 e
export const J_EV = 6.24150974e18 // 1 J = 6.24150974e18 eV (1 eV = 1.602e-19 J)
export const BYTE_BIT = 8
// }
