import { ref } from 'vue'
import { auth } from '@/tools/firebase/fb'

const error = ref(null)
const isPending = ref(false)

const signup = async (email, password, displayName) => {
  error.value = null
  isPending.value = true

  try {
    const res = await auth.createUserWithEmailAndPassword(email, password)

    await res.user.sendEmailVerification().then(function () {
      // Email sent.
      console.log('verification email sent')
    }).catch(function (error) {
      // An error happened.
      console.log(`error: ${error}`)
      throw new Error('Could not verify email')
    })

    await res.user.updateProfile({ displayName })

    /* if (!res) {
      throw new Error('Could not complete signup')
    } */

    // https://stackoverflow.com/questions/41326951/firebase-email-verification-behavior?rq=1#comment69977910_41341827
    await res.user.reload()

    if (!res.user.emailVerified) {
      isPending.value = false //
      throw new Error('Check your email to activate your accound.')
    }

    await res.user.updateProfile({ displayName })
    error.value = null
    isPending.value = false

    return res
  } catch (err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const useSignup = () => {
  return { error, signup, isPending }
}

export default useSignup
