<template>
  <div class="flex flex-col">
    <div class="flex justify-center p-2">
      <div class="bg-white flex items-center rounded-full shadow-lg w-4/5 max-w-md">

        <div class="p-1" title="switch to public or private">
          <button class="btn-search-box text-white rounded-full p-1 hover:bg-blue-400 focus:outline-none w-7 h-7 flex items-center justify-center"
            @click.prevent="searchPublic = !searchPublic">
            <img v-if="searchPublic" src="../assets/icons/public_black_24dp.svg"
              alt="Public"
              height="20" width="20"/>
            <img v-else src="../assets/icons/private_black_24dp.svg"
              alt="Private"
              height="20" width="20"/>
          </button>
        </div>

        <input class="rounded-l-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none"
          v-model="searchTerm" id="search" ref="searchInput" autocomplete="off"
          :placeholder="searchPlaceHolder()"
          @keyup.enter="readCalcsData(searchTerm)"
          @keyup.tab="readCalcsData(searchTerm)" />

        <div class="p-1">
          <button class="btn-search-box text-white rounded-full p-1 hover:bg-blue-400 focus:outline-none w-7 h-7 flex items-center justify-center">
            <img src="../assets/icons/search-24px.svg"
              alt="Search"
              height="20" width="20"
              @click.prevent="readCalcsData(searchTerm)"/>
          </button>
        </div>
      </div>
    </div>

    <!-- Below could not apply debounce, need to rework TODO: -->
    <!-- <div class="searchInput">
      <input v-model="searchTerm" id="search" ref="searchInput" autocomplete="off"
        placeholder="Search [Alt+S]"
        @keyup.enter="readCalcsData(searchTerm)"
        @keyup.tab="readCalcsData(searchTerm)"/>
      <img src="../assets/icons/search-24px.svg"
        alt="Search"
        height="20" width="20"
        @click.prevent="readCalcsData(searchTerm)"/>
    </div> -->

    <slot />

    <!-- <div id="v-model-select-dynamic" class="demo" v-if="calcsInCloud">
      <div v-if="filteredCalcsInCloud.length !== 0">
        <br>
        <label for="calcSelected">Searched Calculations: </label>
        <select v-model="calcSelected" @change="changeRoute(calcSelected)">
          <option v-for="calc in filteredCalcsInCloud" :key="calc.id" :value="calc">
            {{calc.calcDesc}}
          </option>
        </select>
        <br><br>
      </div>
    </div>
    <br> -->

    <div v-if="calcsInCloud.length">
      <div class="flex justify-center"
        v-if="filteredCalcsInCloud.length"><br />
        <button @click="showSearchedContent = !showSearchedContent">
          <label v-if="showSearchedContent">
            <img style="display: inline;"
              src="@/assets/icons/arrow_circle_up_black_24dp.svg"
              alt="Show Help"
              height="20" width="20" />
          </label>
          <label v-else>
            <img style="display: inline;"
              src="@/assets/icons/arrow_circle_down_black_24dp.svg"
              alt="Show Help"
              height="20" width="20" />
          </label>
        </button>

        Found <div class="border-b-2 border-black text-blue-700 font-bold px-2"> {{ ` ${filteredCalcsInCloud.length} ` }}</div> calculations:

      </div>

      <div v-else class="flex justify-center">
        Found <div class="border-b-2 border-black text-blue-700 font-bold px-2"> 0 </div> calculations.
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 justify-center" id="searchedContent" v-if="showSearchedContent">
      <div class="flex flex-col justify-center mt-0"
        v-for="(c, index) in filteredCalcsInCloud" :key="c.id">
        <CalculatorCard :calc='c' @calculatorCardClicked="changeRoute(c)" >
          <template v-slot:calcIndex>
            {{ ` ${index+1} ` }}
          </template>
          <template v-slot:calcLink>
            <div class="roundBtn px-4 w-20 text-center"
              @click.stop="showModal = true">
              Share ...
            </div>
          </template>
          <template v-slot:header>
            <!-- <h3>custom header outside</h3> -->
            <ShareModal v-if="showModal" @close="showModal = false" @click.stop="">
              <template v-slot:headerInside>
                <h2 class="text-2xl font-bold text-center p-3">Link to Share</h2>
              </template>
              <template v-slot:body>
                <span class="p-2 w-11/12 text-xs"
                  type="text" readonly
                  @focus="$event.target.select()" >
                  URL: {{ c }}: {{ getLinkUrl(c) }}
                </span>

              </template>
            </ShareModal>
          </template>
        </CalculatorCard>
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */
import { ref, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import getUser from '@/composables/getUser'
import { db /*, storage */ } from '../tools/firebase/fb'
import E3dPhysicalQuantity from '@/components/PhysicalQuantity.vue'
import CalculatorCard from '@/components/CalculatorCard.vue'
import ShareModal from '@/components/ShareModal.vue'
// import E3dCalculator0 from "@/components/E3dCalculator0.vue";
import useKeydown from '@/composables/useKeydown'

export default {
  name: 'SearchBox',
  components: {
    E3dPhysicalQuantity,
    CalculatorCard,
    ShareModal
  },
  setup () {
    const isTesting = ref(false)
    const router = useRouter()
    const route = useRoute()
    const { user } = getUser()
    const libID =  computed(() => isTesting.value ? 'testCalcs' : 'calcs')

    /* const projectID = ref('');
    const projectName = ref('');
    const calcCategory = ref(''); // math, physics, mechanical, structural, daily-life, etc.
    const calcDesc = ref('');
    const calcNote = ref('');
    const metaData = ref('');
    const userID = ref(''); */

    const searchTerm = ref('')
    const searchInput = ref(null)
    const searchPublic = ref(true)
    const showSearchedContent = ref(true)
    const isStarting = ref(true) // when true, show starting screen status, allow user to add PE/PQ/Demo

    const calcsInCloud = reactive(new Array()) // calcs from cloud per user query
    const filteredCalcsInCloud = reactive(new Array()) // used by search
    const calcSelected = ref(null)

    const showModal = ref(false)

    /***
     *
     */
    async function readCalcsData (search) {
      console.log(`db: ${db}`)
      let _calcNote, _calcDesc, _calcTitle, _calcVisibility
      let projectData = new Map()

      calcsInCloud.splice(0, calcsInCloud.length)

      // let mathCalcData = [];
      await db.collection(libID.value) /* .where("calcTitle", "==", search) */
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            console.log(`doc.id = ${doc.id}`, ' => ', doc.data())
            data.projectData.forEach(({key, value}) => projectData.set(key, value))

            calcsInCloud.push({
              id: doc.id,

              calcTitle: data.calcTitle,
              calcDesc: data.calcDesc,
              creatorID: data.creatorID,
              creatorDN: data.creatorDN,
              ownerID: data.ownerID,
              ownerDN: data.ownerDN,
              updatedAt: projectData.get('updatedAt').value,
              visibility: projectData.get('visibility'),

              coverImage: data.images ? data.images[0] : null
            })
          })

        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })

      console.log('--start--')
      let tmpArray0 = new Array()
      tmpArray0 = calcsInCloud.filter(calc => {
        return calc.calcTitle.toLowerCase().includes(search.toLowerCase())
      })

      // calcSelected.value = calcsInCloud[0];
      if (tmpArray0.length === 0) {
        calcSelected.value = null
        filteredCalcsInCloud.splice(0, filteredCalcsInCloud.length)
        // alert('No relevant calcs was found.')
        return
      }

      let tmpArray1 = new Array()

      if (searchPublic.value) { /* all public */
        tmpArray1 = tmpArray0.filter(calc => calc.visibility !== 'private')
      } else { /* all private of current user */
        tmpArray1 = tmpArray0.filter(calc => user.value.uid === calc.ownerID )
      }

      tmpArray1 = tmpArray1.sort((a, b) => (a.calcTitle > b.calcTitle) ? 1 : -1)

      filteredCalcsInCloud.splice(0, filteredCalcsInCloud.length)
      filteredCalcsInCloud.push(...tmpArray1)

      filteredCalcsInCloud.forEach(c => console.log(`c = ${c.id}, desc = ${c.calcDesc}`))
      filteredCalcsInCloud.forEach(c => console.log(`c = ${c.id}, title = ${c.calcTitle}`))
      filteredCalcsInCloud.forEach(c => console.log(`c = ${c.id}, creatorDN = ${c.creatorDN}`))
      filteredCalcsInCloud.forEach(c => console.log(`c = ${c.id}, ownerID = ${c.ownerID}`))
      filteredCalcsInCloud.forEach(c => console.log(`c = ${c.id}, ownerDN = ${c.ownerDN}`))

      console.log('--end--')
      // await router.push({ name: 'Calculators', params: { calcs: JSON.stringify(filteredCalcsInCloud) } })
    }

    async function readCalcsData0 () {
      console.log(`db: ${db}`)
      let _calcNote, _calcDesc

      calcsInCloud.splice(0, calcsInCloud.length)

      // let mathCalcData = [];
      await db.collection(libID.value) /* .where("calcDesc", "==", searchTerm.value) */
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            console.log(`doc.id = ${doc.id}`, ' => ', doc.data())

            let noteInfo = data.projectData.info.find((info) => info.label === 'Note')
            _calcNote = noteInfo.value

            let descInfo = data.projectData.info.find((info) => info.label === 'Description')
            _calcDesc = descInfo.value

            calcsInCloud.push({
              id: doc.id,

              // projectID: data.projectID,
              // projectName: data.projectName,
              // calcCategory: data.calcCategory,

              calcDesc: _calcDesc,
              calcNote: _calcNote,
              // metaData: data.metaData,
              // keyWords: data.keyWords,
              // userID: data.userID,

              // quantities: data.quantities
            })
          })

          // calcSelected.value = calcsInCloud[0];
          if (calcsInCloud.length === 0) {
            calcSelected.value = null
            // initCalcData()
            alert('No relevant calcs was found.')
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })

      console.log('--start--')
      let tmpArray0 = new Array()
      tmpArray0 = calcsInCloud.filter(calc => {
        return calc.calcTitle.toLowerCase().includes(searchTerm.value.toLowerCase())
      })

      tmpArray0 = tmpArray0.sort((a, b) => (a.calcDesc > b.calcDesc) ? 1 : -1)

      filteredCalcsInCloud.splice(0, filteredCalcsInCloud.length)
      filteredCalcsInCloud.push(...tmpArray0)
      console.log('--end--')
    }

    async function changeRoute (calc) {
      const link = { name: 'E3dCalculator', params: { id: calc.id, calcTitle: calc.calcTitle }  }
      const routeData = router.resolve(link)
      // window.open(window.location.origin + routeData.href, '_blank') // open the link in new tab
      // window.open(routeData.href, '_blank') // open the link in new tab -- this used to work before v0.5.0

      await router.push(link)  // open the link in current tab
    }

    async function refreshRoutePerSearch () {
      const link = { name: 'E3dCalculator', params: { calcs: JSON.stringify(filteredCalcsInCloud) }}
      await router.push(link)
    }

    // watchEffect( () => readCalcsData() )

    // watchEffect(() => refreshRoutePerSearch() )

    /**
     * Listen to ctrl-Q to run addPQ_PE()
     * https://www.vuemastery.com/courses/build-a-gmail-clone-with-vue3/usekeydown-composable
     */

    const gotoSearch = () => searchInput.value.focus()

    const getLink = (calc) => {
      const link = { name: 'Calculator', params: { id: calc.id, calcTitle: calc.calcTitle }  }
      const routeData = router.resolve(link)
      return routeData
    }

    const getLinkUrl = (calc) => {
      const link = { name: 'Calculator', params: { id: calc.id, calcTitle: calc.calcTitle }  }
      const routeData = router.resolve(link)
      // console.log(`BASE URL: ${process.env.BASE_URL}`)
      return window.location.origin + routeData.href
      /* window.location.href does not work on localhost but works on dw.com. 'href' comes with a slash. '/' */
      // return window.location.href + routeData.href
    }

    const searchPlaceHolder = () => {
      return searchPublic.value ? "Search Public Calcs [Alt+S]" : "Search Private Calcs [Alt+S]"
    }

    useKeydown([ // can define keyCombos of multiple keyCombos
      { key: 's', altKey: true, fn: gotoSearch }, // keyCombo[0]
      // { key: 'r', fn: toggleRead },
      // { key: 'e', fn: toggleArchive },
    ])

    return {
      user, libID,
      searchTerm, searchInput, searchPublic, searchPlaceHolder,
      showSearchedContent,
      isStarting,
      calcsInCloud, filteredCalcsInCloud, calcSelected,
      readCalcsData, refreshRoutePerSearch,
      /* populateFBCalcData, */
      changeRoute,
      getLink, getLinkUrl,
      showModal
    }
  }

}
</script>

<style src="../styles/components/index.css" scoped></style>

<style scoped>
  img.search {
    margin: 0px;
    position: absolute;
    top: 30%;
  }

  .searchedContent {
    display: inline-block;
    justify-content: center;
  }

</style>
