import { ref } from 'vue'
import { db, storage } from '../tools/firebase/fb'

const getCalc = (collection, id) => {
  const calc = ref(null)
  const error = ref(null)
  const isPending = ref(false)
  const calcRef = db.collection(collection).doc(id)
  const urls = ref([])

  const load = async () => {
    try {
      const res = await db.collection(collection).doc(id).get()
      console.log(res)
      console.log(res.data)

      if (!res.exists) {
        throw Error('That calc does not exist')
      }
      calc.value = { ...res.data(), id: res.id }
      console.log(`@getCalc.load: calc.value ${calc.value}`)
    } catch (err) {
      console.log(`@getCalc.load: err.message: ${err.message}`)
      alert(err.message)
      error.value = err.message
    }
  }

  const deleteCalc = async () => {
    isPending.value = true
    error.value = null

    try {
      const res = await calcRef.delete()
      isPending.value = false
      return res
    } catch (err) {
      console.log(err.message)
      isPending.value = false
      error.value = 'could not delete the document'
    }
  }

  const loadImages = async (projectPath) => {
    const listRef = storage.ref(projectPath)
    const list = await listRef.list({ maxResults: 20 })

    try {
      if (list) {
        list.items.forEach(url => {
          console.log(`loadFSImage: imgUrl= ${url}`)
          urls.value.push(url)
        })
      }
    } catch (err) {
      console.log(err.message)
    }
  }
  return { calc, error, load, deleteCalc, loadImages, urls } // load will run automatically per NN (net ninja) lesson 80. Though lesson 81 does call load() @6:26. Why?
}

export default getCalc
