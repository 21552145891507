<!-- both unitChanged() and expresssionChanged() trigger rerunCalc() -->

<template>
  <!-- 1. SingleMode for conventional calculator -->
  <div v-if="mode === 'SINGLE'"
    class="container flex flex-col justify-center my-16" >
    <div
      class="flex flex-col justify-center mx-auto md:w-4/5"
      @mouseleave="turnSpeechBubbleOff"
      @blur="turnSpeechBubbleOff" >
      <div
        class="text-center p-3 my-3 h-16 bg-gray-300 rounded color-bg-output1 text-blue text-2xl bold"
        :class="{ 'output-placeholder-text': !pq.faceValue }"
        title="Calculated result."
        @click="turnSpeechBubbleOn" >
        {{ faceValue }}
        <!-- {{ pq.faceValue === '' ? 'Result' : Number(pq.faceValue)}} -->

        <div
          class="container relative inline"
          v-if="pq.showPQOptions && isNaN(pq.expression)" >
          <button>&nbsp;&nbsp;&nbsp;</button>
          <div
            class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2"
            v-if="pq.showPQOptions"
            title="fractional digits" >
            <SpeechBubble>
              <input
                class="w-12 border-b rounded-md text-center"
                type="number"
                min="0"
                v-model="pq.fractionalDigits"
                @change="$emit('rerunCalc')" />
            </SpeechBubble>
          </div>
        </div>
      </div>

      <!-- <label for="label" class="input-label">Enter Expression, eg: sin(30)</label> -->
      <input
        class="color-bg-input1 text-blue-700 rounded-lg shadow-lg px-3 py-8 my-3 text-center text-3xl text-crimsontext-semibolditalic leading-6 hover:shadow-2xl"
        title="Enter Quantity / Expression."
        type="text"
        id="label"
        placeholder="Enter Expression, eg: sin(30)"
        v-model.lazy="pq.expression"
        @change="expressionChanged" />
    </div>
  </div>

  <!-- 2. Multi-step Mode for multiple step calc -->
  <div v-else class="container">
    <!-- 2.0 for Header. -->
    <div v-if="pq.expression === 'Quantity/Expression'"
      class="grid gap-1 items-center my-1 mx-2"
      :class="[
        { 'grid-cols-pq17': !pq.showPQOptions },
        { 'grid-cols-pq17': pq.showPQOptions },
      ]">
      <div
        class="col-start-1 col-span-1 header-text btn-add-pq-pe px-auto flex"
        @click="$emit('addPQ_PE')"
        title="Add a Quantity / Expression [Alt+Q]" >
        <img
          src="@/assets/icons/add_circle_outline_black_24dp.svg"
          height="20"
          width="20" />
      </div>
      <input
        class="col-start-2 col-span-6 header-text"
        type="text"
        placeholder=" "
        v-model="pq.name"
        title="Description of the physical quantity/measurement (optional)." />
      <input
        class="col-start-8 col-span-2 header-text"
        type="text"
        placeholder="Symbol"
        v-model="pq.symbol" />
      <div class="col-start-10 col-span-1 none-handle-header">
        <router-link
          class="centered0"
          target="_blank"
          v-if="!$route.fullPath.includes('embed') && $route.params.id"
          :to="{ name: 'EmbeddedCalculator', params: { id: $route.params.id } }" >
          <img
            class="rotating"
            title="Embed this Calcuation in your website with iFrame"
            src="@/assets/e3d-logo2.png"
            width="24" />
        </router-link>
        <router-link
          class="centered0"
          target="_blank"
          v-else-if="$route.params.id"
          :to="{ name: 'E3dCalculator', params: { id: $route.params.id } }" >
          <img
            class="rotating"
            title="Go to the Source Calculator"
            src="@/assets/e3d-logo2.png"
            width="24" />
        </router-link>
        <div v-else>&nbsp;</div>
      </div>
      <input
        class="col-start-11 col-span-4 header-text"
        type="text"
        placeholder=""
        v-model="pq.expression" />
      <span type="text"
        class="col-start-15 col-span-3 header-text"
        :class="[
          { 'btn-unit-auto': unitConversionAuto },
          { 'btn-unit-manual': !unitConversionAuto },
        ]"
        placeholder=""
        @click="$emit('autoUnitConversion')" >
        {{ pq.unit }}
      </span>
    </div>

    <!-- comment starts -->
    <!-- 2.1 for comment. -->
    <!-- <div class="grid grid-cols-pq17 gap-1 mx-2" v-else-if="pq.name.substr(0, 2) === '//'"> -->
    <div v-if="pq.name.substr(0, 2) === '//'"
      class="grid grid-cols-pq17 gap-1 mx-2" >
      <span
        class="col-start-1 col-span-1 text-center relative drag-handle input-pq00"
        title="Drag &amp; Drop to Adjust Position." >
        {{ rowId + 1 }}
      </span>
      <input
        class="col-start-2 col-span-16 input-comment0 text-crimsontext-bold"
        type="text"
        placeholder=" "
        v-model="pq.name"
        title="Comment" />
    </div>
    <!-- comments ending -->

    <!-- 2.2 for PQ integrated w/ unit conversion. -->
    <div v-if="pq.pqType === PqTypes.NUMBER || pq.pqType === PqTypes.BLANK_INPUT"
      class="grid grid-cols-pq17 gap-1 mx-2"
      @mouseleave="turnSpeechBubbleOff"
      @blur="turnSpeechBubbleOff" >
      <span
        class="col-start-1 col-span-1 text-center relative drag-handle input-pq00"
        title="Drag &amp; Drop to Adjust Position." >
        {{ rowId + 1 }}
      </span>

      <input
        class="col-start-2 col-span-6 input-pq0 text-crimsontext-bold"
        type="text"
        placeholder=""
        v-model.lazy="pq.name" />

      <input
        class="col-start-8 col-span-2 input-pq1 text-center text-crimsontext-semibolditalic"
        type="text"
        placeholder=""
        v-model.lazy="pq.symbol"
        title="Enter Symbol Here." />

      <!-- '=' sign with PQOptions -->
      <span
        class="container col-start-10 col-span-1 text-center items-center relative cursor-pointer border-b border-blue-300"
        title="Click to show options."
        @click.stop="pq.showPQOptions = true" >
        <div class="font-semibold text-black">=</div>
        <div
          class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2"
          v-if="pq.showPQOptions"
          title="fractional digits" >
          <SpeechBubble>
            <button
              class="pr-2"
              @click="$emit('deletePQ_PE')"
              title="Delete this Quantity/Expression" >
              <img
                src="@/assets/icons/cancel_black_24dp.svg"
                alt="Delete the Quantity"
                height="20"
                width="20" />
            </button>
            <button
              class="pr-2 bg-red-500"
              @click="$emit('showPQ_PE')"
              title="Hide this Quantity/Expression" >
              Hide
            </button>
            <!-- Below is redundent for PQ -->
            <input
              class="w-10 border-b rounded-md text-center"
              type="number"
              min="0"
              title="fractional digits"
              v-if="!isInput"
              v-model="pq.fractionalDigits"
              @change="$emit('rerunCalc')" />
          </SpeechBubble>
        </div>
      </span>

      <input
        class="col-start-11 col-span-4 input-pq1 text-right qtyInput text-crimsontext-semibolditalic"
        title="Enter Quantity / Expression."
        type="text"
        placeholder=""
        v-model.lazy="pq.expression"
        @change="expressionChanged" />

      <!-- <input class="col-start-15 col-span-3 input-pq0 text-center text-crimsontext-semibolditalic"
        title="Enter Unit Here." type="text" placeholder=""
        v-model.lazy="pq.unit"
        @change="pqUnitChanged()" /> -->
      <!-- <div v-if="pq.unit">
        pq faceValue: {{pq.faceValue}} <br>
        baseValue: {{pq.baseValue}} <br>
        baseUnit: {{pq.baseUnit}} <br>
      </div> -->
      <div class="col-start-15 col-span-3 flex flex-col items-center">
        <auto-complete
          v-model="pq.unit"
          :data="uomCategories"
          :groupKey="'name'"
          :groupOptionsKey="'units'"
          title="Click to Choose from Unit List."
          @unitUpdated="pqUnitChanged()"
          @updateCategory="updateCategory"
          @acceptCustomUnit="acceptCustomUnit" >
        </auto-complete>
      </div>
    </div>

    <!-- 2.3 for true / false -->
    <div v-if="pq.pqType === PqTypes.BOOLEAN"
      class="grid grid-cols-pq17 gap-1 mx-2"
      @mouseleave="turnSpeechBubbleOff"
      @blur="turnSpeechBubbleOff" >
      <span
        class="col-start-1 col-span-1 text-center relative drag-handle input-pq00"
        title="Drag &amp; Drop to Adjust Position." >
        {{ rowId + 1 }}
      </span>

      <input
        class="col-start-2 col-span-6 input-pq0"
        type="text"
        placeholder=""
        v-model.lazy="pq.name" />

      <input
        class="col-start-8 col-span-2 input-pq1 text-center text-crimsontext-semibolditalic"
        type="text"
        placeholder=""
        v-model.lazy="pq.symbol"
        title="Enter Symbol Here." />

      <!-- '=' sign with PQOptions -->
      <span
        class="container col-start-10 col-span-1 text-center items-center relative cursor-pointer border-b border-blue-300"
        title="Click to show options."
        @click.stop="pq.showPQOptions = true" >
        <div class="font-semibold text-black">=</div>
        <div
          class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2"
          v-if="pq.showPQOptions" >
          <SpeechBubble>
            <button
              class="pr-2"
              @click="$emit('deletePQ_PE')"
              title="Delete this Quantity/Expression" >
              <img
                src="@/assets/icons/cancel_black_24dp.svg"
                alt="Delete the Quantity"
                height="20"
                width="20" />
            </button>
            <button
              class="pr-2 bg-red-500"
              @click="$emit('showPQ_PE')"
              title="Hide this Quantity/Expression" >
              Hide
            </button>
          </SpeechBubble>
        </div>
      </span>

      <input
        class="col-start-11 col-span-7 input-pq1 qtyInput text-crimsontext-semibolditalic"
        title="Enter Quantity / Expression."
        type="text"
        placeholder=""
        v-model.lazy="pq.expression"
        @change="expressionChanged" />
    </div>

    <!-- 2.4 for PQms - multi-value input PQs -->
    <div v-if="pq.pqType === PqTypes.MULTIVALUE_INPUT_NUMBER"
      class="grid grid-cols-pq17 gap-1 mx-2"
      @mouseleave="turnSpeechBubbleOff"
      @blur="turnSpeechBubbleOff" >
      <span
        class="col-start-1 col-span-1 text-center relative drag-handle input-pq00"
        title="Drag &amp; Drop to Adjust Position." >
        {{ rowId + 1 }}
      </span>

      <input
        class="col-start-2 col-span-6 input-pq0 text-crimsontext-bold"
        type="text"
        placeholder=""
        v-model.lazy="pq.name" />

      <input
        class="col-start-8 col-span-2 input-pq1 text-center text-crimsontext-semibolditalic"
        type="text"
        placeholder=""
        v-model.lazy="pq.symbol"
        title="Enter Symbol Here." />

      <span
        class="container col-start-10 col-span-1 text-center items-center relative cursor-pointer border-b border-blue-300"
        title="Click to show options."
        @click.stop="pq.showPQOptions = true" >
        <div class="font-semibold text-black">=</div>
        <div
          class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2"
          v-if="pq.showPQOptions"
          title="fractional digits" >
          <SpeechBubble>
            <button
              class="pr-2"
              @click="$emit('deletePQ_PE')"
              title="Delete this Quantity/Expression" >
              <img
                src="@/assets/icons/cancel_black_24dp.svg"
                alt="Delete the Quantity"
                height="20"
                width="20" />
            </button>
            <button
              class="pr-2 bg-red-500"
              @click="$emit('showPQ_PE')"
              title="Hide this Quantity/Expression" >
              Hide
            </button>
          </SpeechBubble>
        </div>
      </span>

      <!-- <input
          class="col-start-11 col-span-4 input-pq1 text-right qtyInput text-crimsontext-semibolditalic"
          title="Enter Quantity / Expression." type="text"
          placeholder=""
          v-model.lazy="pq.expression"
          @change="expressionChanged()" /> -->

      <!-- Multiple v-model bindings in vue 3 doc @ https://vuejs.org/guide/components/v-model.html#multiple-v-model-bindings -->

      <e3d-dropdown
        class="col-start-11 col-span-4 input-pq1 text-right qtyInput text-crimsontext-semibolditalic"
        v-model:expression="pq.expression"
        v-model:value="pq.value"
        @update:expression="expressionChanged"
        @update:value="pqmValueChanged" />

      <div class="col-start-15 col-span-3 flex flex-col items-center">
        <auto-complete
          v-model="pq.unit"
          :data="uomCategories"
          :groupKey="'name'"
          :groupOptionsKey="'units'"
          title="Click to Choose from Unit List."
          @unitUpdated="pqmUnitChanged()"
          @updateCategory="updateCategory"
          @acceptCustomUnit="acceptCustomUnit" >
        </auto-complete>
      </div>

    </div>

    <!-- 2.5 for PEs -->
    <div v-if="pq.pqType === PqTypes.EXPRESSION"
      class="grid grid-cols-pq17 gap-x-1 mx-2"
      @mouseleave="turnSpeechBubbleOff"
      @blur="turnSpeechBubbleOff" >
      <!-- 2.5.0 1st row -->
      <span
        class="col-start-1 col-span-1 text-center relative drag-handle input-pe00"
        title="Drag &amp; Drop to Adjust Position." >
        {{ rowId + 1 }}
      </span>

      <input
        class="col-start-2 col-span-6 input-pe0 nameInput"
        type="text"
        placeholder=""
        v-model.lazy="pq.name" />

      <input
        class="col-start-8 col-span-2 input-pe1 text-center text-crimsontext-semibolditalic"
        type="text"
        placeholder=""
        v-model.lazy="pq.symbol"
        title="Enter Symbol Here." />

      <!-- '=' sign with PQOptions -->
      <span
        class="container col-start-10 col-span-1 text-center items-center relative cursor-pointer"
        title="Click to show options."
        @click="pq.showPQOptions = true" >
        <div class="font-semibold text-black">=</div>
        <div v-if="pq.showPQOptions"
          class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2" >
          <SpeechBubble @mouseleave="turnSpeechBubbleOff">
            <button
              class="pr-2"
              @click="$emit('deletePQ_PE')"
              title="Delete this Quantity/Expression" >
              <img
                src="@/assets/icons/cancel_black_24dp.svg"
                alt="Delete the Quantity"
                height="20"
                width="20" />
            </button>
            <button
              class="pr-2 bg-red-500"
              @click="$emit('showPQ_PE')"
              title="Hide this Quantity/Expression" >
              Hide
            </button>
            <input
              class="w-10 border-b rounded-md text-center mx-2 px-1"
              min="0"
              title="fractional digits"
              v-if="isNaN(pq.expression)"
              type="number"
              v-model="pq.fractionalDigits"
              @change="$emit('rerunCalc')" />
          </SpeechBubble>
        </div>
      </span>

      <input v-if="showExpression"
        class="col-start-11 col-span-7 input-pe1 text-crimsontext-semibolditalic"
        title="Enter Quantity / Expression."
        type="text"
        placeholder=""
        v-model="pq.expression"
        @change="expressionChanged" />

      <!-- 2.5.1 2nd row -->
      <div v-if="showExpression" class="col-start-1 col-span-1 input-pq00"></div>
      <span v-if="showExpression" class="col-start-2 col-span-8 input-pq0">
        <span class="warning" v-if="pq.message && pq.showAlert">
          Warning: {{ pq.message }}
        </span>
        <span class="warning-blank" v-else> </span>
      </span>

      <span v-if="showExpression"
        class="col-start-10 col-span-1 text-red-600 text-center border-b border-blue-300" >
        =
      </span>

      <span class="col-start-11 col-span-4 output-pe0" title="Calculated result.">
        {{ faceValue }}
        <!-- {{ pq.value === '' ? '' : Number(pq.value).toFixed(pq.fractionalDigits) }} -->
        <!-- {{ pq.baseValue === '' ? '' : Number(pq.faceValue) }} -->
      </span>

      <!--
        <input class="col-start-15 col-span-3 input-pq0 text-center text-crimsontext-semibolditalic"
          title="Enter Unit Here." type="text" placeholder=""
          v-model.lazy="pq.unit"
          @change="peUnitChanged()" />
      -->
      <div class="col-start-15 col-span-3 flex flex-col items-center">
        <auto-complete
          v-model="pq.unit"
          :data="uomCategories"
          :groupKey="'name'"
          :groupOptionsKey="'units'"
          title="Click to Choose from Unit List."
          @unitUpdated="
            peUnitChanged();
            $emit('rerunCalc'); " >
        </auto-complete>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { reactive, ref, computed, watch, onMounted } from "vue";
import { PhysicalQuantity, PqTypes, UomCategories } from "../API/pq.js";

import SpeechBubble from "@/components/SpeechBubble.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import E3dDropdown from "@/components/Dropdown.vue";

export default {
  name: "E3dPhysicalQuantity",
  components: {
    SpeechBubble,
    AutoComplete, // It is surprising that this is required here though it is indirectly for dropdown.
    E3dDropdown,
  },
  emits: [
    "addPQ_PE",
    "deletePQ_PE",
    "showPQ_PE",
    "rerunCalc",
    "autoUnitConversion",
    "UNIT_CHANGED",
  ],
  props: {
    physicalQuantity: PhysicalQuantity,
    rowId: Number,
    mode: String,
    showExpression: Boolean,
  },

  setup(props, ctx) {
    debugger
    const pq = reactive(props.physicalQuantity);
    const uomCategories = UomCategories;
    const selected = ref(null);
    /**true when expression is '', or number, or calculable expression like '2+sin(30)+PI()'.
     * If true, then no fractional digits adjustment is needed.
     * @type {Boolean}
     */
    const isInput = ref(true);
    const oldExpression = ref("")
    // const oldBaseExpression = ref("")
    const oldUnit = ref("")
    const oldBaseUnit = ref("");
    const oldBaseValue = ref("");
    const unitConversionAuto = computed(() => pq.unitConversionAuto);

    const titleTmp = ref('title');
    const messageTmp = ref('message');

    /* let test = pq.test;
    console.table(`pq.test: ${test}`) */

    onMounted(() => {
      console.log(`@PQ.onMounted, pq.unit: ${pq.unit}`);
      oldUnit.value = pq.unit;              // keep a copy
      pq.baseUnit = pq.unit; // set baseUnit to unit using setter
      oldBaseUnit.value = pq.baseUnit; // using getter
      pq.baseValue = pq.value; // set baseValue to value using setter
      oldBaseValue.value = pq.baseValue; // using getter

      debugger
      pq.baseExpression = pq.expression; // check point
      oldExpression.value = pq.baseExpression;  // keep a copy

      /* if (pq.unit !== 'Unit') { // when it is not header of calc table
        if (!isNaN(pq.expression)) { // when it is a number or ''
          pqUnitChanged()
        } else {
          peUnitChanged()
        }
      } */
    });

    const expressionChanged = e => {
      if (!(e instanceof Event)) {
        console.log(`event@expressionChanged: e=${e}`);
      }
      debugger

      isInput.value = true; // ???
      pq.normExpression = pq.expression;
      const expr = pq.normExpression;
      console.log(`pqType@expressionChanged: ${pq.pqType.toString()}`)

      switch (pq.pqType) {
        /** TODO: to improve and find a better way for no input value.
         * This type is not calculatable. Should have a way to indicate on UI.
         */
        case PqTypes.BLANK_INPUT:
          pq.faceValue = "";
          pq.value = pq.faceValue;
          pq.baseValue = pq.value;
          break;

        case PqTypes.NUMBER:
          pq.faceValue = Number(expr);
          pq.value = pq.faceValue;
          // The following is confusing. It might be better to calculate/convert here. Then explicitly set the baseValue.
          // pq.baseValue = (pq.unit === '') ? pq.value : pq.fv2bv(pq.value) // this makes conversion twice.
          pq.baseValue = pq.value; // this calls setter, which calls fv2bv() once
          break;

        case PqTypes.BOOLEAN: // TODO: to improve.
          break;

        case PqTypes.MULTIVALUE_INPUT_NUMBER:
          debugger
          // console.log(`pq.value@multiValue-Input: ${e.target.value}`);
          pq.baseExpression = expr; // use setter to update baseExpression
          const options = expr.split('|').filter(Boolean);
          pq.value = options.includes(`${pq.value}`) ? pq.value : Number(options[0]);
          pq.faceValue = pq.value;
          pq.baseValue = pq.value;
          break;

        default:
          // PqTypes.EXPRESSION
          if (!pq.symbolsOfInputPQs.length) {
            // for PQ.expr that is independent of other PQ/PE, like '1+2'?
            console.log(`anything to do here?`);
          }
          console.log(`anything to do here?`);
          break;
      }

      ctx.emit("rerunCalc");
    }

    const updateCategory = (e) => {
      // TODO: to improve later.
      console.log(`updateCategory: ${e}`); // eg: 'e' is 'length' for 'in' here.
    };

    const pqUnitChanged = () => {
      debugger;
      ctx.emit("UNIT_CHANGED");
      isInput.value = false;
      oldBaseUnit.value = pq.baseUnit ? pq.baseUnit : "";
      // TODO: check if unit is valid
      pq.baseUnit = pq.unit; // update to new input unit using setter
      const newBaseUnit = pq.baseUnit; // using getter

      if (oldBaseUnit.value === newBaseUnit) {
        /*
        if it is in the same unit category (have the same base unit), then no change to baseValue
        however, if I do consider pressure and stress as 2 different categories, they both have the same base unit. Then this need improvement.
       */
        const fv = pq.bv2fv(pq.baseValue);
        // pq.expression = fv
        pq.expression = fv
          ? fv.toString().length > 12 // this is problematic for 111000111000.111
            ? fv.toFixed(pq.fractionalDigits)
            : fv
          : "";
      } else {
        // otherwise, recalculate baseValue for a new category
        /* const res = confirm('Confirm to change unit category?')

        if (res) { // back to previous valid unit and category
          // TODO: need more work here!!! How to keep previous unit state? => Maybe use faceUnit?
          const fv = Number(pq.expression)
          pq.baseValue = fv
        } */
        console.log("Unit category changed");

        pq.baseValue = Number(pq.expression);
      }
    };

    const peUnitChanged = () => {
      debugger;
      oldBaseUnit.value = pq.baseUnit ? pq.baseUnit : "";
      pq.baseUnit = pq.unit; // update to new input unit using setter
      const newBaseUnit = pq.baseUnit; // using getter

      if (oldBaseUnit.value === newBaseUnit) {
        // if it is in the same unit category (have the same base unit)
        if (pq.symbolsOfInputPQs.length) {
          // the expression is dependent on other PQ/PE, then no change to baseValue
          pq.faceValue = pq.bv2fv(pq.baseValue);
          pq.value = pq.faceValue;
        } else {
          // if the expression is independent on any PQ / PE, take the expr and trigger rerun. The baseValue will be changed.
          // ctx.emit('rerunCalc')
          console.log("do nothing here. baseValue will be recalculated by rerun()");
        }
      } else {
        // otherwise, recalculate baseValue for a new category
        confirm("when provided, unit needs to be in the same category as before");
      }

      ctx.emit("UNIT_CHANGED");
    };

    const acceptCustomUnit = () => {
      // switch to unit[manual] mode if it is not already
      if (unitConversionAuto.value) ctx.emit("autoUnitConversion");
    };

    const pqmValueChanged = (v) => {
      debugger;
      console.log(`selected option changed@pqmValueChanged: ${v}`);
      pq.faceValue = Number(v);
      pq.value = pq.faceValue;

      pq.baseValue = pq.value; // this calls setter, which calls fv2bv() once
      console.log(`pq.value changed@pqmValueChanged: ${pq.value}`);
      ctx.emit("rerunCalc");
    };

    const pqmUnitChanged = () => {
      debugger;
      ctx.emit("UNIT_CHANGED"); // TODO:
      isInput.value = false;
      oldBaseUnit.value = pq.baseUnit ? pq.baseUnit : "";
      // TODO: check if unit is valid
      pq.baseUnit = pq.unit; // update to new input unit using setter
      const newBaseUnit = pq.baseUnit; // using getter

      if (oldBaseUnit.value === newBaseUnit) {
        /*
        if it is in the same unit category (have the same base unit), then no change to baseValue
        however, if I do consider pressure and stress as 2 different categories, they both have the same base unit. Then this need improvement.
        */
        const expr2fv = convertExpression2fv(pq.baseExpression) // based on new unit
        pq.expression = expr2fv;
        pq.value = pq.bv2fv(pq.baseValue);
        ctx.emit("rerunCalc");
      } else {
        // otherwise, recalculate baseValue for a new category
        /* const res = confirm('Confirm to change unit category?')

        if (res) { // back to previous valid unit and category
          // TODO: need more work here!!! How to keep previous unit state? => Maybe use faceUnit?
          const fv = Number(pq.expression)
          pq.baseValue = fv
        } */
        console.log("Unit category changed");

        pq.baseValue = Number(pq.value);
      }
    };

    /**
     * Assuming the for the same unit category -
     * @param {*} pqmOld
     * @param {*} expr
     */
    const convertExpression2fv = expr => { // expr = "1000|2000|3000"
      return expr.split('|').filter(Boolean).map(i => {
        return pq.bv2fv(+i)
      }).join('|')
    }

    const togglePQOptions = () => {
      pq.showPQOptions = !pq.showPQOptions;
    };

    const turnSpeechBubbleOn = () =>
      setTimeout(() => {
        pq.showPQOptions = true;
      }, 500);

    const turnSpeechBubbleOff = () =>
      setTimeout(() => {
        pq.showPQOptions = false;
      }, 300);

    const faceValue = computed(() => {
      /*
      pq.faceValue
        ? pq.faceValue.toString().length > 12
          ? pq.faceValue.toFixed(pq.fractionalDigits)
          : pq.faceValue
        : pq.faceValue === 0 ? 0 : ""
      */
      if (pq.faceValue === 0) return 0 // previously this returns ''. It was a bug fixed 20231002
      if (!pq.faceValue) return ''
      return pq.faceValue.toString().length > 12
           ? pq.faceValue.toFixed(pq.fractionalDigits)
           : pq.faceValue
    });

    /**
     * The follow does not seem to be used in the app so far. 20231010
     */
    const expression = computed(() =>
      isNaN(pq.expression) /* TODO: to improve */
        ? pq.expression.toString().length > 12
          ? pq.expression.toFixed(pq.fractionalDigits)
          : pq.expression
        : ""
    );

    return {
      unitConversionAuto,
      uomCategories,
      PqTypes,
      selected,
      oldBaseUnit,
      oldBaseValue,
      pq,
      isInput,
      expressionChanged,
      pqUnitChanged,
      peUnitChanged,
      acceptCustomUnit,
      pqmValueChanged, pqmUnitChanged,
      togglePQOptions,
      turnSpeechBubbleOff,
      turnSpeechBubbleOn,
      updateCategory,
      faceValue,
      expression,
      titleTmp, messageTmp,
    };
  },
};
</script>

<style src="../styles/components/index.css" scoped></style>
<style scoped lang="scss">
.text-charm-bold {
  font-family: "Charm-Bold";
}
.text-charm-regular {
  font-family: "Charm-Regular";
}

.text-crimsontext-semibolditalic {
  font-family: "CrimsonText-SemiBoldItalic";
}

.header-text {
  @apply bg-blue-500 text-center items-center justify-center text-white py-2 px-0 md:px-2 rounded;
}

/*  For input in speechBubble to show the up/down arrows
  https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
  https://codepen.io/BJack/pen/FHgtc */
input[type="number"] {
  &::-webkit-inner-spin-button {
    opacity: 1;
  } // magic!
}

.drag-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

.btn-add-pq-pe {
  cursor: pointer;
  height: 40px;
  background-color: #8adcde;
  @apply rounded-full hover:bg-blue-300;
}

.btn-unit-auto {
  cursor: pointer;
  height: 40px;
  color: black;
  background-color: #8adcde;
  @apply rounded-full font-semibold hover:bg-blue-300;
}

.btn-unit-manual {
  cursor: pointer;
  height: 40px;
  color: red;
  @apply rounded-full font-semibold bg-yellow-200 hover:bg-blue-300;
}

.container0 {
  /* https://www.freecodecamp.org/news/how-to-center-anything-with-css-align-a-div-text-and-more/ */
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-single {
  grid-area: "input";
  font-size: 2em;
  width: 90%;
  height: 120px;
}

.input-single:hover {
  grid-area: "input";
  font-size: 2em;
  width: 90%;
  /* border: 2px dashed cyan; */
  box-shadow: 5px 5px aquamarine;
  cursor: text;
}

::placeholder {
  color: #8cf2e4;
  opacity: 1; /* Firefox */
  font-size: 0.8em;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8cf2e4;
  font-size: 0.8em;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8cf2e4;
  font-size: 0.8em;
}

@font-face {
  /* https://fonts.google.com/specimen/Charm?preview.text=I%20love%20you.&preview.text_type=custom */
  font-family: "Charm-Bold";
  src: local("Charm-Bold"), url("../assets/fonts/Charm/Charm-Bold.ttf") format("truetype");
}

@font-face {
  /* https://fonts.google.com/specimen/Charm?preview.text=I%20love%20you.&preview.text_type=custom */
  font-family: "Charm-Regular";
  src: local("Charm-Regular"),
    url("../assets/fonts/Charm/Charm-Regular.ttf") format("truetype");
}

@font-face {
  /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: "CrimsonText-SemiBoldItalic";
  src: local("CrimsonText-SemiBoldItalic"),
    url("../assets/fonts/CrimsonText/CrimsonText-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: "CrimsonText-Bold";
  src: local("CrimsonText-Bold"),
    url("../assets/fonts/CrimsonText/CrimsonText-Bold.ttf") format("truetype");
}

@font-face {
  /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: "CrimsonText-SemiBold";
  src: local("CrimsonText-SemiBold"),
    url("../assets/fonts/CrimsonText/CrimsonText-SemiBold.ttf") format("truetype");
}

@font-face {
  /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: "CrimsonText-Regular";
  src: local("CrimsonText-Regular"),
    url("../assets/fonts/CrimsonText/CrimsonText-Regular.ttf") format("truetype");
}
</style>
