import { ref } from 'vue'
const useChart = (chartInput, calculation) => {
  const inputOK = ref(false)

  const getDataSets = () => {
    if (calculation) {
      if (calculation.chartInputDataOK(chartInput)) {
        inputOK.value = true

        const dataSet = {
          label: 'Paris',
          data: [],
          showLine: true,
          fill: false,
          backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
          borderColor: ['#CE23FF', '#aa79AF', '#123E6B', '#97B0C4', '#A5C8ED']
        }

        const datasets = []

        calculation.chartDataSets(chartInput).forEach(ds => {
          datasets.push(Object.assign(
            {}, dataSet, { data: ds.data, label: ds.label, yAxisID: ds.yAxisID }
          ))
        })

        // chart.chartData = Object.assign({}, calculation.chartDataSets(chartInput))
        return { datasets }
      }
    } else {
      return []
    }
  }

  const getOptions = () => {
    if (calculation) {
      if (calculation.chartInputDataOK(chartInput)) {
        return Object.assign({}, calculation.chartOptions(chartInput))
      }
    } else {
      return {}
    }
  }

  return { inputOK, getDataSets, getOptions }
}

export default useChart
