<template>
  <div>
    <input
      class="input-pq1 text-right qtyInput text-crimsontext-semibolditalic"
      type="text" placeholder="expression, eg: 1|2|3" title="expression"
      :value="inputExpression" v-if="mode === Modes.EDITING"
      @change="updateExpression"
      @blur="mode = Modes.SELECTING"/>
    <div v-if="mode === Modes.SELECTING"
      class="input-pq1 text-right qtyInput text-crimsontext-semibolditalic">
      <select :value="inputValue" title="value"
        @change="$emit('update:value', $event.target.value)">
        <option class="bg-gray-400 text-center text-white max-h-48 overflow-auto"
          v-for="(option, index) in options" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
      <button
        class="btn-unitlist mx-1 rounded-full hover:bg-blue-400 focus:outline-none w-6 h-6 items-center justify-center"
        @click="toggleEditing">
        <label title="edit...">
          <img style="display: inline;"
            src="@/assets/icons/edit_FILL0_wght400_GRAD0_opsz24.svg"
            alt="Show Help"
            height="20" width="20" />
        </label>
      </button>
    </div>
  </div>
 </template>

 <script>
 import { ref, computed } from 'vue';

 const Modes = { // can be replaced with a switch like isEditing.
  EDITING: "editing",
  SELECTING: "selecting"
};

 export default {
  name: 'DropdownList',
  emits: ['update:value', 'update:expression'],
  props: {
    expression: { // refer to BaseInput.vue
      type: [String, Number],
      default: ''
    },
    value: { // refer to BaseInput.vue
      type: [String, Number],
      default: ''
    },
    data: { type: Array } // this is needed for reading multi-value PQ from existing calc.
  },
  setup(props, cxt) {
    debugger
    const inputExpression = ref(props.expression)
    const inputValue = ref(props.value ? props.value : '')
    const inputString = ref('');

    /**  .filter(Boolean) below filters the array of substrings to only include elements that evaluate to true when
          passed to the Boolean function. This effectively removes any empty strings or false values from the array.
    */
    const options = computed(() => inputExpression.value.split('|').filter(Boolean));
    const option = options.value.includes(`${inputValue.value}`) ? inputValue.value : Number(options.value[0]);

    inputValue.value = option
    const mode = ref(Modes.SELECTING)

    const updateExpression = (e) => {
      debugger
      console.log(`@updateExpression: ${e.target.value}`);
      if (!(options.value.includes(`${inputValue.value}`))) inputValue.value = Number(options.value[0]);

      cxt.emit('update:expression', e.target.value);
      cxt.emit('update:value', inputValue.value);
      mode.value = Modes.SELECTING
    };

    const updateValue = (e) => {
      debugger
      cxt.emit('update:value', e.target.value)
    };

    const toggleEditing = () => { mode.value = Modes.EDITING; };

    return {
      inputString, inputExpression, inputValue,
      options, Modes, mode,
      updateExpression, updateValue,
      toggleEditing,
    };
  },
 };
 </script>
