<template>
  <div>
    <nav>
      <div class="flex flex-row justify-end items-center">
        <div class="justify-end" v-if="user && user.emailVerified">
          <span class="mx-3">Welcome, {{ user.displayName }}!</span>
          <button class="bg-blue-300 hover:bg-blue-500 text-white font-bold p-1 rounded w-18"
            @click="handleClick">Logout
          </button>
          <button class="bg-blue-300 hover:bg-blue-500 text-white font-bold py-1 px-2 m-1 rounded w-20" title="Go to Docs">
            <a href="https://v2-docs.donwen.com/" class="toolbarItem" target="_blank">Docs</a>
          </button>
        </div>

        <div v-else>
          <button class="bg-blue-300 hover:bg-blue-500 text-white font-bold py-1 px-2 m-1 rounded w-20" title="">
            <router-link :to="{ name: 'Signup' }">Sign Up</router-link>
          </button>
          <button class="bg-blue-300 hover:bg-blue-500 text-white font-bold py-1 px-2 m-1 rounded w-20" title="">
            <router-link :to="{ name: 'Login' }">Login</router-link>
          </button>
          <button class="bg-blue-300 hover:bg-blue-500 text-white font-bold py-1 px-2 m-1 rounded w-20" title="Go to Docs">
            <a href="https://v2-docs.donwen.com/" class="toolbarItem" target="_blank">Docs</a>
          </button>
        </div>

      </div>

    </nav>
  </div>
</template>

<script>
/* eslint-disable */
import getUser from '../composables/getUser'
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'

// challenge
//   - only show the logout button if we are logged in
//   - only show the signup and login links if we are not logged in
//   - use the getUser composable to help

export default {
  name: 'E3dNavbar',
  setup () {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()

    const handleClick = async () => {
      await logout()
      console.log('logged out')
      router.push({ name: 'Login' })
    }

    return { handleClick, user }
  }
}
</script>

<style scoped>
  .navbar {
    padding: 16px 10px;
    margin-bottom: 10px;
    background: white;
  }
  nav {
    display: flex;
    align-items: center;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  nav img {
    max-height: 10px;
  }
  nav h1 {
    margin-left: 20px;
  }
  nav .links {
    margin-left: auto;
  }
  nav .links a, button {
    margin-left: 2px;
    font-size: 14px;
  }
  span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #eee;
  }
</style>
