/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */

"use strict"

import { ref, reactive, computed } from 'vue'
import * as utilsPub from '@/API/utils-public'

import { PhysicalQuantity, PqTypes, mathMethodListTrig } from '@/API/pq.js'

const PQs = reactive(new Array())
const PQMs = reactive(new Array())
window.utilsPub = utilsPub // don't fully understand this, but it works.

const useCalculation = (settings) => {
  const message = ref('')
  const canCalculate = ref(false)
  // const autoSymbol = ref(settings.autoSymbol)
  const alertIndicatorON = ref(false)
  const unitConversionAuto = ref(settings.unitConversionAuto)

  const xAxis = ref('')
  const yAxes = reactive([])

  init()

  function init () {
    const pq = new PhysicalQuantity({ unitConversionAuto: settings.unitConversionAuto })
    PQs.push(pq)
  }

  /**
   * Check if there is one PQ in the calc, set as SINGLE so PQ component will be shown as
   * regular calculator.
   * PQ0 is taken by the header row.
   */
  const calcMode = computed(() => {
    return PQs.length === 1 ? 'SINGLE' : 'MULTIPLE'
  })

  function clearCalc() { PQs.splice(0, PQs.length) }

  /**
   * This technically makes P_Qs the public member for private member PQs.
   */
  const P_Qs = computed({
    get: () => PQs,
    set: val => {
      PQs.splice(0, PQs.length)
      PQs.push(...val)
    }
  })

  function addPqPe () { // includes PQM
    const _autoSymbol = PQs.length ? `x${PQs.length}` : 'x0'
    debugger
    const symbol = settings.autoSymbol ? _autoSymbol : ''
    // const symbol = autoSymbol.value ? _autoSymbol : ''
    const pq = new PhysicalQuantity({ symbol: symbol, unitConversionAuto: unitConversionAuto.value })
    PQs.push(pq)
    // refreshPQs()

    // console.log('add equation/expression')
  }

  function deleteCurrentPQ (pq, i) {
    console.log(`to delete pq, i: @${i} = ${JSON.stringify(pq)}`)
    console.log(`PQs length before delete: ${PQs.length}`)
    PQs.splice(i, 1)

    // refreshPQs()

    console.log(`PQs length after delete: ${PQs.length}`)
    rerun()
  }

  function hideCurrentPQ (pq, i) {
    console.log(`to show pq, pq = ${JSON.stringify(pq)}`)
    settings.showHiddenRows = false
    pq.showPQRow = !pq.showPQRow
    // PQs[i].showPQRow = !PQs[i].showPQRow
    rerun()
  }

  /**
   * get all symbols of this calculation.
   */
  const pqSymbols = computed(() => PQs.map(pq => {
    console.log(`pq.symbol: ${pq.symbol}`)
    return pq.symbol
  }))

  // for now, keep rerun so other parts of the app can use this as before. Later, rerun() should be obsolete.
  function rerun () {
    refreshPQs() // this makes sure all PQs are updated with latest baseValues, including autoUnitConversion

    /**The following is a milestone bug fix to make sure all PEs are recalculated with latest
     * baseValues from PQs.
     */
    PQs.forEach(pq => {
      if(pq.pqType === PqTypes.NUMBER) { pq.baseValue = pq.value } // use setter to update baseValue
      if(pq.pqType === PqTypes.MULTIVALUE_INPUT_NUMBER ) {
        pq.baseValue = pq.value // use setter to update baseValue
      }
    })

    /***
     * This was a brute force way to refresh the PEs to make sure all get recalculated with latest data.
     * As I changed the algorithm to createInternalExpressions, this is no longer needed.
     *   const length = PEs.value.length
     *   alertIndicatorON.value = false // initialize as false, then turn ON whenever sth goes wrong.
     *
     */
    for (let i = 0; i < PEs.value.length; i++) { run() }
  }

  function run () { // run validating and calculation for all PEs
    PEs.value.forEach(pe => {
      /** beside validating, I should also check if the expression and composition has changed.
       *  If not, then no need to recreate/update internal expression. This will save time. TODO:
      */
      validate(pe)

      if (pe.isExpressionValid) { createInternalExpression(pe) }
    })

    PEs.value.forEach(pe => {
      if (pe.isExpressionValid) {
        // console.log(`pe@run(): ${JSON.stringify(pe)}`)
        calculate(pe)
      }
    })
  }

  function refreshPQs () {
    const _PQs = []
    let PQ

    PQs.forEach(({ name, symbol, expression, value, unit, fractionalDigits, showPQRow }) => {
      PQ = new PhysicalQuantity({
        name: name,
        symbol: symbol,
        expression: expression,
        value: value,
        unit: unit,
        fractionalDigits: fractionalDigits,
        showPQRow: showPQRow,
        unitConversionAuto: settings.unitConversionAuto
      })

      // PQ.value = !isNaN(PQ.expression) ? Number(PQ.expression) : ''
      console.log(`@refreshPQs: PQ=${JSON.stringify(PQ)}`)
      _PQs.push(PQ)
    })

    PQs.splice(0, PQs.length)
    PQs.push(..._PQs)
  }

  /**
     * get all PEs for this calculation, get rid of duplicates (TODO:)
     * Only real PEs should go through the validation process. I need to clarify what should be PEs.
     */
  const PEs = computed(() => PQs.filter( pq => pq.pqType === PqTypes.EXPRESSION ))

  /**
   * validate a Physical Expression pe that is calculatable.
   * Inputs to be ignored:
   * - comments: //
   * - empty input (), (''), ("")
   * - numbers
   * - boolean: true, false
   * - multi-value inputs, to be validated separately (TODO:)
   * - multi-value outputs (future TODO:)
   *
   * param: pe
   */
  function validate (pe) {
    // get pe's parent PEs
    // get pe's children PEs and PQs
    pe.message = ''

    // pe.normExpression = normalizeExpression(pe.expression);
    pe.normExpression = pe.expression

    // let tmpParentPEs = getParentPEs(pe);

    console.log(`pe.symbol@validate(): ${pe.symbol}`)
    console.log(`expr = ${pe.expression}, value = ${pe.value}`)

    // console.log(`parentPEs: ${ tmpParentPEs }`);

    // Set init status. Any pe's invalid (no value or has error) child will bring it to false
    let canCalculate = true
    // find if there is letter that has number before it. However the following doesnot allow A1r to be used.
    // const regex = /(?<=\d)[A-Za-z]/g;
    const regex = /\b\d+(?=[a-zA-Z])/g // boundary with digit(s), followed by letter

    // when user input is a number, do nothing. It will not come to this point.

    if (pe.normExpression.search(regex) !== -1) { // found a number before a symbol
      pe.message = "A number can't appear before a symbol."
      alertIndicatorON.value = true
      canCalculate = false
    } else { // not found, continue check symbolic children PQs/PEs
      /**uExprInputPQs: u stands for user, refers to symbols/variable names ceated by user,
       * like V, m, rou, etc. This is to differentiate from those in InternalExpressions.
       **/
      let uExprInputPQsTmp = pe.symbolsOfInputPQs // 1-[mu, theta, theta] as proxy

      // get input PQs' symbol array, and not proxy any more.
      let uExprInputPQs = uExprInputPQsTmp.map(pq => pq[0]) // 1-[mu, theta]

      /* uExprInputPQs = uExprInputPQs.filter( // remove duplicates from uExprInputPQs.
        (item, index) => uExprInputPQs.indexOf(item) === index
      ) */

      uExprInputPQs = [...new Set(uExprInputPQs)] // remove duplicates per Bito

      // loop thru each inputPQ of pe using their symbols
      uExprInputPQs.forEach(sym => { // Note: sym is not proxy.
        const indexInPQs = PQs.findIndex(pq => pq.symbol === sym) // find the 1st instance

        if (indexInPQs === -1) { // if current sym is not found in PQs symbol list
          pe.message += `\n${sym} not defined! Check spelling or create a new variable for it.`
          canCalculate = false
          // pe.value = null;
          alertIndicatorON.value = true
        } else { // if PQ for sym (ie: PQs[indexInPQs]) is an input type, and not have value yet:
          // current PQ existing as pe_ in PQs[]
          const pe_ = PQs[indexInPQs] // get the corresponding PE/PQ in PQs[]

          switch (pe_.pqType) {
            case PqTypes.COMMENT: // comment
              pe.message += `\n ${pe_.symbol} is commented out.`
              canCalculate = false
              alertIndicatorON.value = true
              break
            case PqTypes.BLANK_INPUT:
              pe.message += `\n ${pe_.symbol} is empty.`
              canCalculate = false
              alertIndicatorON.value = true
              break
            case PqTypes.BOOLEAN:
              pe.message += `\n ${pe_.symbol} is a boolean value, can't be used in expression for calculation.`
              canCalculate = false
              alertIndicatorON.value = true
              break
            /* case PqTypes.MULTIVALUE_INPUT_NUMBER:  // not needed here, like the numbers
              pe.message += `\n ${pe_.symbol} is a multi-value list.`
              canCalculate = false
              alertIndicatorON.value = true
              break */

            case PqTypes.EXPRESSION:
              validate(pe_) // Check pe_ is valid recursively.
              if (!pe_.isExpressionValid) {
                pe.message += `\ncheck ${pe_.symbol}!`
                canCalculate = false
                alertIndicatorON.value = true
              } else {
                calculate(pe_) // calculate(pe_) if pe_ is valid.
                // TODO: if isNaN(pe_.value), show error / warning
                // canCalculate = isNaN(pe_.value) ? false : true

                /**
                 * Critical bug fix 20200823
                 * This makes sure that all child PE/PQ in uExprInputPQs are checked for canCalculate
                 *
                 */
                canCalculate = isNaN(pe_.value) ? false : canCalculate
              }
              break
            default: // PqTypes.NUMBER, canCalculate does not change.
              break
          }

          // if (canCalculate) { validate(pe_) } // Check pe_ is valid recursively.

        }
      })
    }

    pe.isExpressionValid = canCalculate
    if (!pe.isExpressionValid) {
      pe.value = ''
      // then validate parents PEs (parentPEs) TODO:
    } // else { calculate(pe) }
  }

  const testSum = (a, b) => a + b

  /**
   * run the calc per eq
   */
  function calculate (pe) { // pe is PE only if its expression is not empty
    console.log(`pqSymbols@calculate: ${JSON.stringify(pqSymbols.value)}`)

    // const iexpr = createInternalExpression(pe)
    // pe.internalExpression = iexpr

    console.log(`uExpr@calculate: ${pe.expression}`)
    console.log(`iExpr@calculate: ${pe.internalExpression}`)

    let tmp = utilsPub.toRad(90)

    // let tmp3 = testSum(2, 3)
    // console.log(`tmp3: ${tmp3}`)
    // let tmp2 = eval('testSum(2, 3)')
    // let tmp0 = eval('1 + rad(180)')
    // let tmp1 = evaluate('rad(180)') // Does not work???
    // console.log(`tmp = rad@calculate: ${tmp}`)
    // console.log(`tmp0 = eval(rad)@calculate: ${tmp0}`)
    // console.log(`rad@calculate by eval: ${tmp1}`)
    // console.log(`tmp2@calculate by eval: ${tmp2}`)

    try {
      const result = Number(eval(pe.internalExpression))
      // TODO: if isNaN(result), show error message
      if (settings.unitConversionAuto) {
        if (pe.symbolsOfInputPQs.length) { // if pe contains some PQ
          pe.baseValue = isNaN(result) ? '' : result
          pe.faceValue = pe.bv2fv(pe.baseValue)
          pe.value = pe.faceValue
        } else {
          // if pe does not contain any input variable, that means it can be calc'd directly by JS.eval
          // TODO: this can be improved in createInternalExpression as no inputPQs
          pe.faceValue = isNaN(result) ? '' : result

          pe.baseValue = pe.faceValue // calling setter
          pe.value = pe.faceValue
        }
      } else { // unitConversionAuto = false
        pe.faceValue = isNaN(result) ? '' : result
        pe.value = pe.faceValue
      }
    }
    catch(err) { console.log(`err@calculate: ${err.message}`) }
  }

  /**
   * From here, I started to replace child PEs in PE's internalExpression.
   * @param {*} pe
   */
  function createInternalExpression (pe /** uExpr - user input expression */) {
    console.log('======createInternalExpression======')
    // prefix u for user, ie: user input string, i for internal, ie: converted to internal string
    // let uExpr = pe.expression                        // '9.81 * (mu * cos(theta) + sin(theta))'
    // let uExprSymbols = pe.symbols;                   // [mu, cos, theta, sin, theta]
    const uExprInputPQs = pe.symbolsOfInputPQs // 1 -[mu, theta, theta]: child PQs and PEs of current pe.
    const uExprNumbers  = pe.numbersInExpression // 2 -[9,81]
    let uExprSplitters  = pe.splitterCharsInExpression // 3 -[., *, (, *, (, ), +, (, ), )]
    let uExprMathMethods= pe.mathMethodsInExpression // 4a-[sqrt, abs, cos, sin]
    let uExprMathProps  = pe.mathPropsInExpression // 4b-[PI, E, LN2]

    // let uExprE3dFuncs   = pe.e3dFuncsInExpression // 5 -[rad, deg, ]
    let uExprUtilsPubFuncs = pe.utilsPubFuncsInExpression // 5 -[rad, deg, ]

    let uExprBooleans   = pe.booleansInExpression // 6 - to allow booleans to be used in expr.
    // let uExprMathTrigFuncs = pe.mathTrigFunctionsInExpression;  // 5-[cos, sin]

    let text

    /** #1: To replace symbols in user expression with PQ's ID suffixed with '.value'
     *  Eg: PQi's new ID is PQs is PQi, its symbol changes from F to F1, then in new
     *  internal expression, it will be 'PQi.value'
     */

    let iExprInputPQs = uExprInputPQs.map(sym => { // inputPQ can be PQ or PE here.
      let indexInPQs = PQs.findIndex((PQ) => PQ.symbol === sym[0] )
      let indexInUExpr = sym.index
      text = settings.unitConversionAuto ? `Number(PQs[${indexInPQs}].baseValue)` // when sym is a PQ, ie: quantity
        : `Number(PQs[${indexInPQs}].value)`

      if(PEs.value.findIndex(pq => pq.symbol === sym[0]) !== -1) { // if sym is PE
        if (!PQs[indexInPQs].symbolsOfInputPQs.length) { // 不含变量的算式或表达式
          // TODO: handle simple expression of numbers only, not involve functions yet. to improve in future!!!
          text = `${PQs[indexInPQs].baseValue}`
        } else { // for dependent variable 因变量
          createInternalExpression(PQs[indexInPQs]) // recursively call createInternalExpression()
          text = `${PQs[indexInPQs].internalExpression}`
        }
      }

      console.log(`pe: ${pe.symbol}, ${sym[0]} in UExpr ==== ${text}`)

      return {index: indexInUExpr, text}
    })

    /** #2: numbers */
    let iExprNumbers = uExprNumbers.map(num => { return {index: num.index, text: num[0]} })

    /** #3: splitters */
    let iExprSplitters = uExprSplitters.map(s => { return {index: s.index, text: s[0]} })

    /** #4a: To replace math methods by prefixing with 'Math.???'  */
    let iExprMathMethods

    /*
      - mode SINGLE && angleInDegrees => sin0();
      - mode Multi && UnitManual && angleInDegrees => sin0();
      - else: sin()
     */
    let angleInDegrees =  (
                            (calcMode.value === 'SINGLE') ||
                            (calcMode.value === 'MULTIPLE' && !settings.unitConversionAuto )
                          ) && settings.angleInDegrees
    if (angleInDegrees) {
      iExprMathMethods = uExprMathMethods.map(func => {
        text = `${func}`
        if (mathMethodListTrig.includes(text)) { text = `window.utilsPub.${text}0` } // sin0();
        else { text = `Math.${func}` }
        let index = func.index
        return { index, text }
      })
    } else {
      iExprMathMethods = uExprMathMethods.map(func => {
        text = `Math.${func}` // sin(), just like other math methods;
        let index = func.index
        return { index, text }
      })
    }

    /** #4b: To replace math props by prefixing with 'Math.???()' */
    let iExprMathProps = uExprMathProps.map(prop => {
      text = `Math.${prop}()` // this will add extra (), so PI() will be PI()() as signature.
      let index = prop.index
      return { index, text }
    }) // PI=>Math.PI

    /** #5: To replace e3d functions by prefixing with 'utils.???'  */
    let iExprUtilsPubFuncs = uExprUtilsPubFuncs.map(func => {
      text = `window.utilsPub.${func}`  // TODO: in future, add namespace/module
      // let text = `${func[0]}`
      let index = func.index
      return { index, text }
    }) // rad=>utilsPub.rad

    /** #6: To use boolean names as is  */
    let iExprBooleanValues = uExprBooleans.map(bool => {
      text = `${bool[0]}`
      let index = bool.index
      return { index, text }
    }) // true=>true

    /** TODO:
     * In future, include my custom functions so that they can be used directly in expression.
     * Eg: rad(), deg(),
     *     cos(), sin() to be excluded from mathMethodListNoE_n_Trig, then my own copy that takes
     *     degrees can be used.
     */

    // let iExpr = iExprInputPQs.concat(iExprNumbers).concat(iExprSplitters).concat(iExprMathMethods);
    let iExpr = iExprInputPQs.concat(
                iExprNumbers,
                iExprSplitters,
                iExprMathMethods, iExprMathProps,
                iExprUtilsPubFuncs,
                iExprBooleanValues)
    iExpr = iExpr.sort((a, b)=> (a.index > b.index)? 1 : -1); // iExpr is array

    // let exprTmp = iExpr.map(item => item.text).join(''); // contains ()() to be removed

    /**
     * .replace("()()", '')  // this converts user input of PI() to Math.PI in internalExpr
     * .replace('^', "**")   // this converts user input of '^' to "**" in internalExpr
     */

    let evalExpr = iExpr.map(item => item.text).join('')   // join to a string of expression
      .replace( /\(\)\(\)/g, '' )  // updated per bug discovered by Eric!!!, ie: PI()=>Math.PI()()=>Math.PI
      .replace( /\^/g, "**" )
      /** Below needs to improve - the greek letter used as symbol name needs to be replaced as well */
      // .replace( /\τ/g, "TAU" )
      ; ////!!!! Great!!!!

    pe.internalExpression = `(${evalExpr})`
    console.log(`pe.internalExpression: ${pe.internalExpression}`)
  }

  /***
   *
   */
  const chartInputDataOK = (chartInput) => {
    debugger
    let symX = chartInput.symX,
        symYs = [...chartInput.symY.matchAll(/\w+/g)].map(item => item[0])

    if (PQs.length < 2) { return false }

    const i0 = PQs.findIndex(pq => pq.symbol === symX)
    if ( symX === '' || i0 === -1 || symYs.length === 0 ) {
      // console.error(`PQs does not contain ${symX}. Please enter one symbol only.`)
      return false
    }

    symYs.forEach((sym) => { // for each PE symbol
      let k = PEs.value.findIndex(pe => pe.symbol === sym)
      if (k === -1) {
        // console.error(`PEs does not contain ${sym}`)
        return false
      }
    })

    return true
  }

  /**
   * Prepare data for vue-chart-3 to draw scatter chart.
   * Other data / options can be provided by calling component.
   * argument Object {symX, xo, steps, symY}
   * @param {string} x - input PQ's symbol
   * @param {*} xo - x range offset on one side, hidden by default, to use settings to turn on/off
   * @param {*} n - steps in x range, hidden by default
   * @param {string} y - output variable PE's symbols, delimited by none letter chars, for now, delimited by ','
   * @returns {Array} - array of objects with x and y values
   */
  const chartDataSets = (chartInput) => {
    // 1. need to make sure x is an input for y (ie, validation required in future)
    debugger
    let symX = chartInput.symX,
        xo = chartInput.xo || 10,
        x0 = chartInput.x0 || 0,
        x1 = chartInput.x1 || 10,
        n = chartInput.steps || 10,
        symYs = [...chartInput.symY.matchAll(/\w+/g)].map(item => item[0]),
        dx = xo * 2 / n

    let xs = new Array(n), ys = new Array(), dataSets = new Array()

    // 2. find x's position in PQs, say PQs[k], record PQs[k] orginal value
    const i0 = PQs.findIndex(pq => pq.symbol === symX)
    if (i0 === -1) {
      console.error(`PQs does not contain ${symX}`)
      return []
    }

    let PQi = PQs[i0].value
    x0 = PQi - xo
    x1 = PQi + xo

    let j0 = []
    symYs.forEach((sym) => { // for each PE symbol
      let k = PEs.value.findIndex(pe => {
        return pe.symbol === sym
      })
      if (k === -1) {
        // console.error(`PEs does not contain ${sym}`)
        alert(`PEs does not contain ${sym}`)

        return []
      }
      j0.push(k)
    })

    for (let j=0; j<j0.length; j++) {
      let yjs = new Array(), xyjs = new Array()
      let yLabel = PEs.value[j0[j]].name,
          ySym = PEs.value[j0[j]].symbol

      for (let i=0; i<= n; i++) {
        xs[i] = x0 + dx*i
        PQs[i0].value = xs[i]
        PQs[i0].baseValue = xs[i] // setter to update baseValue for PQs[i0]

        // let y = eval(PEs.value[j0[j]].internalExpression)
        calculate(PEs.value[j0[j]]) // update PEs[j0[j]] with calculate(pe), not just internalExpression
        let y = PEs.value[j0[j]].value
        yjs.push(y)
        xyjs.push({x: xs[i], y})
      }

      let dataSet = { label: yLabel, symbol: ySym, data: xyjs, yAxisID: ySym }
      dataSets.push(dataSet)
    }

    PQs[i0].value = PQi // restore PQs[i0] orginal value,
    PQs[i0].baseValue = PQi // push to update BaseValue for PQs[i0]

    for (let j=0; j<j0.length; j++) { // restore PQs[i0] orginal value,
      calculate(PEs.value[j0[j]]) // update PEs[j0[j]] with calculate(pe), not just internalExpression
    }

    // 3. restore PQs[k].expression back to original value and rerun the actual calculation
    return dataSets
  }

  /**
   * renamed to getChartDataSets
   * @param {} charInput
   * @returns
   */
  const getXYs_R1 = (chartInput) => {
    // 1. need to make sure x is an input for y (ie, validation required in future)
    let symX = chartInput.symX,
        x0 = chartInput.x0 || 0,
        x1 = chartInput.x1 || 10,
        n = chartInput.steps || 10,
        symY = chartInput.symY

    let xs = new Array(n), ys = new Array(n), xys = new Array(n)

    // 2. find x's position in PQs, say PQs[k], record PQs[k] orginal value
    const i0 = PQs.findIndex(pq => pq.symbol === symX)
    if (i0 === -1) {
      console.error(`PQs does not contain ${symX}`)
      return []
    }
    let PQi = PQs[i0].value
    const j0 = PEs.value.findIndex(pe => pe.symbol === symY)
    if (j0 === -1) {
      console.error(`PEs does not contain ${symY}`)
      return []
    }

    for (let i=0; i<= n; i++) {
      PQs[i0].value = x0 + (x1-x0)*i/n
      xs[i] = x0 + (x1-x0)*i/n
      // replace PQs[k].expression with xs[i]

      // calculate((PEs.value)[j0]) // using xs[i] as input in place of PQs[k].expression
      // ys[i] = (PEs.value)[j0].value
      ys[i] = eval(PEs.value[j0].internalExpression)
      xys[i] = [xs[i], ys[i]]
    }

    PQs[i0].value = PQi // restore PQs[i0] orginal value

    // 3. restore PQs[k].expression back to original value and rerun the actual calculation
    return xys
  }

  const getXYs_R0 = (x /* PQ */, x0, x1, n, y /* PE */ ) => {
    // 1. need to make sure x is an input for y (ie, validation required in future)

    let xs = new Array(n), ys = new Array(n), xys = new Array(n)

    // 2. find x's position in PQs, say PQs[k], record PQs[k] orginal value
    const i0 = PQs.findIndex((pq) => pq.symbol === x)
    let PQi = PQs[i0].value
    const j0 = PEs.value.findIndex((pe) => pe.symbol === y)

    for (let i=0; i<n; i++) {
      PQs[i0].value = x0 + (x1-x0)*i/n
      xs[i] = x0 + (x1-x0)*i/n
      // replace PQs[k].expression with xs[i]

      calculate((PEs.value)[j0]) // using xs[i] as input in place of PQs[k].expression
      ys[i] = (PEs.value)[j0].value
      xys[i] = [xs[i], ys[i]]
    }

    // 3. restore PQs[k].expression back to original value and rerun the actual calculation
    return xys
  }

  const chartOptions = (chartInput) => {
    debugger
    let axesMap = new Map(),
        yAxesObjs = new Object()

    let xAxisID = chartInput.symX
    const i0 = PQs.findIndex(pq => pq.symbol === xAxisID)
    let pqUnit = PQs[i0].value ? PQs[i0].unit : ''
    let xAxisTitleText = pqUnit ? `${xAxisID} [${pqUnit}]`: xAxisID

    axesMap.set( // for xAxis
      xAxisID, {
        type: 'linear',
        display: true,
        title: {
          display: true,
          text: xAxisTitleText,
          color: '#0009d1',
          align: 'center'
        },
        position: 'bottom',
        beginAtZero: true
      }
    )

    // 'reverse' order so the axes in chart are in the same order as in in chartInput.symY
    let symYs = [...chartInput.symY.matchAll(/\w+/g)].map(item => item[0])

    // array.reduce(function(total, currentValue, currentIndex, arr), initialValue)
    let yAxesTemp = symYs,
        chartTitle = `"${xAxisID} - ${yAxesTemp.join('/')}" Chart`

    let yAxisUnits = []
    symYs.reverse().forEach((sym) => { // for each PE symbol
      let k = PEs.value.findIndex(pe => pe.symbol === sym)
      yAxisUnits.push(PEs.value[k].unit ? `[${PEs.value[k].unit}]` : '')
    })

    axesMap.set(symYs[0], { // the 1st yAxis has proirity to be the primary yAxis, can have special options.
      type: 'linear',
      display: true,
      title: {
        display: true,
        text: `${symYs[0]} ${yAxisUnits[0]}`,
        color: '#0009d1',
        align: 'end'
      },
      position: 'left',
      beginAtZero: true
    })

    let yAxisID
    for (let i=1; i<symYs.length; i++) {
      yAxisID = symYs[i]
      axesMap.set(yAxisID, {
        type: 'linear',
        display: true,
        title: {
          display: true,
          text: `${yAxisID} ${yAxisUnits[i]}`,
          color: '#0009d1',
          align: 'end'
        },
        position: 'left',
        beginAtZero: true,
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
      })
    }

    /* symYs.forEach((yAxisID) => { // set base template for each yAxis, to improve later.
      axesMap.set(yAxisID, {
        type: 'linear',
        display: true,
        title: {
          display: true,
          text: yAxisID,
          color: '#0009d1'
        },
        position: 'left',
        beginAtZero: true,
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
      })
    }) */

    // https://gist.github.com/lukehorvat/133e2293ba6ae96a35ba ES6 Map to Object
    yAxesObjs = Object.fromEntries(axesMap)

    const options = {
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: chartTitle
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: yAxesObjs
      /* scales: {
        b: {
          type: 'linear',
          display: true,
          title: {
            display: true,
            text: 'title',
            color: '#0009f1',
          },
          position: 'left',
          beginAtZero: true
        },
        c: {
          type: 'linear',
          display: true,
          title: {
            display: true,
            text: 'title c',
            color: '#f56',
          },
          position: 'right',

          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      }, */
    }
    return options
  }

  return {
    message,
    init, calcMode, canCalculate,
    PQs, addPqPe,
    refreshPQs,
    deleteCurrentPQ, hideCurrentPQ, PEs, P_Qs,
    clearCalc,
    rerun,
    validate, calculate, createInternalExpression,
    chartInputDataOK, chartDataSets, chartOptions
  }
}

export default useCalculation
