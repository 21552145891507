<template>
  {{ Calcs.length }} calcs were found.<br />
  <p>Searched results: </p>

  <div v-for="calc in Calcs" :key="calc.id">
    <!-- <div>card here</div>
    <p>calc@Calculators: {{ calc }} </p>
    <p>ID: {{calc.id}}</p>
    <p>desc: {{calc.calcDesc}}</p> -->
    <CalculatorCard :calc = 'calc' />
  </div>
</template>

<script>
// import { reactive } from 'vue'
import CalculatorCard from '@/components/CalculatorCard.vue'

export default {
  name: 'E3dCalculators',
  components: {
    CalculatorCard
  },
  props: {
    calcs: String
  },
  setup (props) {
    const Calcs = JSON.parse(props.calcs)
    console.log(`Calcs = ${Calcs}`)

    return { Calcs }
  }
}
</script>
