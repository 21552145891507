<template>
  <div class="grid grid-cols-proj-info8 items-start gap-1 mx-2 p-1">
    <label class="col-start-1 col-span-3 px-3" :for="label" v-if="label">{{ label }}:</label>
    <div class="col-start-4 col-span-5">
      <div v-if="type === 'text' || type === 'date'">
        <input :id="label" :type="type" class="project-info-input p-1"
          :value="modelValue"
          :readonly="readonly"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="label"
          :aria-describedby="error ? `${label}-error` : null"
          :aria-invalid="error ? true : null" >
      </div>

      <div v-else-if="type === 'textarea'">
        <textarea :id="label" class="project-info-textarea p-1" rows="4" maxlength="300"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          :placeholder="label"
          :aria-describedby="error ? `${label}-error` : null"
          :aria-invalid="error ? true : null" />
      </div>

      <div v-else-if="type === 'datalist'">
        <select class="project-info-input p-1"
          :id="label" :placeholder="label"
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
          :aria-describedby="error ? `${label}-error` : null"
          :aria-invalid="error ? true : null" >
          <option v-for="(data, i) in datalist" :key="i" :value="data">{{ data }}</option>
        </select>
      </div>

      <div v-else> --- </div>
      </div>

      <p v-if="error" class="errorMessage" :id="`${label}-error`" aria-live="assertive" >
        {{ error }}
      </p>
  </div>

</template>

<script>
// https://github.com/Code-Pop/Vue-3-Forms/blob/l9-end/src/components/BaseInput.vue
// import { reactive } from 'vue'
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    datalist: {
      type: Array
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  }
}
</script>

<style src="../styles/components/index.css" scoped></style>

<style scoped lang="scss">
  $input-bk-color1: #ccf2f4;/*  #94ebcd; */

  .container { // ref to PhysicalQuantity.vue
    display: flex;
    justify-content: right;
    align-items: right;
    width: 395px;
    margin: 0px 6px;
    padding: 2px 0px;
    border-collapse: collapse;
    box-sizing: border-box;
  }

  .project-info-input {
    background-color: $input-bk-color1;
    color: blue;
    text-align: left;
    width: 100%;
    transition: 750ms background-color;
  }

  .project-info-textarea {
    background-color: $input-bk-color1;
    color: blue;
    text-align: left;
    width: 100%;
    transition: 750ms background-color;
  }

  label {
    display: inline-block;
    text-align: right;
  }

</style>
