<template>
  <form class="grid grid-cols-4 gap-1 mx-auto p-1 w-4/5" @submit.prevent="handleSubmit">
    <div class="col-start-2 col-span-3 text-2xl font-bold text-center p-4">Sign Up</div>

    <label for="displayName" class="col-start-1 col-span-1 text-right m-2">Display Name:</label>
    <input id="displayName" class="col-start-2 col-span-3 input-pq1 m-2" type="text" v-model="displayName">

    <label for="email" class="col-start-1 col-span-1 text-right m-2">Email:</label>
    <input id="email" class="col-start-2 col-span-3 input-pq1 m-2" type="email" v-model="email">

    <label for="password" class="col-start-1 col-span-1 text-right m-2">Password:</label>
    <input class="col-start-2 col-span-3 input-pq1 m-2" type="password" v-model="password">
    <div v-if="error" class="error col-start-2 col-span-3 text-center">{{ error }}</div>
    <div class="col-start-3 col-span-1 centered">
      <button class="roundBtn" v-if="!isPending">Submit</button>
      <button class="roundBtn" v-if="isPending" disabled>Loading</button>
    </div>
  </form>
</template>

<script>
// using @ means start at the project src root
import useSignup from '@/composables/useSignup'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'E3dSignup',
  setup () {
    const { error, signup, isPending } = useSignup()
    const router = useRouter()

    const email = ref('')
    const password = ref('')
    const displayName = ref('')

    const handleSubmit = async () => {
      const res = await signup(email.value, password.value, displayName.value)
      console.log(res)
      await res.user.reload()

      if (!error.value) {
        router.push({ name: 'NewCalculator' })
      }
    }

    return { email, password, displayName, handleSubmit, error, isPending }
  }
}
</script>

<style src="../../styles/components/index.css" scoped></style>
