<template>
  <form class="grid grid-cols-4 gap-1 mx-auto p-1 md:w-4/5" @submit.prevent="handleSubmit">
    <div class="col-start-2 col-span-3 text-2xl font-bold text-center p-4">Reset Password</div>
    <label for="email" class="col-start-1 col-span-1 text-right m-2">Email:</label>
    <input id="email" class="col-start-2 col-span-3 input-pq1 m-2" type="email" v-model="email">

    <div v-if="error" class="error col-start-2 col-span-3 text-center">{{ error }}</div>
    <button class="roundBtn localBtnResetPwd" v-if="!isPending">Reset</button>
    <button class="roundBtn localBtnResetPwd" v-if="isPending" disabled>Loading</button>
  </form>
</template>

<script>
// using @ means start at the project src root
import useLogin from '@/composables/useLogin'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// import getUser from '@/composables/getUser'

export default {
  setup () {
    const { error, resetPassword, isPending } = useLogin()
    const router = useRouter()
    // const { user } = getUser()

    const email = ref('')

    const handleSubmit = async () => {
      // const res = await resetPassword(email.value)
      await resetPassword(email.value)

      if (!error.value) {
        router.push({ name: 'NewCalculator' })
      }
    }

    return { email, resetPassword, handleSubmit, error, isPending }
  }
}
</script>

<style src="../../styles/components/index.css" scoped></style>
