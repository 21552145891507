<template>
  <!-- Multi-step Mode for multiple step calc -->
  <div class="container">
    <!-- // for PQ integrated w/ unit conversion. -->
    <div  class="grid grid-cols-pq17 gap-1 mx-2" v-if="!isNaN(pq.expression)"
      @mouseleave="turnSpeechBubbleOff" @blur="turnSpeechBubbleOff">
      <span class="col-start-1 col-span-1 text-center relative drag-handle input-pq00"
        title="Drag &amp; Drop to Adjust Position.">
        1
      </span>

      <input class="col-start-2 col-span-6 input-pq0 text-crimsontext-bold"
        type="text" placeholder="" v-model.lazy="pq.name"/>
      <input class="col-start-8 col-span-2 input-pq1 text-center text-crimsontext-semibolditalic"
        type="text" placeholder="" v-model.lazy="pq.symbol" title="Enter Symbol Here." />

      <!-- '=' sign with PQOptions -->
      <span class="container col-start-10 col-span-1 text-center items-center relative
        cursor-pointer border-b border-blue-300" title="Click to show options."
        @click.stop="pq.showPQOptions = true">
        <div class="font-semibold text-black">=</div>
        <div class="flex flex-row justify-center items-center absolute bottom-7 left-0 w-40 px-2"
          v-if="pq.showPQOptions"
          title="fractional digits">
          <SpeechBubble>
            <button class="pr-2" @click="$emit('deletePQ_PE')" title="Delete this Quantity/Expression">
              <img src="@/assets/icons/cancel_black_24dp.svg"
                  alt="Delete the Quantity"
                  height="20" width="20" />
            </button>
            <!-- Below is redundent for PQ -->
            <input class="w-10 border-b rounded-md text-center"
              type="number" min="0" title="fractional digits"
              v-if="isNaN(pq.expression)"
              v-model="pq.fractionalDigits" @change="$emit('rerunCalc')"/>
          </SpeechBubble>
        </div>
      </span>

      <input class="col-start-11 col-span-4 input-pq1 text-right qtyInput text-crimsontext-semibolditalic"
        title="Enter Quantity / Expression." type="text"
        placeholder=""
        v-model.lazy="pq.expression"
        @change="expressionChanged()" />

      <div class="col-start-15 col-span-3 flex flex-col items-center">
        <auto-complete
          v-model.lazy="pq.unit"
          :data='uomCategories'
          :groupKey="'name'"
          :groupOptionsKey="'units'"
          title="Enter Unit Here."
          @change="pqUnitChanged()">
        </auto-complete>
      </div>

    </div>
    <div v-if="pq.unit">
      pq faceValue: {{pq.faceValue}} <br>
      baseValue: {{pq.baseValue}} <br>
      baseUnit: {{pq.baseUnit}} <br>
    </div>

  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { PhysicalQuantity, UomCategories } from '../API/pq.js'
import SpeechBubble from '@/components/SpeechBubble.vue'
import AutoComplete from '@/components/AutoComplete.vue'

export default {
  name: 'E3dPhysicalQuantity',
  components: {
    SpeechBubble,
    AutoComplete
    // Autocomplete
  },
  emits: ['deletePQ_PE', 'addPQ_PE'],
  props: {
    physicalQuantity: PhysicalQuantity,
    rowId: Number,
    mode: String
  },

  setup (props/* , ctx */) {
    const pq = reactive(props.physicalQuantity)
    const uomCategories = UomCategories
    const selected = ref(null)

    /* let test = pq.test;
    console.table(`pq.test: ${test}`) */

    function expressionChanged () {
      pq.normExpression = pq.expression
      const expr = pq.normExpression

      // For PQ: when pq.expression is empty or a number, rerun() will not be called as rerun() only
      // works for PEs
      if (!isNaN(expr)) { // when not PE, ie: '' or a number
        pq.faceValue = (expr === '') // empty expression input?
          ? '' // Y => faceValue gets empty to indicate no result avaialble
          : Number(expr) // N => assign expr to faceValue, and value

        pq.value = pq.faceValue
        // The following is confusing. It might be better to calculate/convert here. Then explicitly set the baseValue.
        // pq.baseValue = (pq.unit === '') ? pq.value : pq.fv2bv(pq.value) // this makes conversion twice.
        pq.baseValue = pq.value // this calls setter, which calls fv2bv() once
      }
    }

    const pqUnitChanged = () => {
      const oldBaseUnit = pq.baseUnit ? pq.baseUnit : ''
      // TODO: check if unit is valid
      pq.baseUnit = pq.unit // update to new input unit using setter
      const newBaseUnit = pq.baseUnit // using getter

      if (oldBaseUnit === newBaseUnit) {
      // if it is in the same unit category (have the same base unit), then no change to baseValue
        pq.faceValue = pq.bv2fv(pq.baseValue)
        pq.expression = pq.faceValue
      } else {
      // otherwise, recalculate baseValue for a new category
        const res = confirm('Confirm to change unit category?')

        if (res) { // back to previous valid unit and category
          // TODO: need more work here!!! How to keep previous unit state? => Maybe use faceUnit?
          const expr = Number(pq.expression)
          pq.faceValue = expr
          // pq.baseValue = pq.fv2bv(expr)
          pq.baseValue = expr
        }
      }
    }

    const peUnitChanged = () => {
      const oldBaseUnit = pq.baseUnit ? pq.baseUnit : ''
      pq.baseUnit = pq.unit // update to new input unit using setter
      const newBaseUnit = pq.baseUnit // using getter

      if (oldBaseUnit === newBaseUnit) {
      // if it is in the same unit category (have the same base unit), then no change to baseValue
        pq.faceValue = pq.bv2fv(pq.baseValue)
        pq.value = pq.faceValue
      } else { // otherwise, recalculate baseValue for a new category
        confirm('when provided, unit needs to be in the same category as before')
      }
    }

    const togglePQOptions = () => {
      pq.showPQOptions = !pq.showPQOptions
    }

    const turnSpeechBubbleOn = () => setTimeout(() => { pq.showPQOptions = true }, 500)
    const turnSpeechBubbleOff = () => setTimeout(() => { pq.showPQOptions = false }, 300)

    return {
      uomCategories,
      selected,
      pq,
      expressionChanged,
      pqUnitChanged,
      peUnitChanged,
      togglePQOptions,
      turnSpeechBubbleOff,
      turnSpeechBubbleOn
    }
  }
}
</script>

<style src="../styles/components/index.css" scoped></style>
<style scoped lang="scss">
.text-charm-bold { font-family: 'Charm-Bold'; }
.text-charm-regular { font-family: 'Charm-Regular'; }

.text-crimsontext-semibolditalic { font-family: 'CrimsonText-SemiBoldItalic'; }

.header-text { @apply bg-blue-500 text-center items-center justify-center text-white py-2 px-0 md:px-2 rounded; }
.btn-add-pq-pe {
  cursor: pointer;
  height: 40px;
  background-color: #8adcde;
  @apply rounded-full hover:bg-blue-300;
}

/*  For input in speechBubble to show the up/down arrows
  https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
  https://codepen.io/BJack/pen/FHgtc */
input[type=number]{
  &::-webkit-inner-spin-button{ opacity:1; } // magic!
}

.drag-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

.container0 { /* https://www.freecodecamp.org/news/how-to-center-anything-with-css-align-a-div-text-and-more/ */
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-single {
  grid-area: 'input';
  font-size: 2em;
  width: 90%;
  height: 120px;
}

.input-single:hover {
  grid-area: 'input';
  font-size: 2em;
  width: 90%;
  /* border: 2px dashed cyan; */
  box-shadow: 5px 5px aquamarine;
  cursor: text;
}

::placeholder {
    color: #8cf2e4;
    opacity: 1; /* Firefox */
    font-size: 0.8em;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #8cf2e4;
    font-size: 0.8em;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #8cf2e4;
    font-size: 0.8em;
  }

@font-face { /* https://fonts.google.com/specimen/Charm?preview.text=I%20love%20you.&preview.text_type=custom */
  font-family: 'Charm-Bold';
  src: local('Charm-Bold'), url('../assets/fonts/Charm/Charm-Bold.ttf') format('truetype'),
}

@font-face { /* https://fonts.google.com/specimen/Charm?preview.text=I%20love%20you.&preview.text_type=custom */
  font-family: 'Charm-Regular';
  src: local('Charm-Regular'), url('../assets/fonts/Charm/Charm-Regular.ttf') format('truetype'),
}

@font-face { /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: 'CrimsonText-SemiBoldItalic';
  src: local('CrimsonText-SemiBoldItalic'), url('../assets/fonts/CrimsonText/CrimsonText-SemiBoldItalic.ttf') format('truetype'),
}

@font-face { /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: 'CrimsonText-Bold';
  src: local('CrimsonText-Bold'), url('../assets/fonts/CrimsonText/CrimsonText-Bold.ttf') format('truetype'),
}

@font-face { /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: 'CrimsonText-SemiBold';
  src: local('CrimsonText-SemiBold'), url('../assets/fonts/CrimsonText/CrimsonText-SemiBold.ttf') format('truetype'),
}

@font-face { /* https://fonts.google.com/specimen/Crimson+Text */
  font-family: 'CrimsonText-Regular';
  src: local('CrimsonText-Regular'), url('../assets/fonts/CrimsonText/CrimsonText-Regular.ttf') format('truetype'),
}
</style>
