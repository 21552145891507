// Your web app's Firebase configuration
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDo_dtRP1iRBeB-cclUMvXI46qG8ce6eZQ',
  authDomain: 'auto-calc-80237.firebaseapp.com',
  databaseURL: 'https://auto-calc-80237.firebaseio.com',
  projectId: 'auto-calc-80237',
  storageBucket: 'auto-calc-80237.appspot.com',
  messagingSenderId: '679624004981',
  appId: '1:679624004981:web:cadb40cd028888101caf3c',
  measurementId: 'G-QJBMMGVWLE'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics();

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

const timeStamp = firebase.firestore.FieldValue.serverTimestamp

export { db, auth, storage, timeStamp }
