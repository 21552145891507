<template>
  <div class="container my-0">
    <div id="image-input-area" class="flex flex-col justify-center mx-3 p-3 image-input-area" contenteditable="false"
          @paste="pasteImage"
          @drop="fileDroppedIn"
          @dragover="$event.preventDefault()">

      <input type="file" id="imageFileInput" ref="imageFileInput" hidden multiple
        @change="addImagesByFile"> <!-- Note: use change() instead of click()!!!-->

      <div class="flex flex-row justify-center">
        <div class="text-2xl justify-center text-black font-bold border-b-3">Illustrative Image(s)</div>
        <button @click="showImages = !showImages">
          <label v-if="showImages">
            <img style="display: inline;"
              src="@/assets/icons/arrow_circle_up_black_24dp.svg"
              alt="Show Help"
              height="20" width="20" />
          </label>
          <label v-else><img style="display: inline;"
            src="@/assets/icons/arrow_circle_down_black_24dp.svg"
            alt="Show Help"
            height="20" width="20" />
          </label>
        </button>
      </div>

      <div v-if="showImages">
        <div v-if="images">
          <draggable v-model="Images" handle='.drag-handle'>
            <div v-for="(img, i) in Images" :key='img.name'>
              <div class="flex flex-row justify-between">
                <div>Image Name:
                  <u> {{ img.name }} </u>
                  <button class="roundBtn p-6" title="Toggle the Settings" @click="toggleImageOptions(img)"><b>&nbsp;&vellip;&nbsp;</b>
                    <!-- <img
                      src="@/assets/icons/settings-24px.svg"
                      alt="Show PQ Settings"
                      height="20" width="20" /> -->
                  </button>
                </div>

                <div v-if="img.showImageOptions">
                  <button class="roundBtn" title="Delete the Image"
                    @click="deleteImageFromUI(i)">
                    <img
                      src="@/assets/icons/delete_forever_black_24dp.svg"
                      alt="Delete the Calculation"
                      height="20" width="20" />
                  </button>
                  <!--TODO:
                    <button @click="renameImage(i)">Rename</button>
                    <button @click="replaceImage(i)">Replace</button>
                    other option buttons
                  -->
                </div>
              </div>
              <div class="drag-handle">
                <div v-if="img.urlGlobal"><!-- Global -->
                  <img :src="img.urlGlobal" alt="" class="image-type1 w-full">
                </div>
                <div v-else><!-- Local -->
                  <img :src="img.urlLocal" alt="" class="image-type1 w-full">
                </div>
              </div>
            </div>
          </draggable>
        </div>

        <div class="container flex flex-col justify-center md:w-3/5">
          <ul class="list-disc">
            <li>Paste ( <kbd>Ctrl</kbd>+<kbd>V</kbd> ) an Image from Clipboard, or</li>
            <li>Drop Image Files here [*.png / .jpeg / .jpg / .gif], or</li>
            <li>
              <button class="roundBtn" @click="addImages">Upload Image Files</button> from your device.
            </li>
          </ul>
        </div>

        <div>
          <div class="error"> {{ fileError }} </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */
import { reactive, computed, ref /*, onMounted, watch */ } from 'vue'
import * as utils from '../API/utils'
import { VueDraggableNext as draggable } from 'vue-draggable-next'
import useStorage from '@/composables/useStorage'

export default {
  name: 'ImageController',
  props: {},
  components: {
    draggable
  },
  emits: ['pasteImage'],
  setup (props) {
    const showImages = ref(true)
    // for all images, including from local harddrive & clipboard. These are all images for the
    // calc, and are to show on UI. And will be saved to FB.
    const images = reactive([])

    // images loaded or removed into memory from local harddrive and clipboard. newly added images
    // will be put into here. newly removed images will be removed from here, and added to
    // imagesToDeletefromFB if it's one that is already in FB.
    const imageFiles = reactive([])

    const imageFileInput = ref(null)

    const {
      url,
      upload: uploadImage,
      copyImage,
      deleteImage,
      deleteAll: deleteAllImages,
      error: errorStorage } = useStorage()

    // To test data in child component can be accessed from parent using ref attribute.
    const testData = ref('test data ddd')

    const fileError = ref(null)
    const imageUploadError = ref(null)

    // const imageUrls = reactive([])  // redundent - urlGlobal info is in images[]
    const imagesToDeletefromFB = reactive([]) // images removed from UI and to delete from FB

    /**
     *  This makes draggable work to save the updated list.
     */
    const Images = computed({
      get: () => images,
      set: val => {
        images.splice(0, images.length)
        images.push(...val)
      }
    })

    function addImages () {
      console.log('image adding button clicked')
      imageFileInput.value.click() // click the button with code
    }

    /**
     * To update file name to allow user to change projectName and calcName.
     * a new image will be saved as:
     *  'users/{userID}/{projectID}/{calcID}/{fileName}'
     */
    function addImagesByFile (e) {
      if (e.target.files.length !== 0) {
        // const fPath = `${projectID.value}`
        // const fPath = imageLocationPath
        console.log(`@addImagesByFile: e.target = ${JSON.stringify(e.target.files[0])}`)
        let files = Array.from(e.target.files)
        files.forEach(f => {
          if (utils.imageTypes.includes(f.type)) {
            const url00 = URL.createObjectURL(f) // local url
            const image = {
              name: f.name,
              type: f.type,
              locationRef: '',
              urlLocal: url00,
              urlGlobal: ''
            }
            imageFiles.push(f) // To be used for uploading image to FS
            images.push(image)
          } else {
            fileError.value = 'A file chosen is not image.'
          }
        })
      }
    }

    /**
     * https://codingnepalweb.com/drag-drop-file-upload-feature-javascript/
     */
    const fileDroppedIn = (e) => {
      e.preventDefault()
      console.log('@file dropped in')

      if (e.dataTransfer.files.length !== 0) {
        console.log(`@fileDroppedIn: e.dataTransfer = ${JSON.stringify(e.dataTransfer.files[0])}`)
        let files = Array.from(e.dataTransfer.files)
        files.forEach(f => {
          if (utils.imageTypes.includes(f.type)) {
            const url00 = URL.createObjectURL(f) // local url for blob
            const image = {
              name: f.name,
              type: f.type,
              locationRef: '',
              urlLocal: url00,
              urlGlobal: ''
            }
            imageFiles.push(f) // To be used for uploading image to FS
            images.push(image)
          } else {
            fileError.value = 'a file chosen is not image.'
          }
        })
      }
    }

    /**
     * https://jsfiddle.net/bt7BU/225/
     * https://stackoverflow.com/questions/6333814/how-does-the-paste-image-from-clipboard-functionality-work-in-gmail-and-google-c
     */
    async function pasteImage (event) {
      console.log(`@pasteImage: ${event}`)

      // use event.originalEvent.clipboard for newer chrome versions
      // The follwing currently works when there is one only images on clipboard.
      // what if clipboard data include multiple images? Even if I tried to copy multiple images at the same time, when pasteing, it only shows one image.
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      console.log(`items in clipboardData: ${JSON.stringify(items)}`) // will give you the mime types
      // find pasted image among pasted items
      let blob = null
      // const fPath = `${projectID}`
      const fPath = 'img' // actually should be refactor to fnamePrefix or sth.
      for (const item of items) {
        if (item.type.indexOf('image') === 0) {

          blob = item.getAsFile()
          // this allows blob to be renamed, per https://stackoverflow.com/questions/52813648/set-filename-to-a-blob-file
          console.log(`blob.name = ${blob.name}, type = ${blob.type}`) // blob.name = image.png, type = image/png

          const fName = `${fPath}-${new Date().valueOf()}` // with timestamp. In future, allow user to name and imageID
          const f0 = new File([blob], fName)
          const url00 = URL.createObjectURL(f0) // url0: blob:http://localhost:8080/e0bfaa61-744a-44b4-a35e-29743fec7675
          // let imageFile = { name: fName, type: f0.type, file: f0/* , url: url00 */ }
          const image = {
            name: fName,
            type: f0.type,
            locationRef: '',
            urlLocal: url00,
            urlGlobal: ''
          }
          imageFiles.push(f0) // To be used for uploading image to FS
          images.push(image)
          // await uploadImage(blobFile)
        }
      }
      // load image if there is a pasted image
      /*
      if (blob !== null) {
        let reader = new FileReader();
        reader.onload = function(event) {
          console.log(`ev.target.result = ${event.target.result}`); // data url!
          // ev.tartet.result = data:image/png;base64,iVBORw0....
          // document.getElementById("pastedImage").src = event.target.result;
          url1.value = URL.createObjectURL(blob) // url1: blob:http://localhost:8080/e0bfaa61-744a-44b4-a35e-29743fec7675
        };
        reader.readAsDataURL(blob);
      } */
    }

    /***
     * for new calc, all images are in imageFiles, upload them, and update images[] with urlGlobal;
     * for save existing calc, newly added images are in imageFiles as well, upload them;
     * for saveAs to a new calc, all images are in images[], use uploadImages() below.
     */
    const appendImages = async (path) => {
      // let _image
      let j

      if (imageFiles) {
        for (let imageFile of imageFiles) { // ref: for-of-loop-with-index.js
          try {
            // here is the time to rename the image file per latest projectID and calcID.
            // to rename image file before uploading.
            // _image = new File([imageFile], `${path}/${imageFile.name}`)
            debugger
            await uploadImage(path, imageFile) // upload image file to fb storage.

            // imageUrls.push(url.value) // url is from downloadUrl() which is accessible from Internet.
            console.log(`@appendImages: url = ${url.value}`)
            j = images.findIndex(image => image.name === imageFile.name )
            if (j !== -1) {
              debugger
              images[j].urlGlobal = url.value
              images[j].locationRef = path + images[j].name
            }
          } catch (errorStorage) {
            imageUploadError.value = errorStorage.value
          }
        }
      }
    }

    const uploadImages = async (path) => { // Make sure image will be saved to new path for SaveAs
      // let _image
      let toURL, oldURL

      if (images) {
        for (let [i, imageFile] of images.entries()) { // ref: for-of-loop-with-index.js
          try {
            // here is the time to rename the image file per latest projectID and calcID.
            // to rename image file before uploading.
            // _image = new File([imageFile], `${path}/${imageFile.name}`)
            debugger
            // await uploadImage(path, imageFile) // TODO: here imageFile is not a real image as in appendImages. Need to getDownloadURL from the image in FB, convert to blob, then upload back to at new location in FB.
            oldURL = imageFile.urlGlobal ? imageFile.urlGlobal : imageFile.urlLocal
            toURL = await copyImage(imageFile.name, oldURL, path)

            // imageUrls.push(url.value) // url is from downloadUrl() which is accessible from Internet.
            console.log(`@uploadImages: toURL = ${toURL}`)
            imageFile.urlGlobal = toURL
            imageFile.locationRef = path + imageFile.name
          } catch (errorStorage) {
            imageUploadError.value = errorStorage.value
          }
        }
      }
    }

    const toggleImageOptions = (img) => {
      img.showImageOptions = !img.showImageOptions
    }

    /**
     * 1. For newCalc:
     * -  when an image[i] is to be deleted, find the coresponding imageFile[j] and delete
     *    it from UI
     * -  delete the image[i]
     *
     * 2. For E3dCalc0 that is loaded from FB:
     * -  say the calc already had images[n]
     * -  when no new image is added from UI, then imageFiles is empty. In this case,
     *    to delete an image, just delete image[i]
     * -  when some new images have been added, to delete an image, there are 2 cases:
     *    (a) it is an image that already is in FB. Put it in imagesToDeleteFromFB[].
     *      imageFiles[] includes only newly added ones, thus will not be affected;
     *    (b) it is a newly added image thus exists in imageFiles[]. So needs to be found
     *      from imageFiles[] and removed from both imageFiles[] and images[]
     */
    const deleteImageFromUI = (i) => {
      if (images[i].urlGlobal) imagesToDeletefromFB.push(images[i])  // only mark those already in FB to be deleted
      // delete it from images on UI, ie: imageUrls

      // find the imageFile that has the same file name with the current images[i]
      let j = imageFiles.findIndex((imgFile) => imgFile.name === images[i].name)
      if (j !== -1) imageFiles.splice(j, 1)

      images.splice(i, 1)
      // delele it from imageFiles that is in memory, so when it is time to save to FB, everything is ready.
    }

    /**
     * Make sure only images related to this calc can be deleted.
     */
    const deleteImagesFromFB = (loc) => {
      /*** cases:
       * 1. user open its own calc, alert user to confirm.
       * 2. user opens other's calc, prompt to clone the calc.
       */
      for (let image of imagesToDeletefromFB) {
        deleteImage(image.locationRef)
      }
    }

    return {
      testData,
      showImages,
      images,
      Images,
      imageFiles,
      imageFileInput,
      // imageUrls,
      imagesToDeletefromFB,
      fileError,
      imageUploadError,
      addImages,
      addImagesByFile,
      fileDroppedIn,
      pasteImage,
      appendImages,
      uploadImages,
      toggleImageOptions,
      deleteImageFromUI,
      deleteImagesFromFB
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../styles/components/index.css" scoped></style>
