 export default class Dimension {
  constructor (dim = { m: 0, kg: 0, s: 0, A: 0, K: 0, mol: 0, cd: 0, ea: 0, $: 0 }) {
    this.m = dim.m // length
    this.kg = dim.kg // mass
    this.s = dim.s // time
    this.A = dim.A // electric current
    this.K = dim.K // thermodynamic temperature
    this.mol = dim.mol // amount of substance
    this.cd = dim.cd // luminous intensity
    /* Don note: suprisingly, this is not a dimension. 'Amount of angle' is prompted by AI. Likely
       somebody in the world had come with the same solution.
       Set default to 0 so all regular units don't use this dimension.
       'ea' is created to be used for 2 and 2 only cases below:
       - Real unitless quantities
       - Units related to angle. Eg: angle.dimension = {'ea': 1}; angular velocity = 1 rad/s, its dimension is {ea: 1, s: -1}
       */
    this.ea = dim.ea // amount of angle or constant value
    this.$ = dim.$ // amount of $
  }

  baseDims = ['m', 'kg', 's', 'A', 'K', 'mol', 'cd']

  footprint () {
    let fp = ''
    /* for(let key in this) { // this works as well.
      let value = this[key]
      // hide ea/$ etc. to avoid confusing and people may figure out how i handle these cases.
      if ((key !== 'ea') && (key !== '$') && value) { fp += key + value + '⋅' }
    } */

    Object.entries(this).forEach(([key, value]) => {
      // if ((key !== 'ea') && (key !== '$') && value) { fp += key + value + '⋅' }
      if (this.baseDims.includes(key) && value ) { fp += key + value + '⋅' }
    })

    // https://masteringjs.io/tutorials/fundamentals/remove-last-character#:~:text=Nov%2012%2C%202021-,To%20remove%20the%20last%20character%20from%20a%20string%20in%20JavaScript,length%20%2D%201)%20.
    // get rid of the last character - an extra dot '⋅'
    return fp.slice(0, -1)
  }
}
