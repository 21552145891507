<template>
  <!-- {{ theCalculation.getXYs('a', 1, 10, 9, 'b') }} -->
  <div class="container flex flex-col justify-center items-center">
    <div>Axis X:
      <input class="border border-blue-300 text-blue-700 text-center" type="text" name="xAxis" id="xAxis"
        v-model="chartInput.symX">
    </div>

    <div v-if="false">
      <div>x-offset:
        <input class="border border-blue-300 text-blue-700 text-center" type="number" name="xOffset" id="xOffset"
          v-model="chartInput.xo">
      </div>

      <div>Steps:
        <input class="border border-blue-300 text-blue-700 text-center" type="number" name="steps" id="steps"
          v-model="chartInput.steps">
      </div>
    </div>

    <div>Axis Y(s):
      <input class="border border-blue-300 text-blue-700 text-center" type="text" name="yAxis" id="yAxis"
        v-model="chartInput.symY">
    </div>
  </div>

  <ScatterChart v-if="inputOK" :options="chartOptions" :chartData="dataSets" />
</template>

<script>
import { computed, reactive, watch } from 'vue'
import useChart from '@/composables/useChart'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Scatter as ScatterChart } from 'vue-chartjs'
ChartJS.register(...registerables)
// import { Chart, ArcElement, BarController, LineController, PieController, Tooltip, CategoryScale } from 'chart.js'
// Chart.register(BarController, LineController, PieController, ArcElement, Tooltip, CategoryScale)

export default {
  components: {
    ScatterChart
  },
  props: {
    chart: {}
  },

  setup (props) {
    console.log(`@vue-chart: ${props.chart}`)

    const chartInput = reactive({
      symX: '',
      xo: 10, // offset on one side
      x0: 1,
      x1: 10,
      steps: 9,
      symY: ''
    })

    const { inputOK, getDataSets, getOptions } = useChart(chartInput, props.chart.calculation)

    const dataSets = computed(() => { return getDataSets(chartInput) })

    const chartOptions = computed(() => { return getOptions(chartInput) })

    watch(chartInput, () => {
      getDataSets(chartInput)
      getOptions(chartInput)
    })

    return { /* scatterData,  */
      chartInput,
      inputOK,
      dataSets,
      chartOptions // theChart
    }
  }

}

/** https://jsfiddle.net/beaver71/2j2gk8nb/ scatterChart example */
</script>
