import { createRouter, createWebHistory } from 'vue-router'
// import { auth } from '../tools/firebase/fb'
import Signup from '@/views/auth/Signup.vue'
import Login from '@/views/auth/Login.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import Help from '@/components/Help.vue'
import NewCalculator from '@/components/NewCalculator.vue'
import E3dCalculators from '@/views/Calculators.vue'
import E3dCalculator0 from '@/components/E3dCalculator0.vue'
import MultiValueInputTest from '@/components/MultiValueInputTest.vue'


/* const requireAuth = (to, from, next) => {
  const user = auth.currentUser
  if (!user.emailVerified) { // ??
    next({ name: 'Login' })
  } else { next() }
} */

const routes = [
  // { path: '/', name: 'Home', component: Home },
  // { path: '/', redirect: 'calculators' },
  { path: '/', name: 'Home', component: NewCalculator/* , beforeEnter: requireAuth */ },
  { path: '/signup', name: 'Signup', component: Signup },
  { path: '/login', name: 'Login', component: Login },
  { path: '/resetPassword', name: 'ResetPassword', component: ResetPassword },

  { path: '/help', name: 'Help', component: Help },

  { path: '/new-calculator', name: 'NewCalculator', component: NewCalculator }, // single mode.
  { path: '/multi-value-input-test', name: 'MultiValueInputTest', component: MultiValueInputTest }, // test mode.
  { path: '/embed-calculator', name: 'EmbedCalculator', component: NewCalculator },//single mode embedded in a page
  {
    path: '/calculators',
    name: 'E3dCalculators',
    component: E3dCalculators,
    props: true,
    meta: { title: 'AutoCalcs', },
  },
  {
    path: '/calculators/:id',
    name: 'E3dCalculator',
    component: E3dCalculator0,
    meta: { title: 'AutoCalcs', },
    props: true,
  },
  { path: '/embed/:id', name: 'EmbeddedCalculator', component: E3dCalculator0, props: true },
  { path: '/:pathMatch(.*)*', redirect: 'E3dCalculators' },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

router.beforeEach(to => { // not function when an existing calc is loaded
  // debugger
  // const calcTitle = to.component.calcTitle ? to.component.calcTitle : 'AutoCalcs'
  const titleFromParams = to.params?.calcTitle ? to.params?.calcTitle : ''
  if (titleFromParams) {
    document.title = `${document.title}-${titleFromParams}`
  }
  else {
    document.title = to.meta?.title ?? 'AutoCalcs'
  }
})

router.afterEach((to) => { // note function yet
  // debugger
  // const calcTitle = to.component.calcTitle ? to.component.calcTitle : 'AutoCalcs'
  const titleFromParams = to.params?.calcTitle ? to.params?.calcTitle : ''
  if (titleFromParams) {
    document.title = `AutoCalcs-${titleFromParams}`
  }
  else {
    document.title = to.meta?.title ?? 'AutoCalcs'
  }
})

export default router
