<template>
  <div class="grid grid-cols-1 container max-w-screen-lg">

    <!--
    <ais-instant-search :search-client="searchClient" index-name="demo_ecommerce">
      <ais-search-box />
      <ais-hits>
        <template v-slot:item="{ item }">
          <h2>{{ item.name }}</h2>
        </template>
      </ais-hits>
    </ais-instant-search>
    -->

    <!-- header -->
    <div v-if="!($route.fullPath.includes('embed'))"
      class="grid container place-items-center px-3 mx-auto w-full top-2">
      <header class="grid grid-cols-12 justify-between text-blue font-bold rounded-lg border shadow-lg p-4 mx-8 w-full h-30">
        <a class="col-start-1 col-span-2" href="http://www.donwen.com/">
          <img class="rotating" alt="E3d logo" src="./assets/e3d-logo2.png" width="60" />
        </a>
        <div class="col-start-3 col-span-10">
          <div class="flex flex-col mx-6">
            <div class="flex flex-row p-1 justify-center">
              <div class="text-3xl font-sans p-1 centered0">
                <router-link :to="{ name: 'NewCalculator' }">AutoCalcs</router-link>
              </div>
              <Navbar v-if="true"/>
            </div>

            <div class="text-center m-3" style='font-family: "Comic Sans MS", cursive, sans-serif; color:#1aa3ff;'>
              <!-- {{ slogan0 }} <br> -->
              <!-- {{ slogan1 }} <br> -->
              {{ slogan2 }}

            </div>

            <!-- <E3dCalculator0></E3dCalculator0> -->
            <!-- <router-link :to="{ name: 'calculator', params: { id: calcId }}">Calculator {{calcId}}</router-link> -->
            <!-- <router-link v-if="calcSelected" :to="{ name: 'calculator', params: { id: calcSelected.id }}">
              Selected Calculator {{calcSelected.id}}
            </router-link> -->
          </div>
        </div>

      </header>
    </div>

    <!-- main content -->
    <div class="grid grid-cols-1 container mx-auto my-1">
      <!-- <SearchBox /> -->

      <div id="nav" v-if="false"> |
        <!-- <router-link :to="{ name: 'Home' }">Home</router-link> | -->
        <router-link :to="{ name: 'Calculators' }">Calculators</router-link> |
      </div>

      <div class="bg-gray-50 py-1">
        <!-- https://stackoverflow.com/questions/60057473/vue-js-update-router-view -->
        <router-view :key="$route.fullPath"/>
      </div>

      <div class="centered0">
        <div v-if="!($route.fullPath.includes('embed'))"
          class="text-center my-2 bottom-0 mx-auto w-full text-sm bg-gray-50 mt-20">
          Copyright©2013-{{currentDate.getFullYear()}} DonWen Services. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive/* , computed */ } from 'vue'
// import { useRoute } from 'vue-router'
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';

// import SearchBox from './components/SearchBox.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    // SearchBox,
    Navbar,
  },
  setup () {
    const currentDate = reactive(new Date())
    const slogan0 = 'Each Calculation Is an App.'
    const slogan1 = 'Unit Conversion can be Automated.'
    const slogan2 = 'Search, Create, Store & Share UCUC (Unit Conversion Unified Calculations).'

    // const slogan0 = 'Here Every Calc Is an App that can be Shared!'
    // const slogan0 = reactive('Every Calculation You Create Becomes an App that Can be Shared with Friends!')

    /* const embedded = computed(() => {
      debugger
      console.log('embed', useRoute().name)
      const embed = useRoute().name.includes('embed')
      console.log('embed', embed)
      return embed
    }) */

    const searchClient = algoliasearch(
      '9JK1D4SRLZ',
      '703bbb8cd82a384e0ff69aa45fe05e69'
    )

    return {
      currentDate, slogan0, slogan1, slogan2,
      searchClient,
      // embedded
    }
  }
}
</script>

<style src="./styles/components/index.css" scoped></style>
