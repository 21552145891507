/* eslint-disable */

import { ref } from 'vue'
import { db } from '../tools/firebase/fb'

const useCollection = (collection, calcPath) => {
  const error = ref(null)
  const isPending = ref(false)
  // const docID = ref('')

  // add a new document
  const addDoc = async (loc, calcID, doc) => {
    error.value = null
    isPending.value = true

    try {
      // const res = await db.collection(collection).add(doc)

      console.log(`@addDoc: doc = ${doc}`)
      console.log(`@addDoc: loc = ${loc}`)
      console.log(`@addDoc: calcID = ${calcID}`)
      console.log(`@addDoc: projectID = ${doc.projectID}`)
      // const res = await db.collection("users").document("don.wen@yahoo.com").collection("general").add(doc) // Did not work.
      // const res = await db.collection('users/don.wen@yahoo.com/general').add(doc) // worked during test
      await db.collection(loc).doc(calcID).set(doc)
        .then(() => {
          console.log('Document successfully written!')
        })
        .catch(err => {
          console.error('Error writing document: ', err)
        })

      // docID.value = res.id // docID is generated by FB. => This was used when FB's autoID is used.

      isPending.value = false

      // console.log(`res @useCollection: ${res}`)

      // return res
    } catch (err) {
      console.log(`@addDoc: err.message = ${err.message}`)
      error.value = 'could not add the calc'
      isPending.value = false
    }
  }

  return { error, addDoc, isPending/* , docID */ }
}

export default useCollection
