<template>
  <div class="flex flex-col justify-center mx-auto">
    <auto-complete-test v-if="false" :physicalQuantity="PQtest"></auto-complete-test>

    <SearchBox v-if="!($route.fullPath.includes('embed'))"> <!-- regular mode with search -->
      <Toolbar :calculation='theCalculation' :settings='settings'
              @saveCalcData='saveCalcData()'
              @addChart='addChart()'/><br>
    </SearchBox>

    <div v-if="($route.fullPath.includes('embed'))"> <!-- no search for embeded mode -->
      <Toolbar :calculation='theCalculation' :settings='settings'
                @saveCalcData='saveCalcData()'
                @addChart='addChart()'/><br>
    </div>

    <!-- <ScatterChart /> -->

    <div v-if="charts">
      <div v-for="chart in charts" :key="chart.chartID">
        <VueChart :chart='chart' />
      </div>
    </div>

    <!-- <VueChart v-if="theCalculation.chartInputDataOK(chartInput)"
      :chartOptions="theCalculation.getChartOptions(chartInput)"
      :chartData="theCalculation.getChartDataSets(chartInput)">
    </VueChart> -->

    <!-- for migrating only -->
    <button v-if="isTesting" class="text-blue-700" @click="migrating">migrate existing calcs</button>

    <div v-if="isTesting">
      <div v-if="user"> user info:
        <div>{{ user.displayName }}</div>
      </div>
      sin() for angles in degrees <br />
      angle: <input type="number" v-model="angle"><br />
      sin({{angle}}) = {{ sinX }}<br />

      parse input: <input type="text" v-model="parseInput"><br />

      parse test: {{ parseTest }} <br />
      <!-- parser test: {{ parserTest }}  --> <!-- not working yet.  -->
    </div>

    <E3dProjectInfo v-if="settings.showProjectInfo" :projectData='projectDataArray'></E3dProjectInfo>

    <!-- <div>
      <li v-for="d in projectData.info" :key="d.label">
        type: {{d.type}}|label: {{d.label}}|value: {{d.value}}
      </li>
    </div> -->

    <div v-if="theCalculation.PQs.length > 1"> <!-- calc form/table header  -->
      <E3dPhysicalQuantity :physicalQuantity="pq0" :mode="theCalculation.calcMode.value"
        @autoUnitConversion='autoUnitConversion()'
        @addPQ_PE="theCalculation.addPqPe"/>

      <!-- keep this !!!!!!!
        <span v-if="!showPQSettings" style="padding: 2px;"></span>
        <span v-else></span>
      -->
    </div>

    <!-- showExpression:
        when the calc contains hidden rows, all expressions that are not numbers or true/false
        will be hidden to avoid misleading info as the equations may contain variables that are
        from hidden rows.

        In other words, when the calc contains hidden rows, all expressions of PEs should be
        hidden for settings.showHiddenRows === false. 20230523

         for PEs | settings.showHiddenRows | showPQRow | showExpression
        case 1:           T                     T           T (show expression)
        case 2:           T                     F           T (show expression)
        case 3:           F                     T           F (hide expression)
        case 4:           F                     F           F (hide expression and PE)
    -->
    <draggable v-model="theCalculation.P_Qs.value" handle=".drag-handle">
      <div v-for="(pq, i) in theCalculation.P_Qs.value" :key="pq.id">
        <E3dPhysicalQuantity :physicalQuantity="pq" :rowId="i"
          v-if="pq.showPQRow || settings.showHiddenRows"
          :mode="theCalculation.calcMode.value"
          :showExpression="isNaN(pq.expression) && settings.showHiddenRows"
          @focus="selectAll()"
          @UNIT_CHANGED="updateChartDataSet()"
          @deletePQ_PE="theCalculation.deleteCurrentPQ(pq, i)"
          @showPQ_PE="theCalculation.hideCurrentPQ(pq, i)"
          @rerunCalc="theCalculation.rerun()"
        />
        <!-- updateChartDataSet(pq) -->
      </div>
    </draggable>

  <!--
    <div v-if="settings.showImageInput">
      <br><br><br>
      <label for="fileInput">Select Image File:</label>
      <input type="file" @change="fileChanged" id="fileInput">
      <div class="error"> {{ fileError }} </div>
      <div v-if="file">
        <img class="preview" :src="pictureUrl" width="300">
      </div>
      <div class="base-image-input" contenteditable="false" @paste='pasteImage'>
        <div>Please paste your image here.</div>
        <hr>
        url1: {{url1}} <br />
        Image:<img id="pastedImage" :src='url1'>
      </div>
    </div>
  -->

    <ImageController ref='imageController' v-show="settings.showImageInput" />
    <!-- <div v-if="imageController">
      <input type="text" v-model="imageController.testData">
      {{imageController.testData}}
    </div> -->
    <br><br><br><br><br><br><br>
    <br><br><br><br><br><br><br>
    <br><br><br><br><br><br><br>

    <div class="container my-1">
      <div class="flex flex-col justify-center mx-3 p-3 hint-area" v-if="theCalculation.calcMode.value === 'SINGLE'">
        <div class="text-2xl font-bold text-center underline">Hints</div>

        <ul class="list-disc">
          <li>Click
            <button class="roundBtn toolbarItem"
              @click="theCalculation.addPqPe">
              <img src="../assets/icons/add_circle_outline_black_24dp.svg"
                height="20" width="20" />
            </button>
            button or press <kbd>Alt</kbd>+<kbd>Q</kbd> to add more quantities/expressions for multi-step calcs.
          </li>
          <li>Click
            <button class="roundBtn toolbarItem" title="Show Help"
              @click="openLinkInNewTab({name: 'Help'})">
              <img
                src="../assets/icons/help_outline-24px.svg"
                alt="Show Help"
                height="20" width="20" />
            </button>
            for help and lists of available math functions and constants.
          </li>
          <li>
            If you have a link for a previous calc, try search its keywords to get the updated version.
          </li>
        </ul> <br><br>

        <div class="text-2xl font-bold text-center underline">Sample Calculation</div><br>

        <iframe src="https://v2.donwen.com/embed/c-20220307.234553663-e3d-09d418-5b4a3f"
          width="80%" height="450" style="border:1px solid black;">
        </iframe>
        <div>Note: the above sample shows a calculation that is embedded in the page and can be used on the spot.<br><br></div>

        <div class="text-2xl font-bold text-center underline">Sample 3rd Party Webpage with Embedded AutoCalcs Calculation</div><br>
        <div><a href="https://e3d.github.io/">https://e3d.github.io/</a><br><br></div>

      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
/* eslint-disable-next-line no-unused-vars */
//ts-check
import { reactive, ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import * as utils from "../API/utils"
import * as utilsPub from "../API/utils-public"
import { db /*, storage */ } from '../tools/firebase/fb'
import E3dPhysicalQuantity from "./PhysicalQuantity.vue"
import E3dProjectInfo from '@/components/ProjectInfo.vue'
import ImageController from '@/components/ImageController.vue'
import Toolbar from '@/components/Toolbar.vue'
import SearchBox from '@/components/SearchBox.vue'
import useCalculation from '@/composables/useCalculation'
// import getDocument from '@/composables/getDocument'
import { VueDraggableNext } from 'vue-draggable-next'
import VueChart from '@/components/VueChart.vue'
import BarChart from '@/components/BarChart.vue'
import ScatterChart from '@/components/ScatterChart.vue'
import AutocompleteDemo from './AutocompleteDemo.vue'
import AutoCompleteTest from './auto-complete-test.vue'

import {
  PhysicalQuantity,
  /* normalizeExpression,
  mathMethodListFull, mathPropListFull */
} from "../API/pq.js"

import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import useKeydown from '@/composables/useKeydown'

function myFunc(params, expr) {
  console.log(`@func: expr = ${expr}`)
  console.log(`params = ${params}`)
  return new Function(...params, `return ${expr}`)
}

window.utilsPub = utilsPub
window.utils = utils

export default {
  name: "NewCalculator",
  components: {
    E3dPhysicalQuantity,
    ImageController,
    E3dProjectInfo,
    Toolbar,
    SearchBox,
    draggable: VueDraggableNext,
    VueChart,
    AutocompleteDemo,
    AutoCompleteTest,
    BarChart, ScatterChart
  },
  // props: ['id'],

  setup(props) {
    const { user } = getUser()
    // test
    const angle = ref(30)
    const parseInput = ref('utilsPub.sin0(60)')
    const isTesting = ref(false)
    const PQtest = new PhysicalQuantity({
      name: 'aTest',
      symbol: 'aTest',
      expression: '12',
      unit: ''
    })

    // for migrating
    const calcsInCloud = reactive(new Array())

    const settings = reactive({
      showProjectInfo: false,
      showPQSettings: false,
      showImageInput: false, // default to false
      showSaveAsNewCalc: false,
      showDeleteCalc: false,

      showTooltip: false,
      autoSymbol: false,
      alertIndicatorON: false, // turned ON by app whenever error msg is found.
      angleInDegrees: true,
      unitConversionAuto: true,

      showHiddenRows: true, // this should be stored with the calc to be effective when loading existing calc.
    })

    const libID = ref('mathCalc') // default to 'mathCalc'

    const router = useRouter()

    const projectID = utilsPub.UUID('p')
    const calcID = ref('initial')
    const ownerID = ref('')

    /**
     * ProjectInfo.vue's template will check property name for sub-string of 'Date' to use date format. So Date is reserved to be used only for actual Date related property use.
     * In the future, I will update:
     * - 'Desc' to be multi-line textarea, may use v-if to switch between <input> and <textarea>
     * - some property may be array type. How to support?
     */

    const projectData0 = new Map()
    projectData0
      .set('title', { type: "text", label: "Title", value: 'New Calc' })
      .set('description', { type: "textarea", label: "Description", value: '' })
      .set('projectName', { type: "text", label: "Project Name", value: '' })
      //category: math, physics, mechanical, structural, daily-life, etc.
      .set('category', { type: "text", label: "Category", value: 'Misc.' })
      .set('keywords', { type: "text", label: "Keywords", value: '' })

      // "protected": access by group member.
      .set('visibility', { type: "datalist", label: "Visibility", value: 'public', datalist: [ 'public', 'private', 'protected' ] })
      .set('status', { type: "text", label: "Status", value: '' })
      .set('ownerDN', { type: "text", label: "Owner", value: "anonymous", readOnly: 'true' })
      .set('createdAt', { type: "date", label: "Created At", value: new Date().toISOString().substring(0, 10) })
      .set('updatedAt', { type: "date", label: "Updated At", value: new Date().toISOString().substring(0, 10) })

      .set('rev', { type: "text", label: "Rev", value: '' })
      .set('note', { type: "textarea", label: "Note", value: '' })
      .set('references', { type: "textarea", label: "References", value: '' })

    const projectData = reactive(projectData0)

    // https://docs.github.com/en/rest/reference/repos#list-user-repositories
    const visibility = ref('public') // Can be one of public, private, internal.

    // const calcData = ref(null)

    const file = ref(null)
    const fileError = ref(null)
    const pictureUrl = ref(null)

    const imageFileInput = ref(null)
    const url1 = ref('')

    const imageController = ref(null)

    // pq0 is not member of PQs
    const pq0 = reactive(new PhysicalQuantity({
      name: "Description",
      symbol: "Symbol",
      expression: 'Quantity/Expression',
      unit: `Unit[Auto]`,
      unitConversionAuto: settings.unitConversionAuto
    }) )

    const theCalculation = useCalculation(settings)

    const unitConversionMode = computed(() => settings.unitConversionAuto ? 'Auto' : 'Manual')

    const searchTerm = ref('')

    const { filePath, url, upload: uploadImage, deleteImage, deleteAll: deleteAllImages, error: errorStorage } = useStorage()
    const { error: errorDoc, addDoc, docID } = useCollection(libID.value)

    const charts = reactive([])

    onMounted(() => {
      pq0.unit = `Unit[${unitConversionMode.value}]`
      initCalcData()
    })

    const sinX = computed(() => {
      /**
       * // https://javascript.info/eval
       * // https://javascript.info/new-function
       * So, such function doesn’t have access to outer variables, only to the global ones.
       * by Samuel Mathew:
       * To fix the other code, use window to make it global.
            function getFunc() {
              window.value = "test"
              let func = new Function("alert(window.value)");
              return func
            }
            getFunc()();
       */

      let expr = 'utilsPub.sin0(x)'
      let params = ['x']

      let sin = myFunc(params, expr)

      return sin(angle.value)
    })

    const parseTest = computed( () => utilsPub.parse(parseInput.value) )

    const parserTest = computed(() => {
      return utilsPub.parser(parseInput.value)  // not working correctly
    })

    const projectDataArray = computed(() => {
      return Array.from(projectData, ([key, value]) => ({key, value}))
    })

    /**
     * -  If there is input id, and there is calcs data searched from FB available already, then grab
          data from there. => This is what happens when user choose a calc from Calculators component.
     * -  If calcsData is not available, then search FB by the id. => This is what happens when user
          enter a calc specific url in address bar.
     * -  If found, poppulate data for the calc per the id.
     * -  If no id, then start with blank page allowing user to start from scratch, or search ...
     */
    const initCalcData = async () => {
      theCalculation.clearCalc()
      theCalculation.init()

      /*       let i = projectData.info.findIndex( prop => prop.label === 'Owner')
      projectData.info[i].value = user.value ? user.value.displayName : 'anonymous' */
    }

    function clearWorkspace() {
      searchTerm.value = ''
      theCalculation.clearCalc()
    }

    const calcLocationPath = computed(() => {
      return isTesting.value ? 'testCalcs' : 'calcs'
      //if (user) { return `users/${user.value.uid}/${projectID}`}
      /* if (user.value) { return `users/tiers/01` }   // registered
      return ('users/tiers/00')               // unregistered */
    })

    const imageLocationPath = computed(() => {
      if (user.value) {
        return `${calcLocationPath.value}/images/tier01/${user.value.uid}/${projectID}/${calcID.value}/`
      }
      return `${calcLocationPath.value}/images/tier00/anonymous/${projectID}/${calcID.value}/`
    })

    const ownerEmail = () => user ? user.value.email : 'anonymous'
    const _calcDesc = computed(() => { // to deprecate, use projectData.get('description') directly
      return projectData.get('description')
    })

    const _calcTitle = computed(() => { //as above
      let titleInfo = projectData.info.find((info) => info.label === 'Title')
      return titleInfo.value
    })

    /**
     * https://jsfiddle.net/chrisvfritz/1oqjojjx/?utm_source=website&utm_medium=embed&utm_campaign=1oqjojjx
     * @param {*} ev
     */
    function selectAll(ev) {
      // Workaround for Safari bug
      // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
      setTimeout(function () { ev.target.select() }, 200)
    }

    function switchTooltipOn() {
      setTimeout( ()=> { settings.showTooltip = true }, 300 );
    }

    function switchTooltipOff() {
      setTimeout( ()=> { settings.showTooltip = false }, 2000 );
    }

    function autoUnitConversion() {
      settings.unitConversionAuto = !settings.unitConversionAuto
      pq0.unitConversionAuto = settings.unitConversionAuto
      console.log(`@autoUnitConversion: ${JSON.stringify(pq0)}`)
      pq0.unit = `Unit[${unitConversionMode.value}]`
    }

    const saveCalcData = async () => {
      // let titleInfo = projectData.info.find((info) => info.label === 'Title')
      if (!projectData.get('title')) {
        alert('Provide a Title for your calculation before saving to the cloud!')
        return
      }

      calcID.value = utilsPub.UUID('c')
      // console.log(`projectData@saveCalcData = ${projectData}`)

      if (imageController.value.imageFiles) {
        await imageController.value.appendImages(imageLocationPath.value)
      }

      let newOwner = Object.assign({}, projectData.get('ownerDN'), { value: user.value ? user.value.displayName : 'anonymous' })
      projectData.set('ownerDN', newOwner)

      debugger

      await addDoc(calcLocationPath.value, calcID.value, {
        calcID: calcID.value,
        projectID: projectID,

        calcTitle: projectData.get('title').value,
        calcDesc: projectData.get('description').value, // to be used for FB filter

        angleInDegrees: settings.angleInDegrees,
        showHiddenRows: settings.showHiddenRows,

        ownerID:    user.value ? user.value.uid : 'anonymous',  // initial save
        ownerDN:    user.value ? user.value.displayName : 'anonymous',
        creatorID:  user.value ? user.value.uid : 'anonymous',  // init. save and will stay unchanged.
        creatorDN:  user.value ? user.value.displayName : 'anonymous',

        projectData: Array.from(projectData, ([key, value]) => ({key, value})),

        // ownerEmail: ownerEmail.value,
        quantities: theCalculation.PQs.map(pq => pq.dataObj),

        imageFilePath: filePath.value, // for single image, obsolete.
        imageUrl: url.value, // for single image, obsolete.
        // imageUrls: imageController.value.imageUrls,
        images: imageController.value.images,
        timeStamp: new Date()
      })

      router.push({ name: 'E3dCalculator', params: { id: calcID.value }})
    }

    /**
     * this was for single image file. Obsolete but keep for reference.
     * It shows how file from input is used to create a local url for showing on UI.
     */
    const fileChanged = e => {
      let selectedFile = e.target.files[0]

      /***
       * https://medium.com/@wangp701/how-to-build-a-drawing-web-app-with-firebase-storage-8811da7d2a48
       * https://www.genuitec.com/upload-files-using-vue-and-firebase
       * https://github.com/Genuitec/article-sources/blob/master/vue-firebase-upload/src/components/Upload.vue
       */
      const url0 = URL.createObjectURL(selectedFile)
      pictureUrl.value = url0

      console.log(`@fileChanged: url0 = ${url0}`) //url0=blob:http://localhost:8080/3cfc081b-d31d-4339-a3e5-3ae38f86cb10
      console.log(`@fileChanged: selectedFile = ${JSON.stringify(selectedFile)}`) // {type: 'image/jpeg', name: 'inclined plane??.jpeg', lastModified: ....}

      if (selectedFile && utils.imageTypes.includes(selectedFile.type)) {
        file.value = selectedFile
        fileError.value = null
      } else {
        file.value = null
        fileError.value = 'Please select an image file (png / jpeg / jpg / gif)'
      }
    }

    async function migrating() {
      // alert('hi')
      await readCalcsData()

      let calc, i = 0, j = 0
      try {
        for (calc of calcsInCloud) {
          console.log(`calc@migrating: ${calc}`)
          /* if (calc.calcID === 'c-20211018.222608952-e3d-064438-5b3959') {
            alert('stop')
          } */
          i++
          await writeCalcsData(calc)
        }
        alert('Migrating done success.')
      } catch (err) {
        console.log('migrating not success.')
        j++
      }

      console.log(`@migrating: i = ${i}, j (not success) = ${j}`)
    }

    async function readCalcsData () { // for migrating
      console.log(`db: ${db}`)

      calcsInCloud.splice(0, calcsInCloud.length)
      // let _calcID
      // let _projectID
      let _calcData, _projectData = new Map()
      libID.value = 'calcs'  // 'Physics-11'

      // let mathCalcData = [];
      await db.collection(libID.value) /* .where("calcDesc", "==", searchTerm.value) */
        .get()
        .then((querySnapshot) => {
          console.log(`querySnapshot.length = ${querySnapshot.docs.length}`)
          querySnapshot.forEach((doc) => {
            /* // to create new calcID and projectID
            _calcID = utilsPub.UUID('c')
            _projectID = utilsPub.UUID('p') */
            const data = doc.data()

            console.log(`doc.id@existing = ${doc.id}`, ' => ', doc.data())

            let owner
            if(Array.isArray(data.images) && (data.images.length)) {
              let imageUrl = data.images[0].urlGlobal
              owner = getOwnerFromImageUrl(imageUrl) // owner = { ownerID, ownerDN, creatorID, creatorDN }
            } else {
              owner = {
                ownerID: 'OLNFxYMdlPUdgXNi0gz9jPYURrq1',
                ownerDN: 'dw',
                creatorID: 'OLNFxYMdlPUdgXNi0gz9jPYURrq1',
                creatorDN: 'dw'
              }
            }

            projectData.clear()

            if (data.projectData.hasOwnProperty('info')) { // for prior v0.2.35
              // transfer existing projectData.info array to new projectData
              /*
              if(doc.id === 'c-20211018.222608952-e3d-064438-5b3959') {
                let res = confirm("pause for info")
                if (res === false) return
              } */

              // migrateProjectData(data.projectData.info) // for prior v0.2.35

              if (projectData.get('date')) {
                // _projectData.ownerID = owner.ownerID
                let createdAt = projectData.get('date')
                projectData.set('createdAt', createdAt)
                projectData.set('updatedAt', createdAt)
                projectData.delete('date')
              }

              if (projectData.get('owner')) {
                let newOwner = Object.assign({}, projectData.get('owner'), { value: owner.ownerDN })
                projectData.set('ownerDN', newOwner)
                projectData.delete('owner')
              }
            } else {
              let ownerPropCount = data.projectData.filter( item => item.key === 'owner' ).length
              data.projectData.forEach(item => {
                projectData.set(item.key, item.value)
                if (item.key === 'visibility' && !item.value.hasOwnProperty('datalist')) {
                  // let newVisibility = Object.assign({}, item.value, { datalist: [] })
                  // let visibilityTemp = projectData0.get('visibility')
                  let visibilityTemp = { type: "datalist", label: "Visibility", value: 'public', datalist: [ 'public', 'private', 'protected' ] }
                  projectData.set(item.key, visibilityTemp)
                }

                if (item.key === 'createdAt') {
                  let createdAt = Object.assign({}, item.value, { label: 'Created At' })
                  projectData.set(item.key, createdAt)
                }

                if (item.key === 'updatedAt') {
                  let updatedAt = Object.assign({}, item.value, { label: 'Updated At' })
                  projectData.set(item.key, updatedAt)
                }

                if (item.key === 'owner' ) {
                  let found = data.projectData.find( item => item.key === 'ownerDN' )
                  if (found) {
                    projectData.set('ownerDN', found.value)
                  } else {
                    let ownerDN = Object.assign({}, item.value, { value: data.ownerDN })
                    projectData.set(item.key, ownerDN)
                  }
                  projectData.delete('owner')
                }

                if (item.key === 'ownerDN' && !item.value.hasOwnProperty('label') ) {
                  let ownerObj = { type: "text", label: "Owner", value: item.value.value, readOnly: 'true' }
                  let ownerObj1 = Object.assign({}, ownerObj)
                  projectData.set(item.key, ownerObj1)
                }
              })
            }

            console.log(`projectData@to be = ${doc.id}`, ' => ', projectData)
            projectData.forEach((value, key) => {console.log(`m[${key}] = ${JSON.stringify(value)}`)})

            // let projectDataArray = Array.from(projectData, ([key, value]) => ({ key, value }))
            _calcData = {
              /**
               * In future migrating, I don't want to change id for existing calcs. In that case,
               * it is better to get previous id and use for new. This will keep old links valid.
               *
               * also i need to retrieve images data and backup properly.
               */
              // calcID: _calcID, // new id
              calcID: data.calcID, // use old id for checking
              projectID: data.projectID,

              calcTitle: data.calcTitle,
              calcDesc: data.calcDesc, // added in addition to above to be used for FB filter

              angleInDegrees: data.angleInDegrees,
              showHiddenRows: data.showHiddenRows,

              ownerID: owner.ownerID,
              ownerDN: owner.ownerDN,
              creatorID: owner.ownerID, // to update
              creatorDN: owner.ownerDN, // to update

              projectData: projectDataArray.value, // JS Map can't be stored in firestore, so convert to array.

              // ownerEmail: ownerEmail.value,
              quantities: data.quantities,

              /* imageFilePath: filePath.value, // for single image, obsolete.
              imageUrl: url.value, // for single image, obsolete.
              imageUrls: imageController.value.imageUrls,*/
              images: data.images ? data.images : [], // for multiple images.
              timeStamp: new Date()
            }

            // const res = addDoc(calcLocationPath.value, _calcID, _calcData)

            calcsInCloud.push(_calcData)
          })

        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })

      console.log(`calcsInCloud@newCalc.readData: ${calcsInCloud.length}`)

      console.log('--end--')
    }

    /**
     * to add image retrieve
     */
    async function writeCalcsData (calc) {
      // return // to skip for now TODO: remove this line
      console.log('--start--')

      if (!isTesting.value) return // run this only when it is in testing mode

      /* let res = confirm("please provide the correct calcs path for migrating. make sure not overwriting calcs. Uncomment the addDoc line properly")
      if (res === false) return */

      // await addDoc('calcs', calc.calcID, calc)  // this is to overwrite existing calcs!!!!!
      // await addDoc(calcLocationPath.value, calc.calcID, calc)
      // await addDoc('calcs_backup_v0_2_35', calc.calcID, calc)
      console.log(`calcID@writeCalcData: ${calc.calcID}`)

      console.log('--end--')
    }

    /**
     * url: calc.urlGlobal
     *      eg:
     *      https://firebasestorage.googleapis.com/v0/b/auto-calc-80237.appspot.com/o/calcs%2Fimages%2Ftier01%2FF5RMptf6uqbq9U9qudbAfhJ0Hpn1%2Fp-20211013.062951812-e3d-04e456-591852%2Fc-20211013.062951813-e3d-006419-595940%2Fimg-1634158541909?alt=media&token=7e2973ff-0fc0-48a9-81f5-ef9ef627a924
     * return owner data { ownerID, ownerDN, creatorID, createrDN }
     */
    const getOwnerFromImageUrl = (url) => {
      let ownerID, ownerDN, creatorID, creatorDN
      let urlParts = url.split('%2F')
      ownerID = urlParts[3]
      switch (ownerID) {
        case 'OLNFxYMdlPUdgXNi0gz9jPYURrq1':
          ownerDN = 'dw'
          creatorID = ownerID
          creatorDN = ownerDN
          break

        case 'F5RMptf6uqbq9U9qudbAfhJ0Hpn1':
          ownerDN = 'holy'
          creatorID = ownerID
          creatorDN = ownerDN
          break

        default:
          ownerID = 'anonymous'
          ownerDN = 'anonymous'
          creatorID = 'anonymous'
          creatorDN = 'anonymous'
      }

      return { ownerID, ownerDN, creatorID, creatorDN }
    }

    /***
     * redundant
     */
    const migrateProjectData = (projData) => {
      let visibilityProp = projData['visibility'].hasOwnProperty('datalist')
      console.log(`visibilityProp@migrateProjectData: ${visibilityProp}`)
    }

    /*** for prior v0.2.35 when projectData contains info prop.
     * info: array of info objects
     */
    const migrateProjectData_R1 = (info) => {
      let newInfo = new Map()
      let projectDataTmp = new Map(projectData0)

      let label
      console.log('projectInfo@migrateProjectInfo start')
      info.forEach(item => {
        label = item.label.replace(/\s+/g, '') // strip spaces in label
        // change label's first char to lower case
        label = label.charAt(0).toLowerCase() + label.substring(1)
        console.log(label, item)
        newInfo.set(label, item)
      })

      console.log('projectData@migrateProjectInfo start')
      projectData0.forEach(item => { console.log(item) })
      projectData.forEach(item => { console.log(item) })
      projectData.clear()  // why projectData0 is cleared as well? is it recovered on next loop step?

      console.log('projectData0@migrateProjectInfo start')
      projectDataTmp.forEach((value, key) => {
        console.log(key, value)
        projectData.set(key, value)
      })

      console.log('newInfo@migrateProjectInfo')
      // https://hackinbits.com/articles/how-to-iterate-a-map-in-javascript---map-part-2
      newInfo.forEach((value, key) => {
        console.log(key, value)
        projectData.set(key, value)
      })

      console.log('projectData@migrateProjectInfo end')
      projectData.forEach((value, key) => { console.log(key, value) })
    }

    const migrateProjectData_R0 = (projectInfo) => {
      // get existing projectInfo and convert to new format.
      let newInfo = {}
      projectInfo.forEach(item => {
        // change label's first char to lower case
        let key = item.label.charAt(0).toLowerCase() + item.label.substring(1)
        Object.defineProperty(newInfo, key, item)
      })

      // convert existing date to new for newInfo
    }

    function addChart() {
      let chart = {
        chartID: 'chart' + (charts.length + 1),
        calculation: theCalculation
      }

      charts.push(chart)
    }

    /**
     * when user triggers pqUnitChanged() or peUnitChanged(), it will trigger unitChangeToUpdateChart, which will active this function.
     * This function checks if there is charts exist, then update chartDateSets. If no charts, then do nothing.
     */
    function updateChartDataSet(pq) {
      debugger
      console.log(`pq@updateChartDataSet: ${pq}`)
      // alert('unit change passed to updateChartDataSet')

      if(charts.length > 0) {
        const _charts = []
        charts.forEach(chart => {
          chart.calculation.rerun()
          _charts.push(chart)
        })
        charts.splice(0, charts.length)
        charts.push(..._charts)
      }
    }

    /**
     * return:
     * - for non-registered user: ''
     * - for registered user: user.id
     */
    const userID = computed(() => {
      user ? user.value.email : 'anonymous'
    })

    /**
     * https://www.codegrepper.com/code-examples/javascript/vue+router+open+link+in+new+tab
     */
    function openLinkInNewTab (route) {
      const routeData = router.resolve(route)
      window.open(routeData.href, '_blank')
    }

    useKeydown([ // can define keyCombos of multiple keyCombos
      { key: 'q', altKey: true, fn: theCalculation.addPqPe },  // keyCombo[0]
      { key: 's', ctrlKey: true, fn: saveCalcData }, // not working yet.
      { key: 'c', ctrlKey: true, altKey: true, fn: clearWorkspace }, // not working yet.
    ])

    watch(user, () => { // TODO: to redo this watch.
      // projectData.set('ownerDN').value = user.value ? user.value.displayName : 'anonymous'
    })

    /* watch(theCalculation, () => {
      if (theCalculation.PQs.length > 1) {
        settings.showProjectInfo = true
      }
    }) */

    return {
      angle, sinX, parseTest, parserTest, parseInput,
      router,
      theCalculation, searchTerm,
      settings,
      user,
      projectID, calcID,
      projectData0, projectData, projectDataArray,
      /* calcDesc, calcTitle, calcCategory, ownerEmail, metaData, keyWords, status, visibility, */
      pq0, //calcData,
      initCalcData,
      clearWorkspace, selectAll,
      switchTooltipOn, switchTooltipOff, autoUnitConversion, unitConversionMode,
      saveCalcData,
      file, fileChanged, fileError, pictureUrl,
      url1,
      imageController,
      calcLocationPath, imageLocationPath,
      charts, addChart,
      isTesting,
      openLinkInNewTab,
      migrating, calcsInCloud,
      PQtest,
      updateChartDataSet
    }
  },

}
</script>

<style src="../styles/components/index.css" scoped></style>
