/* eslint-disable */

/* eslint-disable-next-line no-unused-vars */
const toRad = (deg) => (deg * Math.PI) / 180;
const toDeg = (rad) => (rad * 180 / Math.PI);
const in2mm = (inch) => (inch * 25.4);
const mm2in = (mm) => (mm / 25.4);

/**
 * @param {*} rad - angle in radians per Math Lib
 */
/* const csc = (rad) => 1.0 / Math.sin(rad)
const sec = (rad) => 1.0 / Math.cos(rad)
const cot = (rad) => 1.0 / Math.tan(rad) */

// trigonometry functions for angle in degrees
const sin0 = (deg) => Math.sin(toRad(deg))
const cos0 = (deg) => Math.cos(toRad(deg))
const tan0 = (deg) => Math.tan(toRad(deg))
/* const csc0 = (deg) => 1.0 / sin(toRad(deg))
const sec0 = (deg) => 1.0 / cos(toRad(deg))
const cot0 = (deg) => 1.0 / tan(toRad(deg)) */

const asin0 = (val) => toDeg(Math.asin(val))
const acos0 = (val) => toDeg(Math.acos(val))
const atan0 = (val) => toDeg(Math.atan(val))
const atan20 = (y, x) => toDeg(Math.atan2(y, x))

/**
 * https://www.engineeringtoolbox.com/inches-decimal-equivalents-d_471.html
 * https://www.inchcalculator.com/inch-fraction-calculator/
 *
 * @param {*} foot
 * @param {*} inch
 * @param {*} frac
 * @returns
 * This function is only useful when the PQ's unit is inches.
 * To improve with optional parameters. TODO:
 */
const fracInch = (foot = 0, inch = 0, frac = 0) => (foot * 12 + inch + frac);

/* const PI = ()=> Math.PI;    // PI() = 𝝅 = 3.14159
const exp = () => Math.E;   // exp() = e = 2.71828... */

/** from math.js
 * Calculate the numeric integration of a function
 * @param {Function} f
 * @param {number} start
 * @param {number} end
 * @param {number} [step=0.01] // should use count of steps, default to 100.
 */
 function integrate0(f, start, end, step) {
  let total = 0
  step = step || 0.01
  for (let x = start; x < end; x += step) {
    total += f(x + step / 2) * step
  }
  return total
}

/** from math.js => modified by dw
 * Calculate the numeric integration of a function
 * @param {Function} fn
 * @param {number} x0
 * @param {number} x1
 * @param {number} [n=100] // should use count of steps, default to 100.
 *
 * Note: Integration can generally change dimensions because it is essentially multiplication
 */
 function integrate(fn, x0, x1, n = 100) {
  let total = 0
  let dx = (x1-x0) / n
  for (let x = x0; x < x1; x += dx) { total += fn(x + dx / 2) * dx }
  return total
}

/** from math.js => modified by dw
 * Calculate the numeric integration of a function
 * @param {number[]} numberes
 */
const sum = (...numbers) => numbers.reduce((acc, num) => acc + num)

const isEmptyOrWhitespace = (str) => !str || /^\s*$/.test(str) // by Bito

const clog = s => { console.log(s) }

/**
 * the following returns false:
 * true, false, (), (''), (""), null, undefined,
 * the following returns true:
 * 1, 2, '1', ' 2', ' 2 ', ' 1  2 ' (this parsed to 1, thus still not perfect. TODO:)
 */
// const isNumber = (input) => typeof input === 'string' && !isNaN(input)
const isNumber1 = (input) => (typeof input === 'string' && !isNaN(parseFloat(input)) ) || (typeof input === 'number')

/**
 * This is created by gpt4. And seems to work for all string input which are actually numbers,
 * including scientific notations.
 * GPT4: In this updated regular expression, I added [eE][+-]?\d+ to handle scientific notation.
 * This allows an optional e or E character, followed by an optional plus or minus sign ([+-]),
 * and one or more digits (\d+). Now, numbers like "1.23e-4" will also be considered valid.
 * // Example usage:
 *  console.log(isNumber("42"));  // true
 *  console.log(isNumber("3.14"));  // true
*   console.log(isNumber("1.23e-4"));  // true
*   console.log(isNumber("Hello"));  // false
*   console.log(isNumber("1+2"));  // false
 *
 *  TODO: check the scientific notation vs my expressions.
 */
const isNumber = (input) => /^-?\d+(\.\d+)?([eE][+-]?\d+)?$/.test(input)

const isBoolean = (input) => input === 'true' || input === 'false'
const isBooleanB= (input) => input === true || input === false // does not work as i expect

/**
 * the following returns false:
 * true, false, (), (''), (""), null, undefined, Infinity
 */
const isNumberOrFinite = (input) => !isNaN(parseFloat(input)) && isFinite(input)

const isMultivalueInput = (input) => input.includes('|')

/**
 *
 * @param {*} str
 * Ref: https://dev.to/spukas/everything-wrong-with-javascript-eval-35on
 * as alternative to eval
 */
function parse(script) { // works perfectly!! 20210506
  return (new Function('"use strict";return (' + script + ')'))()
}

// https://forum.freecodecamp.org/t/what-is-the-alternative-of-eval/271451/5
const parser = (...num) => ( num.join(''))

/**
 * padStart(str, length, padChar)
 * @returns string fixed to length
 */
function padStart (val, length=2, padChar='0')  {
  let str = (typeof val === 'string') ? val : val.toString()
  let res = str.padStart(length, padChar)
  // console.log(`@padStart: val = ${val}, res = ${res}`)
  return res
}

/**
 *
 * @param {*}
 * @returns a string for current date and time(is getMilliseconds)
 * eg: 2021512.1859436
 */
const dateTimeString = () => {
  const dt = new Date()

  const yy = dt.getUTCFullYear()
  const mm = dt.getUTCMonth() + 1 // getMonth() gives 0 for Jan, 1 for Feb, etc.
  const dd = dt.getUTCDate()
  const h = dt.getUTCHours()
  const m = dt.getMinutes()
  const s = dt.getSeconds()
  const ms = dt.getMilliseconds()
  // const s = dt.getMilliseconds().toString().padStart(3, '0')

  /* console.log(`res:
    ${padStart(yy, 4)}
    ${padStart(mm)}
    ${padStart(dd)}.
    ${padStart(h)}
    ${padStart(m)}
    ${padStart(s)}
    ${padStart(ms, 3)}`) */

  return `${padStart(yy, 4)}${padStart(mm)}${padStart(dd)}.${padStart(h)}${padStart(m)}${padStart(s)}${padStart(ms, 3)}`
}

/**
 *  UUID with prefix string
 */
const UUID = (prefix = 'd') => {
  // let dt = new Date().getTime()
  let dt = dateTimeString()

  // uuid.length = 36
  const uuid = `${prefix}-${dt}-e3d-xxx4xx-5xxyxx`.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16)
  })

  return uuid
}

/**
 * @param {Map} m a JS Map
 * @returns {Array} an array of objects with key and value objects
 */
 const MapToObjArray = (m) => {
  return Array.from(m, ([key, value]) => ({key, value}))
}

/***
 * @param {Array} arr a JS Array of key-value objects
 * @returns {Map} a JS Map
 */
const ObjArrayToMap = (arr) => {
  let map = new Map()
  arr.forEach(item => map.set(item.key, item.value))
  return map
}

/***
 * https://stackoverflow.com/questions/2257993/how-to-display-all-methods-of-an-object
 * get all methods from an Object, like Math
 */
const getAllMethods = obj => Object.getOwnPropertyNames(obj)
  .filter((prop) => typeof obj[prop] === 'function')

const getAllProperties = obj => Object.getOwnPropertyNames(obj)
  .filter((prop) => typeof obj[prop] !== 'function')

export {
  toRad, toDeg, in2mm, mm2in,
  // cot, sec, csc,
  sin0, cos0, tan0, // cot0, sec0, csc0,
  asin0, acos0, atan0, atan20,
  //acot0, asec0, acsc0, // not much practical use.
  fracInch,
  // integrate, integrate0,
  sum,
  clog,
  isEmptyOrWhitespace,
  isNumber, isNumberOrFinite, isBoolean,
  isMultivalueInput,
  parse, parser,
  // PI, exp,
  UUID, dateTimeString, padStart,
  MapToObjArray, ObjArrayToMap,
  getAllMethods, getAllProperties
}
