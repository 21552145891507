<template>
  <div class="container my-1">
    <div class="flex flex-col justify-center m-2 p-2 bg-gray-200 rounded-lg overflow-hidden
      shadow-lg cursor-pointer"
      @click="$emit('calculatorCardClicked')">
      <div class="flex-col justify-center w-full rounded">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            <slot name="calcIndex"></slot>
            {{ calc.calcTitle }}
          </div>

          <div v-if="calc.coverImage">
            <img class="w-full object-contain"
              :src="calc.coverImage.urlGlobal" alt="Placeholder Image">
          </div>

          <p class="text-gray-700 text-base my-2"> Description: {{ calc.calcDesc }} </p>
          <!-- <p>Note: <u> {{ calc.calcNote }} </u></p> -->

          <!-- <slot name="calcLink"></slot> -->

          <slot name="header"></slot>

          <!-- <p>Creator: {{ calc.creatorDN }} </p> -->
          <p>By: {{ calc.ownerDN }} </p> <!-- to show owner -->
          <p>Updated: {{ calc.updatedAt }} </p> <!-- to show owner -->
        </div>
        <div class="px-6 pt-4 pb-2" v-if="false">
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
        </div>
      </div>

      <!-- calc: {{ JSON.stringify(calc) }} -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalculatorCard',
  emits: ['calculatorCardClicked'],
  props: {
    calc: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.calc-card {
  padding: 20px;
  width: 350px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.calc-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: aquamarine;
}

.calc-link {
  color: #2c3e50;
  text-decoration: none;
}

.image {
  width: 60%;
}
</style>
