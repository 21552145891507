/** from vueMastery.com
 *  https://github.com/Code-Pop/build-gmail-clone-with-vue-3/blob/course-end/src/composables/use-keydown.js
 */

import { onBeforeUnmount } from 'vue'

const useKeydown = (keyCombos) => { // keyCombos: {[0: {key: "q", altKey: true, fn: ƒ}]}
  const onKeydown = (e) => {
    const kc = keyCombos.find(kc =>
      (kc.altKey === e.altKey) &&
      // (kc.ctrlKey === e.ctrlKey) &&
      (kc.key === e.key)
    )

    if (kc) kc.fn()
  }

  window.addEventListener('keydown', onKeydown)
  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeydown)
  })
}

export default useKeydown
