<!-- template for the modal component -->
<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask flex items-center">
      <div class="modal-wrapper flex justify-center mx-auto my-6 bg-center w-9/12">
        <div class="modal-container flex flex-col items-center rounded mx-4">

          <div class="modal-header">
            <slot name="headerInside">
              default header inside
            </slot>
          </div>

          <div class="modal-body w-10/12 my-3">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer relative">
            <slot name="footer">
              <button class="roundBtn absolute" @click.stop="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { ref } from '@vue/reactivity'
export default {
  setup () {}
}
</script>

<style src="../styles/components/index.css" scoped></style>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  /* display: table-cell; */
  vertical-align: middle;
}

.modal-container {
  /* width: 660px; */
  width: 80%;
  height: 200px;
  margin: 10px;
  padding: 10px;
  background-color: lightgray;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  /* width: 80%; */
}

.modal-body {
  margin: 6px;
}

.modal-footer {
  margin-bottom: 0;
  color: #42b983;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
