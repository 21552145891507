/**
 * 20230930: added "nautical mile" and "knot".
 * 20230909: added in flow rate "cm³/s", "cm³/min" for being practical units.
 * 20230729: Added Energy Density per volume and mass. They are actually the same as FUEL_HEAT_VALUE.
 *           Removed this. As they are redundant.
 * 20230515: Added TNT Equivalent
 *           Discovered TNT uses cal(TH) = 4.184J.
 *           Added CALORIE_IT_J & CALORIE_TH_J, and some typical associated units.
 * 20230512: Modified ton(US/long) to ton(UK-Imperial/lon)
 * 20220922: Addin currency category
 * 20220727: should add category symbol so it can be used in unit converter category name for convention. TODO:
 * 20220726: found that '^' for exponent can not be used like "INCH_METER^3". TODO:
 * 20220723: standardized gallon to 'gal' per https://en.wikipedia.org/wiki/Gallon, that 'Gal' is CGS unit for acceleration.
 * 20220704: added some in ACCELERATION
 * 20220626: to add angular acceleration '[deg/s2, rad/s2]'.  => Done
 * 20220703: to add load/length, for load per linear length in structual load calcs. => Done.
 * 20220415: changed angle base unit to [m/m]
 *
 * // 20211207: borrowed from '18000 general-calcs'
 * 20220206: Added flow rate: m³/min, L/min
 *
 * 20190623:
 *    - Uom.unit needs to be unique among all Uoms. Uom.category will be removed.
 * 20190525: started dimensional analysis.
 *    - Added dimension.js
 *    - Currently, need to make sure that each Uom has unique unit so that when a MMT is
 *      constructed in a calculation, its unit category can be searched correctly.
 *
 * 20190119: moved rpm from angular velocity to frequency category to suit ScrewConveyor.vue better.

 * 20181212:
 *    - https://en.wikipedia.org/wiki/Conversion_of_units
 *    - https://en.wikipedia.org/wiki/Dimensional_analysis
 *    - added Torr.
 *    - updated cd, cp per wikipedia.
 *    - Should include property 'symbol' in the Uom object definition.
 * 20181209:
      - added PolarMomentOfInertia (unit: 'm⁴') category
      - added GPa for pressure
 * 20181110:
      - changed angle base unit to 'radian' => further update 20220415
      - found unit 's' caused error because previously it was 's[second]' and it was base unit. So two options:
        (1) create both 's' and 's[second]' in this file, thus new time measurement can be defined with either format. However, this will make both 's' and 's[second]' shown in the dropdown on UI
        (2) everytime a measurement is defined, make sure use the units the same from this file. However can cause trouble if 's' is not in this file, while I will define time as 's' very naturely, and such errors can be hard to debug.
 * 20181102:
      - started to create unit category LINEAR_THERMAL_EXPANSION_COEFFICIENT, and some units for it.
      - updated baseUnit of temperature to °K, so that in future, unit conversion transparency can be improved in calcs, not just UI.
      - updated ./README.md to record idea to refactor uom.data.js
      - some reformating in this file.
 * 20180203: found the volume flow rate L/s was L/h. Corrected.
 * 20171018: added in LENGTH: ft-in
 * 20171014: migrated to vuejs
 * Replaced previous UnitSystem.Metric and UnitSystem.Imperial with strings
 *
 * Refs:
 * 1. https://physics.nist.gov/cuu/Units/units.html ***common units***
 * 2. https://www.unitconverters.net/luminous-intensity-converter.html
 * 3. https://www.khanacademy.org/science/electrical-engineering/introduction-to-ee/intro-to-ee/a/ee-standard-electrical-units
 * 4. https://www.electronics-tutorials.ws/dccircuits/dcp_3.html#:~:text=The%20standard%20units%20of%20electrical,and%20Ohm%20%5B%20%CE%A9%20%5D%20respectively.
 * 5. Electrical and Electronics Measurement & Instrumentation
 */

import {
  POUNDMASS_KG,
  GRAVITY_SI,
  MILE_FOOT,
  INCH_METER,
  FOOT_METER,
  NM_METER,
  ACRE_FOOT2,
  SLUG_KG,
  GALLON_UK_IMPERIAL_LITRE,
  GALLON_US_LIQUID_IN3,
  GALLON_US_DRY_IN3,
  PINT_GALLON,
  OUNCE_FLUID_PINT_UK_IMPERIAL,
  OUNCE_FLUID_PINT_US_LIQUID,
  BUSHEL_GALLON_UK_IMPERIAL,
  BUSHEL_GALLON_US_DRY,
  BTU_J,
  CALORIE_IT_J,
  CALORIE_TH_J,
  HP_W,
  ATM_PA,
  MMHG_PA,
  TORR_ATM,
  F_K_C0,
  MOLE,
  COULOMB_ELECTRON,
  J_EV,
  BYTE_BIT
} from "./uom.constants";

import Dimension from "./dimension";

export const UomCategories = [
  // SI base units
  /**
   * https://en.wikipedia.org/wiki/SI_base_unit
   * https://en.wikipedia.org/wiki/International_System_of_Quantities
   */
  {
    id: 900,
    name: "UNITLESS",
    baseUnit: "ea",
    description: "Unitless",
    quantity: "Unitless",
    units: ["ea"],
    /**
     * For each field, default to 0 if not specified.
     * */
    dimension: new Dimension({ ea: 0 }),
    footprints: {
      // obsolete.
      ea: 0, // const, unitless
      m: 0, // length
      kg: 0, // mass
      s: 0, // time
      A: 0, // electric current
      K: 0, // thermodynamic temperature
      mol: 0, // amount of substance
      cd: 0, // luminous intensity
    },
  },
  {
    id: 901,
    name: "LENGTH",
    baseUnit: "m",
    description: "Length",
    quantity: "Length",
    units: [
      "m",
      "nano[nm]",
      "µm",
      "micron",
      "mm",
      "cm",
      "dm",
      "km",
      "in",
      "thou[mil, or thousandth of an inch]", // a thousandth is called a thou per wiki
      // 'ft-in',
      "ft",
      "yd",
      "mile",
      "NM[M, nmi, or nautical mile]",
    ],
    /**
     * For each field, default to 0 if not specified.
     * */
    dimension: new Dimension({ m: 1 }),
    footprints: {
      // obsolete.
      m: 1, // length
      kg: 0, // mass
      s: 0, // time
      A: 0, // electric current
      K: 0, // thermodynamic temperature
      mol: 0, // amount of substance
      cd: 0, // luminous intensity
    },
  },
  {
    id: 902,
    name: "MASS",
    baseUnit: "kg",
    description: "Mass",
    quantity: "Mass",
    expressionInSI: "kg",
    units: [
      "kg",
      "g",
      "mg",
      "µg",
      "oz",
      "lbm",
      "slug",
      "ton(US/short)",
      "ton(UK-Imperial/long)",
      "tonne",
    ],
    dimension: new Dimension({ kg: 1 }),
  },
  {
    id: 903,
    name: "TIME",
    baseUnit: "s[second]",
    description: "Time",
    quantity: "Time",
    expressionInSI: "s",
    units: ["s[second]", "s", "minute", "h", "h[hour]", "day", "week"],
    dimension: new Dimension({ s: 1 }),
  },
  {
    id: 904,
    name: "ELECTRIC_CURRENT",
    baseUnit: "A",
    description: "Electric Current",
    quantity: "Electric Current",
    expressionInSI: "A",
    units: ["A", "Ampere[amp]", "mA", "µA", "kA", "MA", "esu/s"],
    dimension: new Dimension({ A: 1 }),
  },
  {
    id: 905,
    name: "TEMPERATURE",
    baseUnit: "°K",
    description: "Temperature",
    quantity: "Temperature",
    expressionInSI: "K",
    units: ["°K", "°C", "°F", "°Ra"],
    dimension: new Dimension({ K: 1 }),
  },
  {
    id: 906,
    name: "AMOUNT_OF_SUBSTANCE",
    baseUnit: "mol",
    description: "Amount of Substance",
    quantity: "Amount of Substance",
    expressionInSI: "mol",
    units: ["mol", "mmol", "kmol"],
    dimension: new Dimension({ mol: 1 }),
  },
  {
    id: 907,
    name: "LUMINOUS_INTENSITY",
    baseUnit: "cd[candela]",
    description: "Luminous Intensity",
    quantity: "Luminous Intensity",
    expressionInSI: "cd",
    units: [
      "cd[candela]",
      "lm/sr",
      "cp[candlepower]",
      "cp[candlepower(before 1948)]",
    ],
    dimension: new Dimension({ cd: 1 }),
  },

  // SI derived units
  /**
   * To update per https://en.wikipedia.org/wiki/SI_derived_unit
   */
  {
    id: 100,
    name: "ACCELERATION",
    baseUnit: "m/s²",
    description: "Acceleration",
    quantity: "Acceleration",
    expressionInSI: "m⋅s−2",
    units: ["m/s²", "m/min²", "mm/s²", "ft/s²", "ft/min²", "in/s²"],
    dimension: new Dimension({ kg: 1, s: -2 }),
  },
  {
    id: 1001,
    name: "GRAVITATIONAL CONSTANT",
    baseUnit: "m³/kg/s²",
    description: "Gravitational Constant",
    quantity: "Gravitational Constant",
    expressionInSI: "kg-1⋅m3⋅s-2",
    units: ['m³/kg/s²', "N⋅m²/kg²", 'dyn⋅cm²/g²'],
    dimension: new Dimension({ kg: -1, m: 3, s: -2 }),
  },
  {
    /**
     * 20220415:
     * // radians? => Radians is proportional to unitless number with dimension of [m/m]
     * Angular velocity is the rate of change (of angle), it has the same dimension with frequency.
     * Today added 'rpm' and 'rev/s' to VELOCITY_ANGULAR category. So this is another case of the
     * same unit apprears in multiple categories. [TODO: check]
     *
     * // Should put rpm, rev/s in one category only. Otherwise, it is conflicting.
     */
    id: 1010,
    name: "VELOCITY_ANGULAR",
    baseUnit: "rad/s",
    description: "Velocity (Angular)",
    quantity: "Velocity Angular",
    expressionInSI: "s-1",
    units: [
      "rad/s",
      "rad/min",
      "rad/h",
      "deg°/s",
      "deg°/min",
      "deg°/h",
      "rpm[rev/min]",
      "rev/s",
    ],
    dimension: new Dimension({ s: -1 }),
  },
  {
    id: 1011,
    name: "ACCELERATION_ANGULAR",
    baseUnit: "rad/s²",
    description: "Acceleration (Angular)",
    quantity: "Acceleration Angular",
    expressionInSI: "s-2",
    units: ["rad/s²", "rad/min²", "deg°/s²", "deg°/min²"],
    dimension: new Dimension({ s: -2 }),
  },
  {
    id: 102,
    name: "ILLUMINANCE",
    baseUnit: "lux",
    description: "Illuminance",
    quantity: "Illuminance",
    expressionInSI: "cd⋅m−2",
    units: [
      "lux",
      "lumen/m²",
      "lm/m²",
      "meter-candle",
      "phot",
      "foot-candle",
      "fc",
      "lumen/ft²",
      "lm/ft²",
    ],
    dimension: new Dimension({ m: -2, cd: 1 }),
  },
  {
    id: 103,
    name: "FREQUENCY",
    baseUnit: "Hz",
    description: "Frequency",
    quantity: "Frequency",
    expressionInSI: " ",
    units: ["Hz", "1/s", "1/min", "rpm", "1/hour", "mHz", "kHz", "MHz", "GHz"],
    dimension: new Dimension({ s: -1 }),
  },
  {
    id: 104,
    name: "AREA",
    baseUnit: "m²",
    description: "Area",
    quantity: "Area",
    expressionInSI: " ",
    units: [
      "m²",
      "µm²",
      "mm²",
      "cm²",
      "km²",
      "hectare",
      "in²",
      "ft²",
      "yd²",
      "acre",
      "mile²",
    ],
    dimension: new Dimension({ m: 2 }),
  },
  {
    id: 105,
    name: "VOLUME / SECTION MODULUS",
    baseUnit: "m³",
    description: "Volume / Section Modulus",
    quantity: "Volume / Section Modulus",
    expressionInSI: " ",
    units: [
      "m³",
      "µm³",
      "mm³",
      "cm³",
      "L",
      "mL",
      "km³",
      "in³",
      "ft³",
      "yd³",
      "gal(UK-imperial)",
      "gal(US-liquid)",
      "gal(US-dry)",
      "pint(UK-imperial)",
      "pint(US-liquid)",
      "pint(US-dry)",
      "fluid ounce(UK-imperial)",
      "fluid ounce(US-liquid)",
      "bushel(UK-imperial)",
      "bushel(US-dry)",
    ],
    dimension: new Dimension({ m: 3 }),
  },

  {
    // https://en.wikipedia.org/wiki/Unicode_subscripts_and_superscripts for 'm⁴'
    id: 106,
    name: "POLAR_MOMENT_OF_INERTIA",
    baseUnit: "m⁴",
    description: "Polar Moment of Inertia",
    quantity: "Polar Moment of Inertia",
    expressionInSI: "m4",
    units: ["m⁴", "mm⁴", "cm⁴", "in⁴", "ft⁴"],
    dimension: new Dimension({ m: 4 }),
  },

  { // https://en.wikipedia.org/wiki/Moment_of_inertia. This is different from Roark's.
    id: 1060,
    name: "MOMENT_OF_INERTIA",
    baseUnit: "kg⋅m²",
    description: "Moment of Inertia",
    quantity: "Moment of Inertia",
    expressionInSI: "kg⋅m2",
    units: ["kg⋅m²", "g⋅cm²", "g⋅mm²", "lbm⋅ft²", "lbm⋅in²", "oz⋅in²"],
    dimension: new Dimension({ kg: 1, m: 2 }),
  },

  {
    id: 107,
    name: "FORCE",
    baseUnit: "N",
    description: "Force",
    quantity: "Force",
    expressionInSI: "kg⋅m/s2",
    units: ["N", "kN", "MN", "kgf", "lb", "lbf", "kip[kilo pound]"],
    dimension: new Dimension({ kg: 1, m: 1, s: -2 }),
  },
  {
    id: 108,
    name: "ANGLE",
    baseUnit: "deg°",
    description: "Angle",
    quantity: "Angle",
    expressionInSI: "1",
    units: ["radian", "rad", "deg°", "revolution"],
    dimension: new Dimension({ ea: 1 }),
  },
  {
    id: 1081,
    name: "SOLID_ANGLE",
    baseUnit: "sr[steradian]",
    description: "Solid Angle",
    quantity: "Solid Angle",
    expressionInSI: "m²/m²",
    units: ["sr[steradian]", "m²/m²"],
    dimension: new Dimension({ ea: 1 }),
  },
  {
    id: 1090,
    name: "BMI/BALLISTIC_COEFFICIENT",
    baseUnit: "kg/m²",
    description: "BMI-Body Mass Index/Ballistic Coefficient",
    quantity: "BMI-Body Mass Index/Ballistic Coefficient",
    expressionInSI: "kg/m²",
    units: [
      "kg/m²",
      "g/cm²",
      "g/mm²",
      "tonne/m²",
      "lbm/yd²",
      "lbm/ft²",
      "lbm/in²",
      "oz/yd²",
      "oz/ft²",
      "oz/in²",
    ],
    dimension: new Dimension({ kg: 1, m: -2 }),
  },
  {
    id: 1091,
    name: "DENSITY",
    baseUnit: "kg/m³",
    description: "Density",
    quantity: "Density",
    expressionInSI: "kg/m³",
    units: [
      "kg/m³",
      "kg/L",
      "g/cm³",
      "g/mm³",
      "tonne/m³",
      "lbm/yd³",
      "lbm/ft³",
      "lbm/in³",
      "lbm/gal(US)",
      "oz/yd³",
      "oz/ft³",
      "oz/in³",
      "oz/gal(US)",
    ],
    dimension: new Dimension({ kg: 1, m: -3 }),
  },
  {
    id: 110,
    name: "ENERGY",
    baseUnit: "J[Joule]",
    description: "Energy",
    quantity: "Energy",
    expressionInSI: "kg⋅m2⋅s−2",
    units: [
      "J[Joule]",
      "kJ",
      "MJ",
      "GJ",
      "TNT Equivalent[kiloton]",
      "Btu",
      "MM Btu",
      "calorie(IT)",      // 4.1868J
      "kilo-calorie(IT)",
      "calorie(TH)",      // 4.184J
      "kilo-calorie(TH)",
      "HP Hour",
      "Wh",
      "kWh",
      "eV[electron-volt]",
    ],
    dimension: new Dimension({ kg: 1, m: 2, s: -2 }),
  },
  {
    id: 111,
    name: "POWER",
    baseUnit: "W",
    description: "Power",
    quantity: "Power",
    expressionInSI: "kg⋅m2⋅s−3",
    units: [
      "W[watt]",
      "kW",
      "MW",
      "GW[Gigawatt]",
      "Btu/h",
      "Btu/minute",
      "MM Btu/h",
      "calorie(IT)/second",
      "calorie(IT)/minute",
      "kilo-calorie(IT)/hour",
      "calorie(TH)/second",
      "calorie(TH)/minute",
      "kilo-calorie(TH)/hour",
      "lb-ft/second",
      "lb-ft/minute",
      "HP",
      "J/second",
      "J/minute",
      "J/h",
      "MJ/h",
      "kJ/minute",
    ],
    dimension: new Dimension({ kg: 1, m: 2, s: -3 }),
  },
  {
    id: 112,
    name: "TORQUE_OR_MOMENT_OF_FORCE",
    baseUnit: "Nm",
    description: "Torque / Moment of Force",
    quantity: "Torque, Moment of Force",
    expressionInSI: "m2⋅kg⋅s−2",
    units: ["Nm", "kNm", "lb-in", "kip-in", "lb-ft", "oz-in", "oz-ft"],
    dimension: new Dimension({ kg: 1, m: 2, s: -2 }),
  },
  {
    id: 1130,
    name: "PRESSURE/STRESS",
    baseUnit: "Pa",
    description: "Pressure/Stress",
    quantity: "Pressure/Stress",
    expressionInSI: "kg⋅m-1⋅s−2",
    units: [
      "Pa",
      "Pascal",
      "hPa",
      "kPa",
      "MPa",
      "GPa",
      "bar",
      "mbar",
      "atm",
      "mm HG",
      "cm HG",
      "in HG",
      "mm W.C.",
      "m W.C.",
      "in W.C.",
      "psf",
      "psi",
      "ksi",
      "ksi[kip/in²]",
      "Torr",
      "mTorr",
    ],
    dimension: new Dimension({ kg: 1, m: -1, s: -2 }),
  },
  {
    id: 1131,
    name: "PRESSURE DROP PER UNIT LENGTH",
    baseUnit: "Pa/m",
    description: "Pressure Drop per Unit Length",
    quantity: "Pressure Drop per Unit Length",
    expressionInSI: "kg⋅m-2⋅s−2",
    units: [
      "Pa/m",
      "Pascal/m",
      "kPa/m",
      "MPa/km",
      "bar/m",
      "mbar/mm",
      "atm/m",
      "mm HG/m",
      "in HG/ft",
      "mm W.C./m",
      "m W.C./m",
      "in W.C./ft",
      "in W.C./100 ft",
      "psf/in",
      "psi/ft",
      "ksi/ft",
      "Torr/ft",
      "mTorr/m",
    ],
    dimension: new Dimension({ kg: 1, m: -2, s: -2 }),
  },
  /* {
    id: 1131A,
    name: 'STRESS',
    baseUnit: 'Pa',
    description: 'Stress',
    quantity: 'Stress',
    expressionInSI: 'kg⋅m−1⋅s−2',
    units: [
      'Pa',
      'Pascal',
      'hPa',
      'kPa',
      'MPa',
      'GPa',
      'bar',
      'mbar',
      'atm',
      'mm HG',
      'cm HG',
      'in HG',
      'mm W.C.',
      'm W.C.',
      'in W.C.',
      'psf',
      'psi',
      'ksi[kip/in²]',
      'Torr',
      'mTorr'
    ],
    dimension: new Dimension({ kg: 1, m: -1, s: -2 })
  }, */
  {
    id: 1132,
    name: "UNIFORMLY DISTRIBUTED (UNIFORM LINE) LOAD | STIFFNESS OF LINEAR SPRING",
    baseUnit: "N/m",
    description: "Uniformly Distributed (Uniform Line) Load | Stiffness of Linear Spring",
    quantity: "Uniformly Distributed (Uniform Line) Load | Stiffness of Linear Spring",
    expressionInSI: "kg⋅s−2",
    units: ["N/m", "N/mm", "kN/m", "lb/ft", "kips/ft", "lb/in"],
    dimension: new Dimension({ kg: 1, s: -2 }),
  },
  { // Roark's Formulas
    id: 1133,
    name: "STIFFNESS OF ROTARY/TORSIONAL SPRING",
    baseUnit: "N⋅m/rad",
    description: "Stiffness of Rotary Spring",
    quantity: "Stiffness of Rotary Spring",
    expressionInSI: "kg⋅m2⋅s−2",
    units: [
      "N⋅m/rad", "N⋅m/deg°", "N⋅mm/rad", "N⋅mm/deg°",
      "lb-ft/rad", "lb-ft/deg°", "lb-in/rad", "lb-in/deg°"
    ],
    dimension: new Dimension({ kg: 1, m: 2, s: -2 }),
  },
  {
    id: 114,
    name: "FLOW_RATE_VOLUME",
    baseUnit: "m³/s",
    description: "Flow Rate (Volume)",
    quantity: "Flow Rate (Volume)",
    expressionInSI: "m³/s",
    units: [
      "m³/s",
      "m³/min",
      "m³/h",
      "L/s",
      "L/min",
      "L/h",
      "cm³/s",
      "cm³/min",
      "in³/s",
      "in³/min",
      "ft³/s",
      "CFM",
      "CFM[ft³/min]",
      "CFH",
      "CFH[ft³/h]",
      "yd³/min",
      "yd³/h",
      "gpm(US)",
      "bushel(UK-imperial)/h",
      "bushel(US)/h",
    ],
    dimension: new Dimension({ m: 3, s: -1 }),
  },
  {
    id: 115,
    name: "FLOW_RATE_MASS",
    baseUnit: "kg/s",
    description: "Flow Rate (Mass)",
    quantity: "Flow Rate (Mass)",
    expressionInSI: "kg/s",
    units: [
      "kg/s",
      "kg/min",
      "kg/h",
      "g/s",
      "g/min",
      "g/h",
      "slug/s",
      "slug/min",
      "slug/h",
      "lbm/s",
      "lbm/min",
      "lbm/h",
      "oz/s",
      "oz/min",
      "oz/h",
      "tonne/h",
      "short ton/h",
      "long ton/h",
    ],
    dimension: new Dimension({ kg: 1, s: -1 }),
  },

  {
    id: 117,
    name: "SPEED_OR_VELOCITY_LINEAR",
    baseUnit: "m/s",
    description: "Speed / Velocity (Linear)",
    quantity: "Speed / Velocity (Linear)",
    expressionInSI: "m/s",
    units: [
      "m/s",
      "mm/s",
      "km/s",
      "mm/min",
      "m/min",
      "km/min",
      "mm/h",
      "m/h",
      "km/h",
      "in/s",
      "fps",
      "fps[ft/second]",
      "fpm", // clear version
      "fpm[ft/minute]", // redundant version
      "mph[mile/h]",
      "knot[kn, kt, or nautical mile/h]",
    ],
    dimension: new Dimension({ m: 1, s: -1 }),
  },

  {
    id: 119,
    name: "FUEL_HEAT_VALUE_VOLUME", // the same as ENERGY_DENSITY/VOLUMETRIC
    baseUnit: "J/m³",
    description: "Fuel Heat Value (Volume)",
    quantity: "Fuel Heat Value (Volume)",
    expressionInSI: "kg⋅m-1⋅s−2",
    units: [
      "J/m³",
      "J/L",
      "kJ/L",
      "kJ/m³",
      "MJ/m³",
      "GJ/m³",
      "cal(IT)/L", // 4.1868J
      "kcal(IT)/m³",
      "cal(TH)/L", // 4.184J
      "kcal(TH)/m³",
      "Wh/L",
      "Wh/m³",
      "kWh/m³",
      "HP Hour/ft³",
      "Btu/gal(US)",
      "Btu/ft³",
      "MM Btu/ft³",
    ],
    dimension: new Dimension({ kg: 1, m: -1, s: -2 }),
  },
  {
    id: 120,
    name: "FUEL_HEAT_VALUE_MASS", // the same as ENERGY_DENSITY/GRAVIMETRIC
    baseUnit: "J/kg",
    description: "Fuel Heat Value (Mass)",
    quantity: "Fuel Heat Value (Mass)",
    expressionInSI: "m2⋅s−2",
    units: [
      "J/kg",
      "J/g",
      "kJ/kg",
      "MJ/kg",
      "GJ/kg",
      "cal(IT)/g",
      "kcal(IT)/kg",
      "cal(TH)/g",
      "kcal(TH)/kg",
      "Wh/g",
      "Wh/kg",
      "kWh/kg",
      "HP Hour/lbm",
      "Btu/lbm",
      "MM Btu/lbm",
      "Btu/ton(short)",
    ],
    dimension: new Dimension({ m: 2, s: -2 }),
  },

  {
    id: 121,
    name: "MASS_HEAT_CAPACITY",
    baseUnit: "J/(kg·°K)",
    description: "Mass Heat Capacity",
    quantity: "Mass Heat Capacity",
    expressionInSI: "m2⋅s−2⋅K-1",
    units: ["J/(kg·°K)", "J/(g·°K)", "Btu/(lbm·°F)"],
    dimension: new Dimension({ m: 2, s: -2, K: -1 }),
  },
  {
    id: 122,
    name: "VISCOSITY_ABSOLUTE_DYNAMIC",
    baseUnit: "PI[Pa·s]",
    description: "Viscosity - Absolute/Dynamic(μ)",
    quantity: "Viscosity - Absolute/Dynamic(μ)",
    expressionInSI: "m−1⋅kg⋅s−1",
    units: [
      "PI[Pa·s]",
      "N·s/m²",
      "kg/m/s",
      "P[Poise]",
      "cP",
      "lb·s/ft²",
      "lbm/ft/s",
    ],
    dimension: new Dimension({ kg: 1, m: -1, s: -1 }),
  },
  {
    // https://www.coleparmer.com/tech-article/viscosity-conversion-factors TODO:
    id: 123,
    name: "VISCOSITY_KINEMATIC",
    baseUnit: "m²/s",
    description: "Viscosity - Kinematic",
    quantity: "Viscosity - Kinematic",
    expressionInSI: "m2⋅s−1",
    units: [
      "m²/s",
      "m²/min",
      "m²/hr",
      "St[cm²/s]",
      "Stoke",
      "cm²/min",
      "cm²/hr",
      "cSt[mm²/s]",
      "mm²/min",
      "mm²/hr",
      "ft²/s",
      "ft²/min",
      "ft²/hr",
      "in²/s",
      "in²/min",
      "in²/hr",
    ],
    dimension: new Dimension({ m: 2, s: -1 }),
  },
  {
    id: 1240,
    name: "LINEAR_THERMAL_EXPANSION_COEFFICIENT",
    baseUnit: "1/°K",
    description: "Linear Thermal Expansion Coefficient",
    quantity: "Linear Thermal Expansion Coefficient",
    expressionInSI: "K-1",
    units: ["1/°K", "1/°C", "1/°F", "1/°Ra"],
    dimension: new Dimension({ K: -1 }),
  },
  {
    id: 1241,
    name: "THERMAL_CONDUCTIVITY",
    baseUnit: "W/(m⋅°K)",
    description: "Thermal Conductivity",
    quantity: "Thermal Conductivity",
    expressionInSI: "kg⋅m⋅s−3⋅K-1",
    units: ["W/(m⋅°K)", "W/(cm⋅°K)", "BTU/(h⋅ft⋅°F)", "BTU/(h⋅in⋅°F)"], // to add more TODO:
    dimension: new Dimension({ kg: 1, m: 1, s: -3, K: -1 }),
  },
  {
    id: 125,
    name: "ELECTRICAL_CHARGE", // BATTERY_CAPACITY
    baseUnit: "C[coulomb]",
    description: "Electrical Charge",
    quantity: "Electrical Charge",
    expressionInSI: "A⋅s",
    units: [
      // 'A⋅s',
      "C[coulomb]",
      "Ah",
      "ampere-hour",
      "mAh",
      "e[electron]",
      "faraday",
    ],
    dimension: new Dimension({ A: 1, s: 1 }),
  },
  {
    id: 126,
    name: "ELECTRICAL_POTENTIAL_DIFFERENCE(VOLTAGE)",
    baseUnit: "V[volt]",
    description: "Electrical Potential Difference (Voltage)",
    quantity: "Electrical Potential Difference (Voltage)",
    expressionInSI: "kg⋅m2⋅s−3⋅A−1",
    units: ["V[volt]", "µV", "mV", "kV", "MV", "joule/coulomb"],
    dimension: new Dimension({ kg: 1, m: 2, s: -3, A: -1 }),
  },
  {
    // https://en.wikipedia.org/wiki/SI_electromagnetism_units
    // https://www.khanacademy.org/science/electrical-engineering/introduction-to-ee/intro-to-ee/a/ee-standard-electrical-units
    id: 127,
    name: "ELECTRICAL_RESISTANCE(IMPEDANCE)",
    baseUnit: "Ω[Ohm]",
    description: "Electrical Resistance(Impedance)",
    quantity: "Electrical Resistance(Impedance)",
    expressionInSI: "kg⋅m2⋅s−3⋅A−2",
    units: ["Ω[Ohm]", "µΩ", "mΩ", "kΩ", "MΩ"],
    dimension: new Dimension({ kg: 1, m: 2, s: -3, A: -2 }),
  },
  {
    id: 128,
    name: "ELECTRICAL_CAPACITANCE",
    baseUnit: "F[farad]",
    description: "Electrical Capacitance",
    quantity: "Electrical Capacitance",
    expressionInSI: " kg−1⋅m−2⋅s4⋅A2",
    units: ["F[farad]", "pF", "nF", "µF", "mF"],
    dimension: new Dimension({ kg: -1, m: -2, s: 4, A: 2 }),
  },
  {
    id: 129,
    name: "ELECTRICAL_INDUCTANCE",
    baseUnit: "H[henry]",
    description: "Electrical Inductance",
    quantity: "Electrical Inductance",
    expressionInSI: "kg⋅m2⋅s−2⋅A−2",
    units: ["H[henry]", "µH", "mH"],
    dimension: new Dimension({ kg: 1, m: 2, s: -2, A: -2 }),
  },
  {
    id: 130,
    name: "ELECTRICAL_CONDUCTANCE",
    baseUnit: "S[siemens]",
    description: "Electrical Conductance",
    quantity: "Electrical Conductance",
    expressionInSI: "kg−1⋅m−2⋅s3⋅A2",
    units: ["S[siemens]", "℧", "µS", "mS", "kS"],
    dimension: new Dimension({ kg: -1, m: -2, s: 3, A: 2 }),
  },
  {
    id: 131,
    name: "MAGNETIC_FLUX",
    baseUnit: "Wb[weber]",
    description: "Magnetic Flux",
    quantity: "Magnetic Flux",
    expressionInSI: "kg⋅m2⋅s−2⋅A−1",
    units: ["Wb[weber]", "nWb", "µWb", "mWb", "cWb", "dWb"],
    dimension: new Dimension({ kg: 1, m: 2, s: -2, A: -1 }),
  },
  {
    id: 132,
    name: "LUMINOUS_FLUX",
    baseUnit: "lm[lumen]",
    description: "Luminous Flux",
    quantity: "Luminous Flux",
    expressionInSI: "cd⋅sr",
    units: ["lm[lumen]", "cd⋅sr"],
    dimension: new Dimension({ cd: 1 }), // TODO: check sr
  },
  {
    id: 133,
    name: "MAGNETIC_FLUX_DENSITY",
    baseUnit: "T[tesla]",
    description: "Magnetic Flux Density",
    quantity: "Magnetic Flux Density",
    expressionInSI: "kg⋅s−2⋅A−1",
    units: ["T[tesla]", "Wb/m²", "µT", "G", "mT"],
    dimension: new Dimension({ kg: 1, s: -2, A: -1 }),
  },
  {
    id: 134,
    name: "KATALYTIC_ACTIVITY",
    baseUnit: "kat[katal]",
    description: "Katalytic Activity",
    quantity: "Katalytic Activity",
    expressionInSI: "mol⋅s−1",
    units: ["kat[katal]", "mol/s", "µkat", "mkat", "kkat", "Mkat"],
    dimension: new Dimension({ mol: 1, s: -1 }),
  },
  {
    id: 135,
    name: "RADIOACTIVITY",
    baseUnit: "Bq[becquerel]",
    description: "Radioactivity",
    quantity: "Radioactivity",
    expressionInSI: "s−1",
    units: [
      "Bq[becquerel]",
      "1/s",
      "µBq",
      "mBq",
      "kBq",
      "MBq",
      "Rd[rutherford]",
      "Ci[curie]",
    ],
    dimension: new Dimension({ s: -1 }),
  },
  {
    id: 136,
    name: "ABSORBED_DOSE_OF_IONIZING_RADIATION",
    baseUnit: "Gy[gray]",
    description: "Absorbed Dose of Ionizing Radiation",
    quantity: "Absorbed Dose of Ionizing Radiation",
    expressionInSI: "m2⋅s−2",
    // TODO: this is not unique
    units: ["Gy[gray]", 'J/kg', "µGy", "mGy", "kGy", "MGy"],
    dimension: new Dimension({ m: 2, s: -2 }),
  },
  {
    id: 137,
    name: "EQUIVALENT_DOSE_OF_IONIZING_RADIATION",
    baseUnit: "Sv[sievert]",
    description: "Equivalent Dose of Ionizing Radiation",
    quantity: "Equivalent Dose of Ionizing Radiation",
    expressionInSI: "m2⋅s−2",
    // TODO: this is not unique
    units: ["Sv[sievert]", 'J/kg', "µSv", "mSv", "kSv", "MSv"],
    dimension: new Dimension({ m: 2, s: -2 }),
  },
  {
    id: 138,
    name: "VOLUMETRIC DISPLACEMENT",
    baseUnit: "m³/rad",
    description: "Volumetric Displacement",
    quantity: "Volumetric Displacement",
    expressionInSI: "m³/1",
    units: [
      /** baseUnit does not need to be included here.
       *  => Update: show baseUnit so user can tell it is based on volume/angle, instead of volume/cycle. 20221016
       */
      'm³/rad',
      "m³/rev",
      "L/rev",
      "cc/rev",
      "ft³/rev",
      "in³/rev",
      "gal(US-liquid)/rev",
    ],
    dimension: new Dimension({ m: 3 }),
  },
  {
    id: 139,
    name: "FUEL ECONOMY", // 'mpg'
    baseUnit: "m/m³",
    description: "Fuel Economy",
    quantity: "Fuel Economy",
    expressionInSI: "m⋅m−3",
    units: [
      // 'm/m³',
      "km/L",
      "mpg(US)",
      "mile/gal(US-liquid)",
      "mpg(UK)",
      "mile/gal(UK-imperial)",
      "mile/L",
    ],
    dimension: new Dimension({ m: -2 }),
  },
  {
    /**
     * https://energyeducation.ca/encyclopedia/Fuel_consumption_vs_fuel_economy#:~:text=Fuel%20consumption%20and%20fuel%20economy,car%20gets%20out%20of%20fuel.
     * Fuel consumption and fuel economy are two phrases that are sometimes used interchangeably
     * but have very different meanings. The core difference involves fuel consumption discussing
     * how much fuel a car consumes to go a certain distance and fuel economy measures how much
     * distance a car gets out of fuel.
     */
    id: 140,
    name: "FUEL CONSUMPTION",
    baseUnit: "m³/m",
    description: "Fuel Consumption",
    quantity: "Fuel Consumption",
    expressionInSI: "m3⋅m-1",
    units: [
      'm³/m',
      "L/km",
      "L/100km",
      "L/mile",
      "L/100mile",
      "gal(US-liquid)/mile",
      "gal(US-liquid)/100mile",
      "gal(UK-imperial)/mile",
      "gal(UK-imperial)/100mile",
    ],
    dimension: new Dimension({ m: 2 }),
  },
  {
    id: 141,
    name: "NEW ENERGY ECONOMY (MPGe)", // 'mpg-e'
    baseUnit: "m/J",
    description: "New Energy Economy (MPGe)",
    quantity: "New Energy Economy (MPGe)",
    expressionInSI: "kg-1⋅m-1⋅s2",
    units: [
      'm/J',
      "km/kWh",
      "km/MJ",
      "MPGe",
      "mile/MJ",
      "mile/kWh",
      "mile/MM Btu",
    ],
    dimension: new Dimension({ kg: -1, m: -1, s: 2 }),
  },
  {
    id: 142,
    name: "NEW ENERGY CONSUMPTION",
    baseUnit: "J/m",
    description: "New Energy Consumption",
    quantity: "New Energy Consumption",
    expressionInSI: "kg: 1, m: 1, s: -2",
    units: [
      'J/m',
      "Wh/km",
      "Wh/mile",

      "kWh/km",
      "kWh/100km",
      "kWh/mile",
      "kWh/100mile",

      "MJ/km",
      "MJ/100km",
      "MJ/mile",
      "MJ/100mile",

      "MM Btu/km",
      "MM Btu/100km",
      "MM Btu/mile",
      "MM Btu/100mile",
    ],
    dimension: new Dimension({ kg: 1, m: 1, s: -2 }),
  },
  {
    id: 143,
    name: "BAUD RATE",
    baseUnit: "bit/s",
    description: "Baud Rate",
    quantity: "Baud Rate",
    expressionInSI: "", // not included in SI.
    units: ["bit/s", "Bd", "kBd", "MBd", "GBd"],
    dimension: new Dimension({ bd: 1, s: -1 }),
  },
  {
    id: 150,
    name: "Currency",
    baseUnit: "$",
    description: "Currency",
    quantity: "Currency",
    expressionInSI: "", // not included in SI.
    units: ["$", "¢", "$1K", "$1M", "$1B", "$1T"],
    dimension: new Dimension({ $: 1 }),
  },
  {
    id: 151,
    name: "IMPULSE_OR_MOMENTUM",
    baseUnit: "N·s",
    description: "Impulse / Momentum",
    quantity: "Impulse / Momentum",
    expressionInSI: "kg·m/s",
    units: ["N·s", "Newton-second", "lb·s", "kg·m/s", "lbm·fps", "lbm·fpm"],
    dimension: new Dimension({ kg: 1, m: 1, s: -1 }),
  },
  {
    id: 152,
    name: "DIGITAL_STORAGE",
    baseUnit: "bit",
    description: "Digital Storage",
    quantity: "Digital Storage",
    expressionInSI: "-",
    units: [
      "bit",
      "kilobit", "kibibit",
      "megabit", "mebibit",
      "gigabit", 'gibibit',
      "terabit", "tebibit",
      "petabit", "pebibit",
      "byte",
      "kilobyte", "kibibyte",
      "megabyte", "mebibyte",
      "gigabyte", 'gibibyte',
      "terabyte", "tebibyte",
      "petabyte", "pebibyte",
    ],
    dimension: new Dimension({ bit: 1 }),
  },
  {
    id: 153,
    name: "DATA_TRANSFER_RATE",
    baseUnit: "bit/s",
    description: "Data Transfer Rate",
    quantity: "Data Transfer Rate",
    expressionInSI: "-",
    units: [
      "bit/s",
      "kilobit/s", "kibibit/s",
      "megabit/s", "mebibit/s",
      "gigabit/s", 'gibibit/s',
      "terabit/s", "tebibit/s",
      "petabit/s", "pebibit/s",
    ],
    dimension: new Dimension({ bit: 1, s: -1 }),
  },

]; // UomCategories

export const Uoms = [
  // #region 900 - Unitless
  {
    id: 90000,
    name: "each",
    unit: "ea",
    symbol: "",
    expressionInSI: "",
    category: UomCategories.find((uom) => uom.id === 900),
    cof0: 1.0,
    system: ["SI Metric"],
    // base: (this.expressionInSI = this.unit) ? true : false
    description: "",
    base: true,
  },
  /* {
    id: 90001,
    name: ' ',
    unit: ' ',
    symbol: ' ',
    expressionInSI: '',
    category: UomCategories.find(uom => uom.id === 900),
    cof0: 1.0,
    system: ['SI Metric'],
    // base: (this.expressionInSI = this.unit) ? true : false
    base: false
  }, */

  // #endregion

  // #region 901 - Length
  {
    id: 90100,
    name: "meter",
    unit: "m",
    symbol: "m",
    expressionInSI: "m",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0,
    system: ["SI Metric"],
    // base: (this.expressionInSI = this.unit) ? true : false
    base: true,
  },
  {
    id: 901010,
    unit: "nano[nm]",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 1000000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 901011,
    unit: "µm",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 901012,
    unit: "micron",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90102,
    unit: "mm",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90103,
    unit: "cm",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 100,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90104,
    unit: "dm",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1.0 / 10,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90105,
    unit: "km",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90106,
    unit: "in",
    category: UomCategories.find((uom) => uom.id === 901),
    // cof0: 25.4/1000, // OK
    cof0: INCH_METER,
    system: "Imperial",
  },
  {
    id: 90107,
    unit: "thou[mil, or thousandth of an inch]",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 0.001 * INCH_METER,
    system: "Imperial",
  },
  {
    id: 90108,
    unit: "ft-in",
    category: UomCategories.find((uom) => uom.id === 901),
    // cof0: 25.4/1000, // OK
    cof0: INCH_METER,
    system: "Imperial",
  },
  {
    id: 90109,
    unit: "ft",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 90110,
    unit: "yd",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: 3.0 * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 90111,
    unit: "mile",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: MILE_FOOT * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 901120,
    unit: "NM[M, nmi, or nautical mile]",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: NM_METER,
    system: "SI Metric",
    base: false,
  },
  /**
   *  per https://en.wikipedia.org/wiki/Nautical_mile, historically, it was defined as the
      meridian arc length corresponding to one minute (1/60 of a degree) of latitude at the
      equator.

      Fow now, I am not goint to include this, as the length of the equator is not accurate.
  {
    id: 901121,
    unit: "NM[M, nmi, or nautical mile]",
    category: UomCategories.find((uom) => uom.id === 901),
    cof0: ???,
    system: "Imperial",
    base: false,
  }, */

  // #endregion

  // #region 902 - MASS
  {
    id: 90200,
    unit: "kg",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 90201,
    unit: "g",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90202,
    unit: "mg",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90203,
    unit: "µg",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 1.0 / 1000000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90204,
    unit: "oz",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: (1.0 / 16) * POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 90205,
    unit: "lbm",
    description: "1 pound of mass.",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 90206,
    unit: "slug",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: SLUG_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 90207,
    unit: "ton(US/short)",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 2000.0 * POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 90208,
    unit: "ton(UK-Imperial/long)",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 2240.0 * POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 90209,
    unit: "tonne",
    category: UomCategories.find((uom) => uom.id === 902),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 903 - TIME
  {
    id: 90300,
    unit: "s[second]",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 1.0,
    system: "SI Metric|Imperial",
    base: true,
  },
  {
    id: 90301,
    unit: "s",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 1.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 90302,
    unit: "minute",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 60.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 90303,
    unit: "h",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 3600.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 90304,
    unit: "h[hour]",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 3600.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 90305,
    unit: "day",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 3600.0 * 24,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 90306,
    unit: "week",
    category: UomCategories.find((uom) => uom.id === 903),
    cof0: 3600.0 * 24 * 7,
    system: "SI Metric|Imperial",
    base: false,
  },
  // #endregion

  // #region 904 - ELECTRIC_CURRENT
  // { id: 904, name: 'ELECTRIC_CURRENT', baseUnit: 'A', description: 'Electric Current' },
  {
    id: 90400,
    unit: "A",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 90401,
    unit: "Ampere[amp]",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90402,
    unit: "mA",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90403,
    unit: "µA",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90404,
    unit: "kA",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90405,
    unit: "MA",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90406,
    unit: "esu/s",
    category: UomCategories.find((uom) => uom.id === 904),
    cof0: 1.0 / 3e9,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 905 - TEMPERATURE
  /** currently this is based on F as base, however F is more used in Imperial.
   *  Should I use C as base?
   *  Also see note on 20230930 in log.md
   * F = 1.8 * C + 32
   * F = 1.8 * K - 459.67
   * F = Ra - 459.67
   * -----------
   * K = ( 1.8 C + 32 + 459.67) / 1.8 = C + 273.15
   * K = (F + 459.67) / 1.8           = F / 1.8 + 255.3722222
   * K = (Ra - 459.67 + 459.67) / 1.8 = Ra / 1.8
   */
  {
    id: 90500,
    unit: "°K",
    category: UomCategories.find((uom) => uom.id === 905),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 90501,
    unit: "°C",
    category: UomCategories.find((uom) => uom.id === 905),
    cof0: 1.0,
    cof1: 273.15,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90502,
    unit: "°F",
    category: UomCategories.find((uom) => uom.id === 905),
    cof0: 5.0 / 9,
    cof1: 255.3722222,
    system: "Imperial",
    base: false,
  },
  {
    id: 90503,
    unit: "°Ra", // It is named after the Scottish engineer and physicist William John Macquorn Rankine.
    category: UomCategories.find((uom) => uom.id === 905),
    cof0: 5.0 / 9,
    cof1: 0.0,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 906 - AMOUNT_OF_SUBSTANCE
  /**
   * { id: 906, name: 'AMOUNT_OF_SUBSTANCE', baseUnit: 'mol', description: 'Amount of Substance' },
   * http://www.endmemo.com/convert/mole.php
   */
  {
    id: 90600,
    unit: "mol",
    category: UomCategories.find((uom) => uom.id === 906),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 90601,
    unit: "mmol",
    category: UomCategories.find((uom) => uom.id === 906),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90602,
    unit: "kmol",
    category: UomCategories.find((uom) => uom.id === 906),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 907 - LUMINOUS_INTENSITY
  /**
   * { id: 907, name: 'LUMINOUS_INTENSITY', baseUnit: 'cd', description: 'Luminous Intensity' },
   * https://www.convertunits.com/from/candela/to/decimal+candle
   * https://www.convertunits.com/
   * http://www.endmemo.com/sconvert/decimalcandelcandela.php
   * https://en.wikipedia.org/wiki/Candlepower
   */
  {
    id: 90700,
    unit: "cd[candela]",
    category: UomCategories.find((uom) => uom.id === 907),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  /* {
      id: 90701,
      unit: 'decimal candle',
      category: UomCategories.find(uom => uom.id === 907),
      cof0: 0.98039215686275,
      system: 'SI Metric',
      base: false
    }, */
  {
    id: 90702,
    unit: "lm/sr",
    category: UomCategories.find((uom) => uom.id === 907),
    cof0: 1,
    system: "SI Metric",
    base: false,
  },
  {
    // https://en.wikipedia.org/wiki/Candlepower
    id: 90703,
    unit: "cp[candlepower]",
    category: UomCategories.find((uom) => uom.id === 907),
    cof0: 1,
    system: "SI Metric",
    base: false,
  },
  {
    id: 90704,
    unit: "cp[candlepower(before 1948)]",
    category: UomCategories.find((uom) => uom.id === 907),
    cof0: 0.981,
    system: "SI Metric",
    base: false,
  },

  // #endregion

  // #region 100 - ACCELERATION
  {
    id: 10000,
    unit: "m/s²", // from unit
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10001,
    unit: "m/min²",
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10002,
    unit: "mm/s²",
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10003,
    unit: "ft/s²",
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 10004,
    unit: "ft/min²",
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: FOOT_METER / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 10005,
    unit: "in/s²",
    category: UomCategories.find((uom) => uom.id === 100),
    cof0: INCH_METER,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1001 - GRAVITATIONAL CONSTANT
  {
    id: 100100,
    unit: "m³/kg/s²", // from unit
    category: UomCategories.find((uom) => uom.id === 1001),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 100101,
    unit: "N⋅m²/kg²", // from unit
    category: UomCategories.find((uom) => uom.id === 1001),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 100102,
    unit: "dyn⋅cm²/g²", // https://en.wikipedia.org/wiki/Gravitational_constant
    category: UomCategories.find((uom) => uom.id === 1001),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 1010 - VELOCITY - ANGULAR
  {
    id: 101000,
    unit: "rad/s",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 101001,
    unit: "rad/min",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101002,
    unit: "rad/h",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101003,
    unit: "deg°/s",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: Math.PI / 180,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101004,
    unit: "deg°/min",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: Math.PI / 180.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101005,
    unit: "deg°/h",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: Math.PI / 180.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {// TODO: in future upgrade to allow each unit to be in multiple categories
    id: 10106,
    unit: "rpm[rev/min]",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: (2.0 * Math.PI) / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10107,
    unit: "rev/s",
    category: UomCategories.find((uom) => uom.id === 1010),
    cof0: 2.0 * Math.PI,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 1011 - ACCELERATION_ANGULAR
  {
    id: 101100,
    unit: "rad/s²",
    category: UomCategories.find((uom) => uom.id === 1011),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 101101,
    unit: "rad/min²",
    category: UomCategories.find((uom) => uom.id === 1011),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101102,
    unit: "deg°/s²",
    category: UomCategories.find((uom) => uom.id === 1011),
    cof0: Math.PI / 180,
    system: "SI Metric",
    base: false,
  },
  {
    id: 101103,
    unit: "deg°/min²",
    category: UomCategories.find((uom) => uom.id === 1011),
    cof0: Math.PI / 180.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 102 - ILLUMINANCE
  // http://www.convert-me.com/en/convert/illuminance
  {
    id: 10200,
    unit: "lux",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10201,
    unit: "lumen/m²",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10202,
    unit: "lm/m²",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10203,
    unit: "meter-candle",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10204,
    unit: "phot",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 10000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10205,
    unit: "foot-candle",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0 / Math.pow(FOOT_METER, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10206,
    unit: "fc",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0 / Math.pow(FOOT_METER, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10207,
    unit: "lumen/ft²",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0 / Math.pow(FOOT_METER, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10208,
    unit: "lm/ft²",
    category: UomCategories.find((uom) => uom.id === 102),
    cof0: 1.0 / Math.pow(FOOT_METER, 2),
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 103 - FREQUENCY
  {
    id: 103000,
    unit: "Hz",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 103001,
    unit: "1/s",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 103010,
    unit: "1/min",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 103011,
    unit: "rpm", // Means once per minute.
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10302,
    unit: "1/hour",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10303,
    unit: "mHz",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10304,
    unit: "kHz",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10305,
    unit: "MHz",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10306,
    unit: "GHz",
    category: UomCategories.find((uom) => uom.id === 103),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 104 - AREA
  {
    id: 10400,
    unit: "m²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10401,
    unit: "µm²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(1.0 / 1000000, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10402,
    unit: "mm²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(1.0 / 1000, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10403,
    unit: "cm²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(1.0 / 100, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10404,
    unit: "km²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(1000, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10405,
    unit: "hectare",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(100.0, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10406,
    unit: "in²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 10407,
    unit: "ft²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 10408,
    unit: "yd²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(FOOT_METER * 3, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 10409,
    unit: "acre",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: ACRE_FOOT2 * Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 10410,
    unit: "mile²",
    category: UomCategories.find((uom) => uom.id === 104),
    cof0: Math.pow(MILE_FOOT * FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 105 - VOLUME / SECTION MODULUS
  {
    id: 10500,
    unit: "m³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: 1,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10501,
    unit: "µm³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(1.0 / 1000000, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10502,
    unit: "mm³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(1.0 / 1000, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10503,
    unit: "cm³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(1.0 / 100, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10504,
    unit: "L",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10505,
    unit: "mL",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 10506,
    unit: "km³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(1000.0, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10507,
    unit: "in³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10508,
    unit: "ft³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10509,
    unit: "yd³",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: Math.pow(FOOT_METER * 3, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10510,
    unit: "gal(UK-imperial)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: (GALLON_UK_IMPERIAL_LITRE * 1.0) / 1000,
    system: "Imperial",
    base: false,
  },
  {
    id: 10511,
    unit: "gal(US-liquid)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10512,
    unit: "gal(US-dry)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: GALLON_US_DRY_IN3 * Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10513,
    unit: "pint(UK-imperial)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: (PINT_GALLON * GALLON_UK_IMPERIAL_LITRE * 1.0) / 1000,
    system: "Imperial",
    base: false,
  },
  {
    id: 10514,
    unit: "pint(US-liquid)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: PINT_GALLON * GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10515,
    unit: "pint(US-dry)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: PINT_GALLON * GALLON_US_DRY_IN3 * Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10516,
    unit: "fluid ounce(UK-imperial)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0:
      (OUNCE_FLUID_PINT_UK_IMPERIAL *
        PINT_GALLON *
        GALLON_UK_IMPERIAL_LITRE *
        1.0) /
      1000,
    system: "Imperial",
    base: false,
  },
  {
    id: 10517,
    unit: "fluid ounce(US-liquid)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0:
      OUNCE_FLUID_PINT_US_LIQUID *
      PINT_GALLON *
      GALLON_US_LIQUID_IN3 *
      Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 10518,
    unit: "bushel(UK-imperial)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: BUSHEL_GALLON_UK_IMPERIAL * GALLON_UK_IMPERIAL_LITRE /1000.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 10519,
    unit: "bushel(US-dry)",
    category: UomCategories.find((uom) => uom.id === 105),
    cof0: BUSHEL_GALLON_US_DRY * GALLON_US_DRY_IN3 * Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 106 - POLAR_MOMENT_OF_INERTIA
  {
    id: 10600,
    unit: "m⁴",
    category: UomCategories.find((uom) => uom.id === 106),
    cof0: 1,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10601,
    unit: "mm⁴",
    category: UomCategories.find((uom) => uom.id === 106),
    cof0: Math.pow(1.0 / 1000, 4),
    system: "SI Metric",
    base: false,
  },
  {
    id: 10602,
    unit: "cm⁴",
    category: UomCategories.find((uom) => uom.id === 106),
    cof0: Math.pow(1.0 / 100, 4),
    system: "SI Metric",
    base: false,
  },

  {
    id: 10603,
    unit: "in⁴",
    category: UomCategories.find((uom) => uom.id === 106),
    cof0: Math.pow(INCH_METER, 4),
    system: "Imperial",
    base: false,
  },
  {
    id: 10604,
    unit: "ft⁴",
    category: UomCategories.find((uom) => uom.id === 106),
    cof0: Math.pow(FOOT_METER, 4),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1060 - MOMENT_OF_INERTIA
  {
    id: 106000,
    unit: "kg⋅m²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: 1,
    system: "SI Metric",
    base: true,
  },
  {
    id: 106001,
    unit: "g⋅cm²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: Math.pow(1.0/100, 2) / 1000,
    system: "Metric",
    base: false,
  },
  {
    id: 106002,
    unit: "g⋅mm²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: Math.pow(1.0/1000, 2) / 1000,
    system: "Metric",
    base: false,
  },
  {
    id: 106003,
    unit: "lbm⋅ft²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: POUNDMASS_KG * Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 106004,
    unit: "lbm⋅in²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: POUNDMASS_KG * Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 106005,
    unit: "oz⋅in²",
    category: UomCategories.find((uom) => uom.id === 1060),
    cof0: POUNDMASS_KG / 16.0 * Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 107 - FORCE
  {
    id: 10700,
    unit: "N",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10701,
    unit: "kN",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 107020,
    unit: "MN",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 107021,
    unit: "kgf",
    description: "The force exerted on 1 kg of mass due to Earth's gravity.",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: GRAVITY_SI,
    system: "SI Metric",
    base: false,
  },
  {
    id: 107030,
    unit: "lb",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: POUNDMASS_KG * GRAVITY_SI,
    system: "Imperial",
    base: false,
  },
  {
    id: 107031,
    unit: "lbf", // both "lb" & "lbf" are widely used, so listed for more user-friendly.
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: POUNDMASS_KG * GRAVITY_SI,
    system: "Imperial",
    base: false,
  },
  {
    id: 10704,
    unit: "kip[kilo pound]",
    category: UomCategories.find((uom) => uom.id === 107),
    cof0: 1000.0 * POUNDMASS_KG * GRAVITY_SI,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 108 - ANGLE
  {
    id: 10800,
    unit: "m/m",
    category: UomCategories.find((uom) => uom.id === 108),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 10801,
    unit: "radian",
    category: UomCategories.find((uom) => uom.id === 108),
    cof0: 1.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 10802,
    unit: "rad",
    category: UomCategories.find((uom) => uom.id === 108),
    cof0: 1.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 10803,
    unit: "deg°",
    category: UomCategories.find((uom) => uom.id === 108),
    cof0: Math.PI / 180.0,
    system: "SI Metric|Imperial",
    base: false,
  },
  {
    id: 10804,
    unit: "revolution", // https://en.wikipedia.org/wiki/Revolutions_per_minute
    category: UomCategories.find((uom) => uom.id === 108),
    cof0: Math.PI * 2.0,
    system: "SI Metric|Imperial",
    base: false,
  },

  // #endregion

  // #region 1081 - SOLID_ANGLE
  {
    id: 108100,
    unit: "sr[steradian]",
    category: UomCategories.find((uom) => uom.id === 1081),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 108101,
    unit: "m²/m²",
    category: UomCategories.find((uom) => uom.id === 1081),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 1090 - BMI/BALLISTIC_COEFFICIENT
  {
    id: 109000,
    unit: "kg/m²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 109002,
    unit: "g/cm²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: 1.0 / 1000 / Math.pow(0.01, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 109003,
    unit: "g/mm²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: 1.0 / 1000 / Math.pow(0.001, 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 109004,
    unit: "tonne/m²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 109005,
    unit: "lbm/yd²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / Math.pow(3 * FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 109006,
    unit: "lbm/ft²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 109007,
    unit: "lbm/in²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 109009,
    unit: "oz/yd²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(3 * FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 109010,
    unit: "oz/ft²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 109011,
    unit: "oz/in²",
    category: UomCategories.find((uom) => uom.id === 1090),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1091 - DENSITY
  {
    id: 109100,
    unit: "kg/m³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 109101,
    unit: "kg/L",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 109102,
    unit: "g/cm³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: 1.0 / 1000 / Math.pow(0.01, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 109103,
    unit: "g/mm³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: 1.0 / 1000 / Math.pow(0.001, 3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 109104,
    unit: "tonne/m³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 109105,
    unit: "lbm/yd³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / Math.pow(3 * FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109106,
    unit: "lbm/ft³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109107,
    unit: "lbm/in³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109108,
    unit: "lbm/gal(US)",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)),
    system: "Imperial",
    base: false,
  },
  {
    id: 109109,
    unit: "oz/yd³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(3 * FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109110,
    unit: "oz/ft³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109111,
    unit: "oz/in³",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0: POUNDMASS_KG / 16.0 / Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 109112,
    unit: "oz/gal(US)",
    category: UomCategories.find((uom) => uom.id === 1091),
    cof0:
      POUNDMASS_KG / 16.0 / (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 110 - ENERGY
  {
    id: 11000,
    unit: "J[Joule]",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11001,
    unit: "kJ",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11002,
    unit: "MJ",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11003,
    unit: "GJ",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11004,
    unit: "TNT Equivalent[kiloton]",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1e9 * CALORIE_TH_J, // 1e9 = 1000000000.0
    system: "SI Metric",
    base: false,
  },
  {
    id: 11005,
    unit: "Btu",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: BTU_J,
    system: "Imperial",
    base: false,
  },
  {
    id: 11006,
    unit: "MM Btu",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: BTU_J * 1000000.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11007,
    unit: "calorie(IT)",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: CALORIE_IT_J,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11008,
    unit: "kilo-calorie(IT)",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: CALORIE_IT_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11009,
    unit: "calorie(TH)",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: CALORIE_TH_J,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11010,
    unit: "kilo-calorie(TH)",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: CALORIE_TH_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11011,
    unit: "HP Hour",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: HP_W * 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11012,
    unit: "Wh",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11013,
    unit: "kWh",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 3600.0 * 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11014,
    unit: "eV[electron-volt]",
    category: UomCategories.find((uom) => uom.id === 110),
    cof0: 1 / J_EV,
    system: "SI Metric",
    base: false,
  },

  // #endregion

  // #region 111 - POWER
  {
    id: 11100,
    unit: "W[watt]",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11101,
    unit: "kW",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11102,
    unit: "MW",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11103,
    unit: "GW[Gigawatt]",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },

  {
    id: 11104,
    unit: "Btu/h",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: BTU_J / 3600.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11105,
    unit: "Btu/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: BTU_J / 60.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11106,
    unit: "MM Btu/h",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: (BTU_J * 1000000.0) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11107,
    unit: "calorie(IT)/second",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: CALORIE_IT_J,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11108,
    unit: "calorie(IT)/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: CALORIE_IT_J / 60.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11109,
    unit: "kilo-calorie(IT)/hour",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: (CALORIE_IT_J * 1000.0) / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11110,
    unit: "calorie(TH)/second",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: CALORIE_TH_J,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11111,
    unit: "calorie(TH)/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: CALORIE_TH_J / 60.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11112,
    unit: "kilo-calorie(TH)/hour",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: (CALORIE_TH_J * 1000.0) / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11113,
    unit: "lb-ft/second",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: POUNDMASS_KG * GRAVITY_SI * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 11114,
    unit: "lb-ft/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: (POUNDMASS_KG * GRAVITY_SI * FOOT_METER) / 60,
    system: "Imperial",
    base: false,
  },

  {
    id: 11115,
    unit: "HP",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: HP_W,
    system: "Imperial",
    base: false,
  },

  {
    id: 11116,
    unit: "J/second",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11117,
    unit: "J/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11118,
    unit: "J/h",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11119,
    unit: "MJ/h",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1000000.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11120,
    unit: "kJ/minute",
    category: UomCategories.find((uom) => uom.id === 111),
    cof0: 1000 / 60.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 112 - TORQUE
  {
    id: 11200,
    unit: "Nm",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11201,
    unit: "kNm",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 112020,
    unit: "lb-in",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: POUNDMASS_KG * GRAVITY_SI * INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 112021,
    unit: "kip-in",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: 1000.0 * POUNDMASS_KG * GRAVITY_SI * INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 11203,
    unit: "lb-ft",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: POUNDMASS_KG * GRAVITY_SI * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 11204,
    unit: "oz-in",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: (POUNDMASS_KG * GRAVITY_SI * INCH_METER) / 16,
    system: "Imperial",
    base: false,
  },
  {
    id: 11205,
    unit: "oz-ft",
    category: UomCategories.find((uom) => uom.id === 112),
    cof0: ((POUNDMASS_KG * GRAVITY_SI * FOOT_METER) / 16),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1130 - PRESSURE/STRESS
  {
    id: 113000,
    unit: "Pa",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113020,
    unit: "Pascal",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 113001,
    unit: "hPa",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 100.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113002,
    unit: "kPa",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113003,
    unit: "MPa",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113004,
    unit: "GPa",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113005,
    unit: "bar",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 100000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113006,
    unit: "mbar",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 100.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113007,
    unit: "atm",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: ATM_PA,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113008,
    unit: "mm HG",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: MMHG_PA,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113009,
    unit: "cm HG",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: MMHG_PA * 10.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113010,
    unit: "in HG",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: MMHG_PA * 25.4,
    system: "Imperial",
    base: false,
  },
  {
    id: 113011,
    unit: "mm W.C.",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: (1.0 / 1000) * 1000 * GRAVITY_SI,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113012,
    unit: "m W.C.",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: 1.0 * 1000 * GRAVITY_SI,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113013,
    unit: "in W.C.",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: INCH_METER * 1000.0 * GRAVITY_SI,
    system: "Imperial",
    base: false,
  },
  {
    id: 113014,
    unit: "psf",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 113015,
    unit: "psi",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 113016,
    unit: "ksi",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: (1000.0 * POUNDMASS_KG * GRAVITY_SI) / Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 113017,
    unit: "ksi[kip/in²]",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: (1000.0 * POUNDMASS_KG * GRAVITY_SI) / Math.pow(INCH_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 113018,
    unit: "Torr",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: TORR_ATM * ATM_PA,
    system: "Imperial",
    base: false,
  },
  {
    id: 113019,
    unit: "mTorr",
    category: UomCategories.find((uom) => uom.id === 1130),
    cof0: TORR_ATM * ATM_PA * 0.001,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1131 - PRESSURE DROP PER UNIT LENGTH
  {
    id: 113100,
    unit: "Pa/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113101,
    unit: "Pascal/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },

  {
    id: 113102,
    unit: "kPa/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113103,
    unit: "MPa/km",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },

  {
    id: 113104,
    unit: "bar/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 100000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113105,
    unit: "mbar/mm",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 100000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113106,
    unit: "atm/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: ATM_PA,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113107,
    unit: "mm HG/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: MMHG_PA,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113108,
    unit: "in HG/ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: MMHG_PA * 25.4/FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113109,
    unit: "mm W.C./m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: (1.0 / 1000) * 1000 * GRAVITY_SI,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113110,
    unit: "m W.C./m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: 1.0 * 1000 * GRAVITY_SI,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113111,
    unit: "in W.C./ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: INCH_METER * 1000.0 * GRAVITY_SI / FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113112,
    unit: "in W.C./100 ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: INCH_METER * 1000.0 * GRAVITY_SI / FOOT_METER / 100,
    system: "Imperial",
    base: false,
  },
  {
    id: 113113,
    unit: "psf/in",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / Math.pow(FOOT_METER, 2)/INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113114,
    unit: "psi/ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / Math.pow(INCH_METER, 2)/FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113115,
    unit: "ksi/ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: (1000.0 * POUNDMASS_KG * GRAVITY_SI) / Math.pow(INCH_METER, 2)/FOOT_METER,
    system: "Imperial",
    base: false,
  },

  {
    id: 113116,
    unit: "Torr/ft",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: TORR_ATM * ATM_PA / FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113117,
    unit: "mTorr/m",
    category: UomCategories.find((uom) => uom.id === 1131),
    cof0: TORR_ATM * ATM_PA * 0.001,
    system: "Imperial",
    base: false,
  },

  // #endregion

  // #region 1132 - UNIFORMLY DISTRIBUTED (UNIFORM LINE) LOAD | STIFFNESS OF LINEAR SPRING
  {
    id: 113200,
    unit: "N/m",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 113201,
    unit: "N/mm",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: 1.0 * 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113202,
    unit: "kN/m",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113203,
    unit: "lb/ft",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113204,
    unit: "kips/ft",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: (1000.0 * POUNDMASS_KG * GRAVITY_SI) / FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113205,
    unit: "lb/in",
    category: UomCategories.find((uom) => uom.id === 1132),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / INCH_METER,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1133 - STIFFNESS OF ROTARY/TORSIONAL SPRING
  {
    id: 113300,
    unit: "N⋅m/rad",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 113301,
    unit: "N⋅m/deg°",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: 1.0/(Math.PI/180),
    system: "SI Metric",
    base: false,
  },
  {
    id: 113302,
    unit: "N⋅mm/rad",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 113303,
    unit: "N⋅mm/deg°",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: 0.001/(Math.PI/180),
    system: "SI Metric",
    base: false,
  },
  {
    id: 113304,
    unit: "lb-ft/rad",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: POUNDMASS_KG * GRAVITY_SI * FOOT_METER,
    system: "Imperial",
    base: true,
  },
  {
    id: 113305,
    unit: "lb-ft/deg°",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: POUNDMASS_KG * GRAVITY_SI * FOOT_METER / (Math.PI/180),
    system: "Imperial",
    base: false,
  },
  {
    id: 113306,
    unit: "lb-in/rad",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: POUNDMASS_KG * GRAVITY_SI * INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 113307,
    unit: "lb-in/deg°",
    category: UomCategories.find((uom) => uom.id === 1133),
    cof0: POUNDMASS_KG * GRAVITY_SI * INCH_METER / (Math.PI/180),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 114 - FLOW_RATE_VOLUME
  {
    id: 11400,
    unit: "m³/s",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 114001,
    unit: "m³/min",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11401,
    unit: "m³/h",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11402,
    unit: "L/s",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11403,
    unit: "L/min",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 0.001 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11404,
    unit: "L/h",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 0.001 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 114040,
    unit: "cm³/s",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 0.000001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 114041,
    unit: "cm³/min",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: 0.000001/60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 114050,
    unit: "in³/s",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(INCH_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 114051,
    unit: "in³/min",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(INCH_METER, 3) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 11406,
    unit: "ft³/s",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 114070,
    unit: "CFM",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER, 3) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 114071,
    unit: "CFM[ft³/min]",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER, 3) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 114080,
    unit: "CFH",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER, 3) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 114081,
    unit: "CFH[ft³/h]",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER, 3) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11409,
    unit: "yd³/min",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER * 3, 3) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 11410,
    unit: "yd³/h",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: Math.pow(FOOT_METER * 3, 3) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11411,
    unit: "gpm(US)",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 11412,
    unit: "bushel(UK-imperial)/h",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: (BUSHEL_GALLON_UK_IMPERIAL * GALLON_UK_IMPERIAL_LITRE / 1000.0) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11413,
    unit: "bushel(US)/h",
    category: UomCategories.find((uom) => uom.id === 114),
    cof0: BUSHEL_GALLON_US_DRY * GALLON_US_DRY_IN3 * Math.pow(INCH_METER, 3) / 3600,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 115 - FLOW_RATE_MASS
  {
    id: 11500,
    unit: "kg/s",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11501,
    unit: "kg/min",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11502,
    unit: "kg/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },

  {
    id: 11503,
    unit: "g/s",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11504,
    unit: "g/min",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1.0 / 1000 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11505,
    unit: "g/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1.0 / 1000 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11506,
    unit: "slug/s",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: SLUG_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 11507,
    unit: "slug/min",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: SLUG_KG / 60.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11508,
    unit: "slug/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: SLUG_KG / 3600.0,
    system: "Imperial",
    base: false,
  },

  {
    id: 11509,
    unit: "lbm/s",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 11510,
    unit: "lbm/min",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG / 60.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11511,
    unit: "lbm/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG / 3600.0,
    system: "Imperial",
    base: false,
  },

  {
    id: 11512,
    unit: "oz/s",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG / 16.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11513,
    unit: "oz/min",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG / 16.0 / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 11514,
    unit: "oz/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: POUNDMASS_KG / 16.0 / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11515,
    unit: "tonne/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: 1000.0 / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11516,
    unit: "short ton/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: (2000.0 * POUNDMASS_KG) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 11517,
    unit: "long ton/h",
    category: UomCategories.find((uom) => uom.id === 115),
    cof0: (2240.0 * POUNDMASS_KG) / 3600,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 117 - VELOCITY - LINEAR
  {
    id: 11700,
    unit: "m/s",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11701,
    unit: "mm/s",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11702,
    unit: "km/s",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },

  {
    id: 11703,
    unit: "mm/min",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0 / 1000 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11704,
    unit: "m/min",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11705,
    unit: "km/min",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1000.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11706,
    unit: "mm/h",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0 / 1000 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11707,
    unit: "m/h",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11708,
    unit: "km/h",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: 1000.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11709,
    unit: "in/s",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 117100,
    unit: "fps",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 117101,
    unit: "fps[ft/second]",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 117110,
    unit: "fpm",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: FOOT_METER / 60.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 117111,
    unit: "fpm[ft/minute]",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: FOOT_METER / 60.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11712,
    unit: "mph[mile/h]",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: (MILE_FOOT * FOOT_METER) / 3600.0,
    system: "Imperial",
    base: false,
  },
  {
    id: 11713,
    unit: "knot[kn, kt, or nautical mile/h]",
    category: UomCategories.find((uom) => uom.id === 117),
    cof0: NM_METER / 3600.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 119 - FUEL_HEAT_VALUE_VOLUME // the same as ENERGY_DENSITY/VOLUMETRIC
  /**
   */
  {
    id: 11900,
    unit: "J/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 11901,
    unit: "J/L",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1 / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11902,
    unit: "kJ/L",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1000 / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11903,
    unit: "kJ/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11904,
    unit: "MJ/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11905,
    unit: "GJ/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11906,
    unit: "cal(IT)/L",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: CALORIE_IT_J / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11907,
    unit: "kcal(IT)/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: CALORIE_IT_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11908,
    unit: "cal(TH)/L",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: CALORIE_TH_J / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 11909,
    unit: "kcal(TH)/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: CALORIE_TH_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 119110,
    unit: "Wh/L",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 3600.0/0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 119111,
    unit: "Wh/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 119112,
    unit: "kWh/m³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: 3600.0 * 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 119113,
    unit: "HP Hour/ft³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: HP_W * 3600.0 / Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 11914,
    unit: "Btu/gal(US)",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: BTU_J / (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)),
    system: "Imperial",
    base: false,
  },
  {
    id: 11915,
    unit: "Btu/ft³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: BTU_J / Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  {
    id: 11916,
    unit: "MM Btu/ft³",
    category: UomCategories.find((uom) => uom.id === 119),
    cof0: BTU_J * 1000000.0 / Math.pow(FOOT_METER, 3),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 120 - FUEL_HEAT_VALUE_MASS  // the same as ENERGY_DENSITY/GRAVIMETRIC
  /**
   */
  {
    id: 12000,
    unit: "J/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12001,
    unit: "J/g",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 1.0/0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12002,
    unit: "kJ/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12003,
    unit: "MJ/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 1e6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12004,
    unit: "GJ/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 1e9,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12005,
    unit: "cal(IT)/g",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: CALORIE_IT_J / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12006,
    unit: "kcal(IT)/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: CALORIE_IT_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12007,
    unit: "cal(TH)/g",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: CALORIE_TH_J / 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12008,
    unit: "kcal(TH)/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: CALORIE_TH_J * 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12009,
    unit: "Wh/g",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 3600.0/0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12010,
    unit: "Wh/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12011,
    unit: "kWh/kg",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: 3600.0 * 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12012,
    unit: "HP Hour/lbm",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: HP_W * 3600.0 / POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 12013,
    unit: "Btu/lbm",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: BTU_J / POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 12014,
    unit: "MM Btu/lbm",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: BTU_J * 1e6 / POUNDMASS_KG,
    system: "Imperial",
    base: false,
  },
  {
    id: 12015,
    unit: "Btu/ton(short)",
    category: UomCategories.find((uom) => uom.id === 120),
    cof0: BTU_J / (2000.0 * POUNDMASS_KG),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 121 - MASS_HEAT_CAPACITY
  /**       */
  {
    id: 12100,
    unit: "J/(kg·°K)",
    category: UomCategories.find((uom) => uom.id === 121),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12101,
    unit: "J/(g·°K)",
    category: UomCategories.find((uom) => uom.id === 121),
    cof0: 1000.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12102,
    unit: "Btu/(lbm·°F)",
    category: UomCategories.find((uom) => uom.id === 121),
    cof0: BTU_J / (POUNDMASS_KG * F_K_C0),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 122 - VISCOSITY - ABSOLUTE / DYNAMIC
  /**       */
  {
    id: 12200,
    unit: "PI[Pa·s]", // '·' is special dot, not period
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12201,
    unit: "N·s/m²", // '·' is special dot, not period
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12202,
    unit: "kg/m/s", // kg·m/s2·s/m2 => kg/s/m
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: 1,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12203,
    unit: "P[Poise]",
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: 0.1,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12204,
    unit: "cP",
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12205,
    unit: "lb·s/ft²",
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: (POUNDMASS_KG * GRAVITY_SI) / Math.pow(FOOT_METER, 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 12206,
    unit: "lbm/ft/s",
    category: UomCategories.find((uom) => uom.id === 122),
    cof0: POUNDMASS_KG / FOOT_METER,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 123 - VISCOSITY - KINEMATIC
  /**       */
  {
    id: 12300,
    unit: "m²/s",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12301,
    unit: "m²/min",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 1.0 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12302,
    unit: "m²/hr",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12303,
    unit: "St[cm²/s]",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.0001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12304,
    unit: "Stoke",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.0001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12305,
    unit: "cm²/min",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.0001 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12306,
    unit: "cm²/hr",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.0001 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12307,
    unit: "cSt[mm²/s]",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.000001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12308,
    unit: "mm²/min",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.000001 / 60,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12309,
    unit: "mm²/hr",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: 0.000001 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12310,
    unit: "ft²/s",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: FOOT_METER * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 12311,
    unit: "ft²/min",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: (FOOT_METER * FOOT_METER) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 12312,
    unit: "ft²/hr",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: (FOOT_METER * FOOT_METER) / 3600,
    system: "Imperial",
    base: false,
  },
  {
    id: 12313,
    unit: "in²/s",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: INCH_METER * INCH_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 12314,
    unit: "in²/min",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: (INCH_METER * INCH_METER) / 60,
    system: "Imperial",
    base: false,
  },
  {
    id: 12315,
    unit: "in²/hr",
    category: UomCategories.find((uom) => uom.id === 123),
    cof0: (INCH_METER * INCH_METER) / 3600,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1240 - LINEAR_THERMAL_EXPANSION_COEFFICIENT
  {
    id: 124000,
    unit: "1/°K",
    category: UomCategories.find((uom) => uom.id === 1240),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 124001,
    unit: "1/°C",
    category: UomCategories.find((uom) => uom.id === 1240),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 124002,
    unit: "1/°F",
    category: UomCategories.find((uom) => uom.id === 1240),
    cof0: 1.8,
    system: "Imperial",
    base: false,
  },
  {
    id: 124003,
    unit: "1/°Ra",
    category: UomCategories.find((uom) => uom.id === 1240),
    cof0: 1.8,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 1241 - THERMAL_CONDUCTIVITY
  {
    id: 124100,
    unit: "W/(m⋅°K)",
    category: UomCategories.find((uom) => uom.id === 1241),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 124101,
    unit: "W/(cm⋅°K)",
    category: UomCategories.find((uom) => uom.id === 1241),
    cof0: 1.0/0.01,
    system: "SI Metric",
    base: false,
  },
  {
    id: 124102,
    unit: "BTU/(h⋅ft⋅°F)",
    category: UomCategories.find((uom) => uom.id === 1241),
    cof0: BTU_J/(3600.0*FOOT_METER*F_K_C0),
    system: "Imperial",
    base: false,
  },
  {
    id: 124103,
    unit: "BTU/(h⋅in⋅°F)",
    category: UomCategories.find((uom) => uom.id === 1241),
    cof0: BTU_J/(3600.0*INCH_METER*F_K_C0),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 125 - ELECTRICAL_CHARGE
  // e = 1.602176634 × 10−19 C
  {
    id: 12500,
    unit: "A⋅s",
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12501,
    unit: "C[coulomb]", // C = A⋅s
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 125020,
    unit: "Ah",
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 125021,
    unit: "ampere-hour",
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12503,
    unit: "mAh",
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 3.6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12504,
    unit: "e[electron]",
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: 1.0 / COULOMB_ELECTRON,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12505,
    unit: "faraday", // One faraday of charge is the magnitude of the charge of one mole of electrons, i.e. 96485.33212... C.
    category: UomCategories.find((uom) => uom.id === 125),
    cof0: MOLE / COULOMB_ELECTRON,
    system: "SI Metric",
    base: false,
  },

  // #endregion

  // #region 126 - ELECTRICAL_POTENTIAL_DIFFERENCE(VOLTAGE)
  {
    id: 12600,
    unit: "V[volt]",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12601,
    unit: "µV",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12602,
    unit: "mV",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12603,
    unit: "kV",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12604,
    unit: "MV",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12605,
    unit: "joule/coulomb",
    category: UomCategories.find((uom) => uom.id === 126),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 127 - ELECTRICAL_RESISTANCE(IMPEDANCE)
  {
    id: 12700,
    unit: "Ω[Ohm]",
    category: UomCategories.find((uom) => uom.id === 127),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12701,
    unit: "µΩ",
    category: UomCategories.find((uom) => uom.id === 127),
    cof0: 1.0 / 1000000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12702,
    unit: "mΩ",
    category: UomCategories.find((uom) => uom.id === 127),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12703,
    unit: "kΩ",
    category: UomCategories.find((uom) => uom.id === 127),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12704,
    unit: "MΩ",
    category: UomCategories.find((uom) => uom.id === 127),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 128 - ELECTRICAL_CAPACITANCE
  {
    id: 12800,
    unit: "F[farad]",
    category: UomCategories.find((uom) => uom.id === 128),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12801,
    unit: "pF",
    category: UomCategories.find((uom) => uom.id === 128),
    cof0: 1e-12,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12802,
    unit: "nF",
    category: UomCategories.find((uom) => uom.id === 128),
    cof0: 1e-9,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12803,
    unit: "µF",
    category: UomCategories.find((uom) => uom.id === 128),
    cof0: 1e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12804,
    unit: "mF",
    category: UomCategories.find((uom) => uom.id === 128),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 129 - ELECTRICAL_INDUCTANCE
  {
    id: 12900,
    unit: "H[henry]",
    category: UomCategories.find((uom) => uom.id === 129),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 12901,
    unit: "µH",
    category: UomCategories.find((uom) => uom.id === 129),
    cof0: 1e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 12902,
    unit: "mH",
    category: UomCategories.find((uom) => uom.id === 129),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 130 - ELECTRICAL_CONDUCTANCE
  {
    id: 13000,
    unit: "S[siemens]",
    category: UomCategories.find((uom) => uom.id === 130),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13001,
    unit: "℧",
    category: UomCategories.find((uom) => uom.id === 130),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13002,
    unit: "µS",
    category: UomCategories.find((uom) => uom.id === 130),
    cof0: 1e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13003,
    unit: "mS",
    category: UomCategories.find((uom) => uom.id === 130),
    cof0: 0.001,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13004,
    unit: "kS",
    category: UomCategories.find((uom) => uom.id === 130),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 131 - MAGNETIC_FLUX
  {
    id: 13100,
    unit: "Wb[weber]",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13101,
    unit: "nWb",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 1e-9,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13102,
    unit: "µWb",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 1e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13103,
    unit: "mWb",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 1e-3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13104,
    unit: "cWb",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 0.01,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13105,
    unit: "dWb",
    category: UomCategories.find((uom) => uom.id === 131),
    cof0: 0.1,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 132 - LUMINOUS_FLUX
  {
    id: 13200,
    unit: "lm[lumen]",
    category: UomCategories.find((uom) => uom.id === 132),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13201,
    unit: "cd⋅sr",
    category: UomCategories.find((uom) => uom.id === 132),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 133 - MAGNETIC_FLUX_DENSITY
  {
    id: 13300,
    unit: "T[tesla]",
    category: UomCategories.find((uom) => uom.id === 133),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13301,
    unit: "Wb/m²",
    category: UomCategories.find((uom) => uom.id === 133),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13302,
    unit: "µT",
    category: UomCategories.find((uom) => uom.id === 133),
    cof0: 1.0e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13303,
    unit: "G",
    category: UomCategories.find((uom) => uom.id === 133),
    cof0: 1.0e-4,
    system: "SI Metric",
    base: false,
  },
    {
    id: 13304,
    unit: "mT",
    category: UomCategories.find((uom) => uom.id === 133),
    cof0: 1.0e-3,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 134 - KATALYTIC_ACTIVITY
  {
    id: 13400,
    unit: "kat[katal]",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13401,
    unit: "mol/s",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13402,
    unit: "µkat",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1.0e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13403,
    unit: "mkat",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1.0e-3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13404,
    unit: "kkat",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13405,
    unit: "Mkat",
    category: UomCategories.find((uom) => uom.id === 134),
    cof0: 1.0e6,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 135 - RADIOACTIVITY
  {
    id: 13500,
    unit: "Bq[becquerel]",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13501,
    unit: "1/s",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13501,
    unit: "µBq",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13502,
    unit: "mBq",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0e-3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13503,
    unit: "kBq",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0e3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13504,
    unit: "MBq",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0e6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13505,
    unit: "Rd[rutherford]",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 1.0e6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13506,
    unit: "Ci[curie]",
    category: UomCategories.find((uom) => uom.id === 135),
    cof0: 37000000000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 136 - ABSORBED_DOSE_OF_IONIZING_RADIATION
  {
    id: 13600,
    unit: "Gy[gray]",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13601,
    unit: "J/kg",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13602,
    unit: "µGy",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13603,
    unit: "mGy",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0e-3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13604,
    unit: "kGy",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0e3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13605,
    unit: "MGy",
    category: UomCategories.find((uom) => uom.id === 136),
    cof0: 1.0e6,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 137 - EQUIVALENT_DOSE_OF_IONIZING_RADIATION
  {
    id: 13700,
    unit: "Sv[sievert]",
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13701,
    unit: "J/kg", // TODO: this is not unique
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13702,
    unit: "µSv",
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0e-6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13703,
    unit: "mSv",
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0e-3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13704,
    unit: "kSv",
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0e3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 13705,
    unit: "MSv",
    category: UomCategories.find((uom) => uom.id === 137),
    cof0: 1.0e6,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 138 - VOLUMETRIC DISPLACEMENT
  {
    id: 13800,
    unit: "m³/rad",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13801,
    unit: "m³/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: 1.0 / (Math.PI * 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 13802,
    unit: "L/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: 1.0 / 1000 / (Math.PI * 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 13803,
    unit: "cc/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: 1.0 / 1000000 / (Math.PI * 2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 13804,
    unit: "ft³/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: Math.pow(FOOT_METER, 3) / (Math.PI * 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 13805,
    unit: "in³/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: Math.pow(INCH_METER, 3) / (Math.PI * 2),
    system: "Imperial",
    base: false,
  },
  {
    id: 13806,
    unit: "gal(US-liquid)/rev",
    category: UomCategories.find((uom) => uom.id === 138),
    cof0: (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)) / (Math.PI * 2),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 139 - FUEL ECONOMY
  {
    id: 13900,
    unit: "m/m³",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 13901,
    unit: "km/L",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0: 1000.0 / (1 / 1000),
    system: "SI Metric",
    base: false,
  },
  {
    id: 13902,
    unit: "mpg(US)",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0:
      (MILE_FOOT * FOOT_METER) /
      (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)),
    system: "Imperial",
    base: false,
  },
  {
    id: 13903,
    unit: "mile/gal(US-liquid)",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0:
      (MILE_FOOT * FOOT_METER) /
      (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)),
    system: "Imperial",
    base: false,
  },
  {
    id: 13904,
    unit: "mpg(UK)",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0: (MILE_FOOT * FOOT_METER) / (GALLON_UK_IMPERIAL_LITRE / 1000),
    system: "Imperial",
    base: false,
  },
  {
    id: 13905,
    unit: "mile/gal(UK-imperial)",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0: (MILE_FOOT * FOOT_METER) / (GALLON_UK_IMPERIAL_LITRE / 1000),
    system: "Imperial",
    base: false,
  },
  {
    id: 13906,
    unit: "mile/L",
    category: UomCategories.find((uom) => uom.id === 139),
    cof0: (MILE_FOOT * FOOT_METER) / (1 / 1000),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 140 - FUEL CONSUMPTION
  {
    id: 14000,
    unit: "m³/m",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 14001,
    unit: "L/km",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: 1.0 / 1000 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14002,
    unit: "L/100km",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: 1.0 / 1000 / (100 * 1000),
    system: "SI Metric",
    base: false,
  },
  {
    id: 14003,
    unit: "L/mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: 1.0 / 1000 / (MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14004,
    unit: "L/100mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: 1.0 / 1000 / (100 * MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14005,
    unit: "gal(US-liquid)/mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0:
      (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)) /
      (MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },
  {
    id: 14006,
    unit: "gal(US-liquid)/100mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0:
      (GALLON_US_LIQUID_IN3 * Math.pow(INCH_METER, 3)) /
      (100 * MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },
  {
    id: 14007,
    unit: "gal(UK-imperial)/mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: GALLON_UK_IMPERIAL_LITRE / 1000 / (MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },
  {
    id: 14008,
    unit: "gal(UK-imperial)/100mile",
    category: UomCategories.find((uom) => uom.id === 140),
    cof0: GALLON_UK_IMPERIAL_LITRE / 1000 / (100 * MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },

  // #endregion

  // #region 141 - NEW ENERGY ECONOMY (MPGe)
  {
    id: 14100,
    unit: "m/J",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 14101,
    unit: "km/kWh",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: 1.0 / 3600,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14101,
    unit: "km/MJ",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: 1.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14102,
    unit: "MPGe",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: (MILE_FOOT * FOOT_METER) / (121.3 * 1e6),
    system: "Imperial",
    base: false,
  },
  {
    id: 14103,
    unit: "mile/MJ",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: (MILE_FOOT * FOOT_METER) / 1e6,
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14104,
    unit: "mile/kWh",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: (MILE_FOOT * FOOT_METER) / (1000 * 3600),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14105,
    unit: "mile/MM Btu",
    category: UomCategories.find((uom) => uom.id === 141),
    cof0: (MILE_FOOT * FOOT_METER) / (1e6 * BTU_J),
    system: "SI Metric/Imperial",
    base: false,
  },
  // #endregion

  // #region 142 - NEW ENERGY CONSUMPTION
  {
    id: 14200,
    unit: "J/m",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 142010,
    unit: "Wh/km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 3600.0 / 1000,
    system: "SI Metric",
    base: false,
  },
  {
    id: 142011,
    unit: "Wh/mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 3600.0 / (MILE_FOOT * FOOT_METER),
    system: "SI Metric",
    base: false,
  },
  {
    id: 14202,
    unit: "kWh/km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 3600.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14203,
    unit: "kWh/100km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 3600.0 / 100,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14204,
    unit: "kWh/mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: (1000.0 * 3600) / (MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14205,
    unit: "kWh/100mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: (1000.0 * 3600) / (100 * MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14206,
    unit: "MJ/km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14207,
    unit: "MJ/100km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1000.0 / 100,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14208,
    unit: "MJ/mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1e6 / (MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14209,
    unit: "MJ/100mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1e6 / (100 * MILE_FOOT * FOOT_METER),
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14210,
    unit: "MM Btu/km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 1e3 * BTU_J,
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14211,
    unit: "MM Btu/100km",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: 10.0 * BTU_J,
    system: "SI Metric/Imperial",
    base: false,
  },
  {
    id: 14212,
    unit: "MM Btu/mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: (1e6 * BTU_J) / (MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },
  {
    id: 14213,
    unit: "MM Btu/100mile",
    category: UomCategories.find((uom) => uom.id === 142),
    cof0: (1e6 * BTU_J) / (100 * MILE_FOOT * FOOT_METER),
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 143 - BAUD RATE   // a common unit of measurement of symbol rate
  {
    id: 14300,
    unit: "Bd",
    category: UomCategories.find((uom) => uom.id === 143),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 14301,
    unit: "kBd",
    category: UomCategories.find((uom) => uom.id === 143),
    cof0: 1e3,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14302,
    unit: "MBd",
    category: UomCategories.find((uom) => uom.id === 143),
    cof0: 1e6,
    system: "SI Metric",
    base: false,
  },
  {
    id: 14303,
    unit: "GBd",
    category: UomCategories.find((uom) => uom.id === 143),
    cof0: 1e9,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 150 - CURRENCY
  {
    id: 15000,
    unit: "$",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 15001,
    unit: "¢",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1.0 / 100,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15002,
    unit: "$1K",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15003,
    unit: "$1M",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15004,
    unit: "$1B",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1000000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15005,
    unit: "$1T",
    category: UomCategories.find((uom) => uom.id === 150),
    cof0: 1000000000000.0,
    system: "SI Metric",
    base: false,
  },
  // #endregion

  // #region 151 - IMPULSE_OR_MOMENTUM
  {
    id: 15100,
    unit: "N·s",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 15101,
    unit: "Newton-second",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15102,
    unit: "lb·s",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: POUNDMASS_KG * GRAVITY_SI,
    system: "Imperial",
    base: false,
  },
  {
    id: 15103,
    unit: "kg·m/s",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: 1.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15104,
    unit: "lbm·fps",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: POUNDMASS_KG * FOOT_METER,
    system: "Imperial",
    base: false,
  },
  {
    id: 15105,
    unit: "lbm·fpm",
    category: UomCategories.find((uom) => uom.id === 151),
    cof0: POUNDMASS_KG * FOOT_METER / 60.0,
    system: "Imperial",
    base: false,
  },
  // #endregion

  // #region 152 - DIGITAL_STORAGE
  {
    id: 15200,
    unit: "bit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 15201,
    unit: "kilobit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15202,
    unit: "kibibit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1024.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15203,
    unit: "megabit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1000000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15204,
    unit: "mebibit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1024*1024,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15205,
    unit: "gigabit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15206,
    unit: "gibibit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15207,
    unit: "terabit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,4),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15208,
    unit: "tebibit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,4),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15209,
    unit: "petabit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,5),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15210,
    unit: "pebibit",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,5),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15211,
    unit: "byte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15212,
    unit: "kilobyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1000*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15213,
    unit: "kibibyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: 1024*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15214,
    unit: "megabyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,2)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15215,
    unit: "mebibyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,2)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15216,
    unit: "gigabyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,3)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15217,
    unit: "gibibyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,3)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15218,
    unit: "terabyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,4)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15219,
    unit: "tebibyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,4)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15220,
    unit: "petabyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1000,5)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15221,
    unit: "pebibyte",
    category: UomCategories.find((uom) => uom.id === 152),
    cof0: Math.pow(1024,5)*BYTE_BIT,
    system: "SI Metric",
    base: false,
  },

  // #endregion

  // #region 153 - DATA_TRANSFER_RATE
  {
    id: 15300,
    unit: "bit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: 1.0,
    system: "SI Metric",
    base: true,
  },
  {
    id: 15301,
    unit: "kilobit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: 1000.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15302,
    unit: "kibibit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: 1024.0,
    system: "SI Metric",
    base: false,
  },
  {
    id: 15303,
    unit: "megabit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1000,2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15304,
    unit: "mebibit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1024,2),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15305,
    unit: "gigabit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1000,3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15306,
    unit: "gibibit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1024,3),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15307,
    unit: "terabit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1024,4),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15308,
    unit: "tebibit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1024,4),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15309,
    unit: "petabit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1000,5),
    system: "SI Metric",
    base: false,
  },
  {
    id: 15310,
    unit: "pebibit/s",
    category: UomCategories.find((uom) => uom.id === 153),
    cof0: Math.pow(1024,5),
    system: "SI Metric",
    base: false,
  },

  // #endregion

  // #region 153 - DATA_TRANSFER_RATE

  // #endregion

]; // Uoms

const _Units = [];
Uoms.forEach((uom) => _Units.push(uom.unit));
export const Units = _Units;
