/* eslint-disable */

import { storage } from '../tools/firebase/fb'
import { ref } from 'vue'

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)

  /**
   * loc: storage path the image file to be stored at.
  */
  const upload = async (path, file) => {
    // filePath.value = `test/test-${file.name}`
    // filePath.value = `${file.name}`
    // filePath.value = `users/don.wen@yahoo.com/general/${file.name}`
    const storageRef = storage.ref(path)

    try {
      const res = await storageRef.child(file.name).put(file)
      url.value = await res.ref.getDownloadURL()
      console.log(`@uploadImage: url.value = ${url.value}`)
    } catch (err) {
      console.log(err.message)
      error.value = err
    }
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Response/blob
   * @param {*} fName: image file name
   * @param {*} fromURL: url of the image in existing calc
   * @param {*} toPath: image location path in the new calc
   * @returns: new url for the image in the new calc
   */
  const copyImage = async (fName, fromURL, toPath) => {
    // const pathRef = storage.ref(from)
    let toURL

    try {
      const response = await fetch(fromURL)
      const blob = await response.blob()
      const storageRef = storage.ref(toPath)

      toURL = await storageRef.child(fName).put(blob)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(downloadURL => {
          console.log(`Successfully uploaded file and got download link - ${downloadURL}`)
          return downloadURL
        })

      console.log(`toURL@copyImage: ${toURL}`)

      return toURL
    } catch (err) {
      console.log(err.message)
      error.value = err
    }
  }

  /**
   *  backup
   */
  const copyImage0 = async (from) => {
    // const pathRef = storage.ref(from)
    let toURL

    try {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'

      xhr.onload = (event) => {
        console.log(`blob@copyImage: ${xhr.response}`)
        toURL = URL.createObjectURL(xhr.response /* blob */)
        console.log(`toURL@copyImage: ${toURL}`)
      }

      xhr.open('GET', from, true)
      xhr.send()
      console.log(`URL@copy image: ${toURL}`)
      return toURL
    } catch (err) {
      console.log(err.message)
      error.value = err
    }
  }

  const deleteImage = async (path) => {
    const storageRef = storage.ref(path)

    try {
      await storageRef.delete()
      console.log(`Image @ ${path} successfully deleted.`)
    } catch (err) {
      console.log(err.message)
      error.value = err
    }
  }

  const deleteAll = async (projectPath) => {
    const listRef = storage.ref(projectPath)

    try {
      const _list = await listRef.list({ maxResults: 20 })
      console.log(`@deleteAllImage: ${_list.items}`)

      if (_list) {
        _list.items.forEach(img => {
          console.log(`@deleteAllImage: img= ${img}`)
          img.delete()
        })
      }
    } catch (err) {
      console.log(`at deleteAllImages, err.message = ${err.message}`)
      error.value = err
    }
  }

  return { upload, copyImage, copyImage0, deleteImage, deleteAll, url, filePath, error }
}

export default useStorage
