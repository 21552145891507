<template>
  <section>
    <p class="content"><b>Selected:</b> {{ selected }}</p>
    <o-autocomplete
      v-model="vegetName"
      group-field="type"
      group-options="items"
      open-on-focus
      :data="filteredDataObj"
      field="user.first_name"
      @select="(option) => (selected = option)"
    >
    </o-autocomplete>
  </section>
</template>

<script>
export default {
  data () {
    return {
      data: [
        {
          type: 'Fruit',
          items: ['Apple', 'Banana', 'Watermelon']
        },
        {
          type: 'Vegetables',
          items: ['Carrot', 'Broccoli', 'Cucumber', 'Onion']
        }
      ],
      vegetName: '',
      selected: null
    }
  },
  computed: {
    filteredDataObj () {
      const newData = []
      this.data.forEach((element) => {
        const items = element.items.filter(
          (item) =>
            item.toLowerCase().indexOf(this.vegetName.toLowerCase()) >= 0
        )
        if (items.length) {
          newData.push({ type: element.type, items })
        }
      })
      return newData
    }
  }
}
</script>
